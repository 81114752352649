import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { DataService } from '../../data.service';
import { AppState } from '../../app.service';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { map } from 'rxjs/operators';

interface Params {
  name: string;
  value: string | number;
}

@Injectable()
export class SearchService {
  // private data: any;
  // private dataObs: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private http: HttpClient,
    public utils: UtilsService,
    public appState: AppState,
    public data: DataService) { }

  public searching(params: Params[], data?: any) {
    let p: string = this.utils.createUrlParams(params);
    return this.http.post(`/open-company/_search${p}`, data ? data : '');
  }

  public search(data?: any, params?) {
    let p: string = params ? this.utils.createUrlParams(params) : '';
    return this.http.post(`/api/search/company${params ? p : ''}`, data ? data : {});
  }

  public searchUz(data: any) {
    return this.http.post(`/web-api-gateway/uz_check/search`, data);
  }

  public searchFl(iin) {
    return this.http.get(`/company/person?iin=${iin}`);
  }

  public searchFlName(name) {
    return this.http.get(`/company/person?name=${name}`);
  }

  public searchByNumber(number) {
    return this.http.get(`/api/get_data/by_phone?search_value=${number}`);
  }

  public searchRf(inn, type) {
    return this.http.post(`/open-company/_search?${type === 'inn' ? 'inn' : 'nameRu'}=${inn}`, {} ? {} : '');
  }

  public searchLegat(name, country) {
    return this.http.post(`/open-company/_search?name=${name}&country=${country}`, {});
  }

  public searchWatchman(filter, params?) {
    let p: string = params ? this.utils.createUrlParams(params) : '';
    return this.http.post(`/api/search/company${params ? p : ''}`, filter);
  }

  public searchWatchmanRus(filter, page = 0) {
    return this.http.post(`/api/search/company?page=${page}`, filter).toPromise()
  }

  public getById(id: number) {
    return this.http.get(`/open-company/${id}`).pipe(map((res) => {
      return res;
    }));
  }

  public getByIdExtendedData(id: number) {
    return this.http.get(`/company/card/${id}`).pipe(map((res) => {
      return res;
    }));
  }

  public getOked() {
    return this.http.get(`/filter-api/okeds`).pipe(map((res) => {
      return res;
    }));
  }

  public getKato() {
    return this.http.get(`/filter-api/katos`).pipe(map((res) => {
      return res;
    }));
  }

  public getLiquid(bin: any, iin: any, rnn: any) {
    let urlBin: any = bin && bin.length === 12 ? 'bin=' + bin : '';
    let urlIin: any = iin && iin.length === 12 ? 'iin=' + iin : '';
    let urlRnn: any = rnn && rnn.length === 12 ? 'rnn=' + rnn : '';
    return this.http.get(`/company/liquid?${urlBin}${urlIin}${urlBin || urlIin ? '' : urlRnn}`).pipe(map((res) => {
      return res;
    }));
  }

  public getNDS(bin: any, iin: any, rnn: any) {
    let urlBin: any = bin && bin.length === 12 ? 'bin=' + bin : '';
    let urlIin: any = iin && iin.length === 12 ? 'iin=' + iin : '';
    let urlRnn: any = rnn && rnn.length === 12 ? 'rnn=' + rnn : '';
    return this.http.get(`/company/nds?${urlBin}${urlIin}${urlBin || urlIin ? '' : urlRnn}`).pipe(map((res) => {
      return res;
    }));
  }

  public getRegisterDate(iin: any) {
    return this.http.get(`/company/register-date?iin=${iin}`).pipe(map((res) => {
      return res;
    }));
  }

  public idIsExist(id: number) {
    return this.data && this.data[`id${id}`] ? true : false;
  }

  getContractors(data) {
    return this.http.post(`/company/i-search`, data).pipe(map((res) => {
      return res;
    }));
  }

  contractorsExport(data) {
    return this.http.post(`/company/i-search/export`, data, { responseType: 'arraybuffer' }).pipe(map((res) => {
      return this.arrayBufferToBase64(res);
    }));
  }

  arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }
}
