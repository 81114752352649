import {
  Component,
  HostBinding,
  ViewChildren,
  OnInit,
  AfterViewInit,
  OnDestroy,
  EventEmitter,
  HostListener,
  Input
} from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { SearchService } from '../../services/search.service';
import { UtilsService } from '../../services/utils.service';
import { AuthService } from '../../services/auth.service';
import { AppState } from '../../../app.service';

@Component({
  selector: 'app-livesearch',
  templateUrl: './livesearch.component.html'
})
export class LiveSearchComponent implements OnInit, AfterViewInit {
  @HostBinding('class') class = 'layout__searchbar';
  @ViewChildren('input') public input;
  @Input() country;
  public focusSet = new EventEmitter<boolean>();
  public activeEl: any = -2;

  public model: any = {
    data: '',
    keyword: '',
    result: [],
    showList: false,
    doSearching: true,
    loading: false,
    help: false,
    selected: ''
  };
  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    public searchService: SearchService,
    public appState: AppState,
    public authService: AuthService,
    public utils: UtilsService
  ) { }

  public ngOnInit() {
    this.activeRoute.queryParams.subscribe((params: Params) => {
      this.model.data = this.cutSymbols(params['q']);
    });
  }

  public ngAfterViewInit() {
    this.input.first.nativeElement.focus();
  }

  @HostListener('keyup', ['$event'])
  public onChange(e: KeyboardEvent) {
    // up
    if (e.keyCode === 38) {
      if (this.activeEl > -1) {
        this.activeEl -= 1;
      } else {
        this.activeEl = this.model.result.length - 1;
      }
      return;
    }
    // down
    if (e.keyCode === 40) {
      if (this.activeEl < this.model.result.length - 1) {
        this.activeEl += 1;
      } else {
        this.activeEl = -1;
      }
      return;
    }
  }

  public doSearch() {
    this.model.result = [];
    this.model.data = this.cutSymbols(this.model.data);
    let data: any = this.model.data ? this.cutQuotes(this.model.data).trim() : this.cutQuotes(this.model.data);
    this.activeEl = -2;
    if (this.model.doSearching) {
      this.model.showList = true;
      if (data && data.length >= 3) {
        if (this.utils.isUIN(data) && this.utils.getUinType(data) === 'iin') {
          let params = [{
            name: 'iin',
            value: data
          }];
          this.searched(params);
          if (this.authService.authorized) {
            this.searchedFl(data);
          }
        } else if (this.utils.isUIN(data) && this.utils.getUinType(data) === 'bin') {
          let params = [{
            name: 'bin',
            value: data
          }];
          this.searched(params);
        } else {
          let params = [{
            name: 'name',
            value: data
          }];
          this.searched(params);
        }
        if (this.authService.authorized && data.split(' ').length > 1) {
          this.searchedFlName(data);
        }
      } else {
        this.model.result = [];
        this.model.showList = false;
      }
    } else {
      this.model.doSearching = true;
    }
  }

  public searched(params: any) {
    this.model.loading = true;
    this.searchService.searching(params).subscribe((res: Array<any>) => {
      this.model.loading = false;
      if (this.model.doSearching) {
        if (!(this.model.result && this.model.result.length > 0 && res && res.length === 0)) {
          this.model.result = this.model.result.concat(res);
        }
      } else {
        this.model.doSearching = true;
      }
    }, (err) => {
      this.model.loading = false;
      this.model.result = [];
      console.log(err);
    });
  }

  public searchedFl(iin: any) {
    this.searchService.searchFl(iin).subscribe((res) => {
      this.model.result.push(res);
    });
  }

  public searchedFlName(name: any) {
    this.searchService.searchFlName(name).subscribe((res) => {
      this.model.result = this.model.result.concat(res);
    });
  }

  public clearAll() {
    this.model.data = '';
    this.model.result = [];
    this.model.showList = false;
    this.model.doSearching = false;
    this.router.navigateByUrl(`/search`);
  }

  public getIndexOf(el: any) {
    return typeof el === 'string' ? el.trim().toLowerCase().indexOf(this.cutQuotes(this.model.data).trim().toLowerCase()) > -1 : false;
  }

  public cutSymbols(str: string) {
    if (!this.utils.isEmpty(str)) {
      return str.replace(/[\/\\[\]{}\?!\`~()#%:^\|]/g, '');
    }
    return str;
  }

  public cutQuotes(str: string) {
    if (!this.utils.isEmpty(str) && str && str[0] === '"' && str[str.length - 1] === '"') {
      return str.substr(1, str.length - 2);
    }
    // if (!this.utils.isEmpty(str)) {
    //   return str.replace(/"/g, '');
    // }
    return str;
  }

  public choiceSearch() {
    this.model.data = this.cutSymbols(this.model.data);
    if (this.activeEl > -1) {
      this.goToCard(this.model.result[this.activeEl]);
    } else {
      this.goToSearch();
    }
  }

  public goToSearch() {
    if (this.model.data) {
      this.model.result = [];
      this.router.navigateByUrl(`/search?q=${this.model.data}${this.appState.state.searchCountry ?
        '&c=' + this.appState.state.searchCountry : ''}${this.appState.state.tabs ? '&t=' + this.appState.state.tabs : ''}`);
      this.model.showList = false;
      this.model.doSearching = false;
    }
  }
  public goToCard(el: any) {
    if (el && el.ip === false && el.iin) {
      this.router.navigateByUrl(`/card/${el.id}/report?type=fl`);
    } else {
      this.router.navigateByUrl(`/card/${el.id}/report`);
    }
  }

  getHtml(el) {
    let name = this.utils.highlight(el.name, this.cutQuotes(this.model.data));
    let field = this.utils.highlight(el.field && el.field[0], this.cutQuotes(this.model.data));
    let address = this.utils.highlight(el.lawAddres, this.cutQuotes(this.model.data));
    return `${name + (field ? ' <i>/ ' + field : '') + (address && field ? ', ' + address + '</i>' :
      address && !field ? '<i>, ' + address + '</i>' : field ? '</i>' : '')}`;
  }
  getUinHtml(el) {
    let uin = this.utils.highlight(el.bin || el.iin, this.cutQuotes(this.model.data));
    let name = this.utils.highlight(el.name, this.cutQuotes(this.model.data));
    let field = this.utils.highlight(el.field && el.field[0], this.cutQuotes(this.model.data));
    let address = this.utils.highlight(el.lawAddres, this.cutQuotes(this.model.data));
    return `${uin + (name || field || address ? ' <i>' : '') + (name ? '/ ' + name + ' ' : '') +
      (field ? '/ ' + field + ' ' : '') + (address ? ', ' + address : '') +
      (name || field || address ? ' <i>' : '')}`;
  }
  getOwnerHtml(el) {
    let ownerName = this.utils.highlight(el.owner.name, this.cutQuotes(this.model.data));
    let name = this.utils.highlight(el.name, this.cutQuotes(this.model.data));
    return `${ownerName + (name ? ' <i>/ ' + name : '') + (name ? name + '</i>' : '')}`;
  }
}
