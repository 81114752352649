import { take } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SurveyForm, SurveyService } from '../../services/survey.service';
import { ToasterService } from 'angular2-toaster';
import { CabinetService } from '../../services/cabinet.service';

@Component({
  selector: 'app-quiz-modal',
  templateUrl: './quiz-modal.component.html',
  styleUrls: ['./quiz-modal.component.css']
})
export class QuizModalComponent implements OnInit {

  @Output() close = new EventEmitter()

  currentPage: number = 0
  isLoading: boolean = false
  isError: boolean = false
  isValid: boolean = true

  form: SurveyForm = {
    survey: {
      id: 1
    },
    answerDto: [{
        question: {
          id: 1,
        },
        value: ""
      }, {
        question: {
          id: 2,
        },
        value: ""
      }, {
        question: {
          id: 3,
        },
        value: ""
      }, {
        question: {
          id: 4,
        },
        value: ""
      }, {
        question: {
          id: 5,
        },
        value: ""
      }, {
        question: {
          id: 7,
        },
        value: ""
      }, {
        question: {
          id: 6,
        },
        value: ""
      },
    ]
  }

  pages = [
    {
      id: 1,
      question: 'Насколько просто вам было пользоваться сайтом?',
      type: 'estimation',
      value: ''
    },
    {
      id: 2,
      question: 'Насколько хорошо наши модули (инструменты) отвечают вашим потребностям?',
      type: 'estimation',
      value: ''
    },
    {
      id: 3,
      question: 'Оцените готовность рекомендовать нашу компанию.',
      type: 'estimation',
      value: ''
    },
    {
      id: 4,
      question: 'Как бы вы себя чувствовали, если бы больше не смогли пользоваться Kompra?',
      type: 'options',
      value: ''
    },
    {
      id: 5,
      question: 'В чем заключается главная польза от Kompra для вас?',
      type: 'form',
      value: ''
    },
    {
      id: 7,
      question: 'Какие инструменты Kompra наиболее важны для вас?',
      type: 'form',
      value: ''
    },
    {
      id: 6,
      question: 'Как мы можем сделать Kompra лучше для вас?',
      type: 'form',
      value: ''
    },
  ]
  
  constructor(private surveyService: SurveyService, private toast: ToasterService, private cabinet: CabinetService) { }

  ngOnInit() {
  }

  takeSurvey() {
    for (let index = 0; index < this.pages.length; index++) {
      this.form.answerDto[index].options = []
      if(this.form.answerDto[index].question.id == this.pages[index].id) {
        this.form.answerDto[index].value = this.pages[index].value
      }
    }

    this.isLoading = true
    this.surveyService.takeSurvey(this.form)
      .pipe(take(1))
      .subscribe(
        () => {
          this.isError = false
          this.isLoading = false
          this.currentPage = 8
          this.cabinet.getBalance().subscribe()
        },
        (err: any) => {
          this.isError = true
          this.isLoading = false
          this.toast.pop('error', 'Техническая ошибка')
          console.error(err)
        }
      )
  }

  validate(text: string) {
    this.isValid = true
    if(text.trim().length < 10 || text.trim().length > 500) {
      this.isValid = false
    }
  }
  
  closeModal() {
    this.close.emit()
    this.currentPage = 0
  }
}
