import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-sitemap',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './sitemap.component.html'
})

export class IconSitemapComponent {}
