import { Component, HostBinding, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MonitoringPopupService } from './monitoring-popup.service';
import { CabinetService } from '../../services/cabinet.service';
import { AppState } from '../../../app.service';
import { ToasterService } from 'angular2-toaster';
import { Sub } from '../../../modules/card/card.service';
import { CardState } from './../../../modules/card/card.service';

@Component({
  selector: 'app-monitoring-popup',
  templateUrl: './monitoring-popup.component.html'
})
export class MonitoringPopupComponent implements OnInit, OnDestroy {
  @Output() public close: any = new EventEmitter();
  @Input() public isIp: boolean;
  @HostBinding('class') class = 'layout__overlay layout__overlay--active';
  model: any = {};
  visibility: any = {};
  data: any = {};
  sub: Sub = {};
  parametrs = {
    is_register_info: true,
    is_status: true,
    is_founder: true,
    is_risk_factors: true,
    is_court_cases: true,
    is_tenders: true,
    is_contracts: true,
    is_board_members: true,
    is_shareholders: true
  }
  isIP: boolean = false
  
  constructor(
    public monitoringPopupService: MonitoringPopupService,
    public toast: ToasterService,
    public appState: AppState,
    public cs: CardState,
    public cabinetService: CabinetService
  ) {
  }
  
  get hasNoParameters() {
    const values: boolean[] = Object.values(this.parametrs)
    return values.every(value => !value)
  }
  
  ngOnInit() {
    this.getList();
    this.checkIsIP()
  }

  ngOnDestroy() {
    for (const key in this.sub) {
      if (this.sub.hasOwnProperty(key)) {
        this.sub[key].unsubscribe();
      }
    }
  }

  getList() {
    this.model.loading = true;
    this.sub.getParameters = this.monitoringPopupService.getParameters(this.isIp ? 'IP' : 'UL').subscribe((res: any) => {
      this.model.parameters = res;
      this.model.groups = [];
      for (const key in res) {
        if (Object.prototype.hasOwnProperty.call(res, key)) {
          if (key === 'risk_factors') {
            this.data.risk_factors = [];
            this.model.groups.push({ name: 'Факторы риска', value: 'risk_factors' });
          }
          if (key === 'risk_factors_founder') {
            this.data.risk_factors_founder = [];
            this.model.groups.push({ name: 'Факторы риска учредителей', value: 'risk_factors_founder' });
          }
          if (key === 'risk_factors_owner') {
            this.data.risk_factors_owner = [];
            this.model.groups.push({ name: 'Факторы риска руководителя', value: 'risk_factors_owner' });
          }
        }
      }
      this.model.loading = false;
      this.getClient();
    }, (err) => {
      console.log(err);
      this.model.loading = false;
    });
  }

  getClient() {
    this.model.loading = true;
    this.sub.getClient = this.monitoringPopupService.getClient(this.appState && this.appState.state
        && this.appState.state.monitoringIdentifier)
      .subscribe((res: any) => {
        this.model.loading = false;
        if(res && res.monitoring_choice) {
          this.parametrs = res.monitoring_choice
        }
        this.model.risk_factors = JSON.parse(JSON.stringify(res.risk_factors));
        this.data = JSON.parse(JSON.stringify(res));
        this.data['risk_factors'] = [];
        this.data['risk_factors_founder'] = [];
        this.data['risk_factors_owner'] = [];
        if (res && res.risk_factors && res.risk_factors.length) {
          [1, 2, 3, 4, 5, 6, 7, 14, 16, 28, 29, 32, 33, 34, 35, 36, 37, 38, 39].forEach((el) => {
            if (res.risk_factors.indexOf(el) > -1) {
              this.data.risk_factors.push(el);
            }
          });
          [17, 18, 19, 20, 21, 22, 23, 25, 27, 31].forEach((el) => {
            if (res.risk_factors.indexOf(el) > -1) {
              this.data.risk_factors_founder.push(el);
            }
          });;
          [8, 9, 10, 11, 12, 13, 15, 24, 26, 30].forEach((el) => {
            if (res.risk_factors.indexOf(el) > -1) {
              this.data.risk_factors_owner.push(el);
            }
          });
        }
      }, (err) => {
        this.model.loading = false;
        console.log(err);
      });
      this.checkIsIP()
  }

  checkIsIP() {
    if(this.cs.model && this.cs.model.data && this.cs.model.data.ip) {
      this.isIP = true
      this.parametrs.is_founder = false
      this.parametrs.is_board_members = false
      this.parametrs.is_shareholders = false
    }
  }
  
  isChecked(groupName, el) {
    return this.data[groupName] && this.data[groupName].length
      && this.data[groupName].indexOf(el.id) > -1;
  }

  setReq(groupName, el, b) {
    if (b) {
      if (this.data[groupName] && this.data[groupName].length) {
        this.data[groupName].push(el.id);
      } else {
        this.data[groupName] = [];
        this.data[groupName].push(el.id);
      }
    } else {
      let i = this.data[groupName].indexOf(el.id);
      if (i > -1) {
        this.data[groupName].splice(i, 1);
      }
    }
  }

  isCheckedGroup(groupName) {
    return this.data[groupName] && this.data[groupName].length;
  }

  setGroup(groupName, b) {
    if (b) {
      this.data[groupName] = this.model.parameters[groupName].map((el) => el.id);
    } else {
      this.data[groupName] = [];
    }
  }

  saveParam() {
    this.model.disabledSave = true;
    let data = {
      identifier: this.appState.state.monitoringIdentifier,
      monitoring_choice: this.parametrs
      // is_risk_factors: this.data.risk_factors && this.data.risk_factors.length ? true : false,
      // is_risk_factors_founders: this.data.risk_factors_founder && this.data.risk_factors_founder.length ? true : false,
      // is_risk_factors_owner: this.data.risk_factors_owner && this.data.risk_factors_owner.length ? true : false,
      // risk_factors: this.data.risk_factors.concat(this.data.risk_factors_founder).concat(this.data.risk_factors_owner)
    }
    if (this.model && this.model.parameters && this.model.parameters.main_parameters
        && this.model.parameters.main_parameters.length) {
          this.model.parameters.main_parameters.forEach((el) => {
            data[el.field] = this.data[el.field];
          });
    }

    this.sub.postClient = this.monitoringPopupService[this.data.created ? 'putClient' : 'postClient'](data).subscribe((res) => {
      this.close.emit(true);
      this.monitoringPopupService.close();
      this.model.disabledSave = false;
    }, (err) => {
      console.log(err);
      this.model.disabledSave = false;
      this.toast.pop('error', `${err.error.detail}`);
    })
  }

}
