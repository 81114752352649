import { AppState } from './../../../app.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { AuthService } from '../../services/auth.service';
import { CabinetService } from '../../services/cabinet.service';
import { BehaviorSubjectService } from '../../services/behavior-subject.service';

@Component({
  selector: 'app-phone-confirmation',
  templateUrl: './phone-confirmation.component.html',
  styleUrls: ['./phone-confirmation.component.css']
})
export class PhoneConfirmationComponent implements OnInit {

  @Input() phone: string
  @Output() close = new EventEmitter()
  
  newPhoneNumber: string = ''
  smsCode: string = ''
  
  interval: any
  timeout: number
  
  changedPhone: string
  requestCode: boolean = false
  isError: boolean = false
  isSuccess: boolean = false
  isLoading: boolean = false
  isChangePhone: boolean = false
  isCorrectNumber: boolean = false
  smsError: string = ''
  user: any = {}

  constructor(
    private authService: AuthService,
    public toast: ToasterService,
    private cs: CabinetService,
    public appState: AppState,
    public subjectService: BehaviorSubjectService
  ) { }

  ngOnInit() {
    this.getUserInfo()
    this.resetTimeout()
    this.changedPhone = this.phone
  }

  public startTimer(): void {
    this.requestCode = true
    this.getSmsCode()
    this.interval = setInterval(() => {
      this.timeout -= 1000
      if(this.interval && this.timeout <= 0) {
        clearInterval(this.interval)
        this.requestCode = false
        this.resetTimeout()
      }
    }, 1000);
  }

  public resetTimeout() {
    this.timeout = 1000 * 60
  }
  
  public getSmsCode(): void {
    this.isLoading = true
    this.authService.getSms(this.phone.replace(/[^0-9+]/g, '')).subscribe(
      res => {
        this.isLoading = false
      },
      err => {
        this.isLoading = false
        console.error(err);
        if(err.error && err.error.text && err.error.text.includes('OK')) {
          return
        }
        if(err.error && err.error.text && err.error.text.includes('ERROR')) {
          this.toast.pop('error', 'Системная ошибка, попробуйте позднее!');
        }
        // if(err.error && err.error.text && !err.error.text.includes('OK') || new Object(err.error).hasOwnProperty('phone')) {
        //   this.toast.pop('error', 'Системная ошибка, попробуйте позднее!');
        // }
      }
    )
  }

  public getSmsCodeForNewPhone() {
    this.phone = this.newPhoneNumber
    this.startTimer()
  }
  
  public sendSmsCode(smsCode: number | string): void {
    this.isLoading = true
    this.authService.sendSms(this.phone.replace(/[^0-9+]/g, ''), smsCode).subscribe(
      res => {
        this.isLoading = false
      },
      err => {
        this.isLoading = false
        console.error(err);
        this.toast.pop('error', 'Системная ошибка, попробуйте позднее!');
      }
    )
  }

  sendSms(sms) {
    this.smsError = ''
    if ((sms + '').length === 5) {
      this.authService.sendSms(this.phone.replace(/[^0-9+]/g, ''), sms).subscribe((res) => {
        this.smsError = ''
        this.isSuccess = true;
        let user = JSON.parse(localStorage.getItem('user'));
        user.phone = this.phone;
        localStorage.setItem('user', JSON.stringify(user));
        this.cs.getBalance().subscribe((r) => {
          this.appState.set('balance', r['balance']);
        });
        this.appState.set('phoneConfirm', false)
        localStorage.removeItem('phoneConfirmation')
      }, (err) => {
        this.smsError = 'Введен не верный СМС-код'
        console.log(err);
      });
    }
  }

  changePhoneNumber() {
    this.phone = this.changedPhone
    this.user = JSON.parse(localStorage.getItem('user'))
    if(this.user) {
      this.cs.saveUsers({
        ...this.user,
        phone: this.phone.replace(/[^0-9+]/g, '')
      }).subscribe((res) => {
        this.getUserInfo()
        this.toast.pop('success', 'Изменения сохранены');
        this.isChangePhone = false
      }, err => {
        console.error(err);
        this.toast.pop('error', 'Системная ошибка, попробуйте позднее!');
      });
    }
  }

  getUserInfo() {
    this.cs.getInfo().toPromise()
    .then((res: any) => {
      localStorage.setItem('userInfo', JSON.stringify(res))
      this.phone = res.phone
      this.changedPhone = res.phone
    }).catch(err => {
      console.log(err);
    });
  }
  
  isPhoneNumber(phoneNumber: string) {
    this.isCorrectNumber = this.phone !== phoneNumber && phoneNumber.replace(/\D/g, '').length >= 11 
  }
}
