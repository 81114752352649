import { Injectable } from '@angular/core';

@Injectable()
export class AccessRightService {

  constructor() {
  }

  // История изменений, enabled_history
  get enabledHistory() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_HISTORY) {
      let a = ar.ENABLED_HISTORY;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6);
    } else {
      return null;
    }
  }

  // Мониторинг, enabled_monitoring
  get enabledMonitoring() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_MONITORING) {
      let a = ar.ENABLED_MONITORING;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6);
    } else {
      return null;
    }
  }

  // Мониторинг, enabled_contractors
  get enabledContractors() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_CONTRACTORS) {
      let a = ar.ENABLED_CONTRACTORS;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6);
    } else {
      return null;
    }
  }

  // Массовая проверка, enabled_bulk_access
  get enabledBulkAccess() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_BULK_ACCESS) {
      let a = ar.ENABLED_BULK_ACCESS;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && (a.totalCount > 0 || a.periodTypeId == 6); //dateFrom < cd && dateTo > cd &&
    } else {
      return null;
    }
  }

  // Расширенный анализ контрагентов, enabled_search_by_phone
  get enabledSearchByPhone() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_SEARCH_BY_PHONE) {
      let a = ar.ENABLED_SEARCH_BY_PHONE;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6); //dateFrom < cd && dateTo > cd &&
    } else {
      return null;
    }
  }

  // Расширенный анализ контрагентов, enabled_search_by_photo
  get enabledSearchByPhoto() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_SEARCH_BY_PHOTO) {
      let a = ar.ENABLED_SEARCH_BY_PHOTO;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6); //dateFrom < cd && dateTo > cd &&
    } else {
      return null;
    }
  }

  // Расширенный анализ контрагентов, enabled_sanctions
  get enabledSanctions() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_SANCTIONS) {
      let a = ar.ENABLED_SANCTIONS;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6); //dateFrom < cd && dateTo > cd &&
    } else {
      return null;
    }
  }

  // Офшоры, enabled_offshore
  get enabledOffshore() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_OFFSHORE) {
      let a = ar.ENABLED_OFFSHORE;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6); //dateFrom < cd && dateTo > cd &&
    } else {
      return null;
    }
  }

  // Расширенный анализ контрагентов, enabled_compare
  get enabledCompare() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_COMPARE) {
      let a = ar.ENABLED_COMPARE;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6); //dateFrom < cd && dateTo > cd &&
    } else {
      return null;
    }
  }

  // Расширенный СМИ, enabled_media
  get enabledMedia() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_MEDIA) {
      let a = ar.ENABLED_MEDIA;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6); //dateFrom < cd && dateTo > cd &&
    } else {
      return null;
    }
  }

  // Доступ к схеме связи, enabled_scheme
  get enabledScheme() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_SCHEME) {
      let a = ar.ENABLED_SCHEME;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6);
    } else {
      return null;
    }
  }

  // ЮЛ, enabled_company
  get enabledCompany() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_COMPANY) {
      let a = ar.ENABLED_COMPANY;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6);
    } else {
      return null;
    }
  }
  // ЮЛ
  get getCompanyAccess() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_COMPANY) {
      let a = ar.ENABLED_COMPANY;
      return a;
    } else {
      return null;
    }
  }

  // Сверка связи между компаниями, enabled_relation
  get enabledRelation() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_RELATIONS) {
      let a = ar.ENABLED_RELATIONS;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6);
    } else {
      return null;
    }
  }

  // Проверка компании СНГ, enabled_ru
  get enabledRu() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_RU) {
      let a = ar.ENABLED_RU;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6);
    } else {
      return null;
    }
  }

  // Мониторинг ФЛ, enabled_monitoring_fl
  get enabledMonitoringFl() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_MONITORING_FL) {
      let a = ar.ENABLED_MONITORING_FL;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6);
    } else {
      return null;
    }
  }

  // Доступ к схеме связи, enabled_scheme_fl
  get enabledSchemeFl() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_SCHEME_FL) {
      let a = ar.ENABLED_SCHEME_FL;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6);
    } else {
      return null;
    }
  }

  // ФЛ, enabled_person
  get enabledPerson() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_PERSON) {
      let a = ar.ENABLED_PERSON;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6)
    } else {
      return null;
    }
  }

  // ФЛ
  get getPersonAccess() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_PERSON) {
      let a = ar.ENABLED_PERSON;
      return a;
    } else {
      return null;
    }
  }

  // Внутренняя проверка
  get enabledInternalCheck() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_INTERNAL_CHECK) {
      let a = ar.ENABLED_INTERNAL_CHECK;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd && (a.leftCount > 0 && a.totalCount > 0 || a.periodTypeId == 6)
    } else {
      return null;
    }
  }

  // НДС
  get enabledNds() {
    let ar = JSON.parse(localStorage.getItem('accessRight'));
    if (ar && ar.ENABLED_NDS) {
      let a = ar.ENABLED_NDS;
      let dateFrom = a && this.formatDateDMY(a.dateFrom);
      let dateTo = a && this.formatDateDMY(a.dateTo);
      let cd = new Date().getTime();
      return a.active && dateFrom < cd && dateTo > cd
    } else {
      return null;
    }
  }

  formatDateDMY(v: any) {
    return v ? new Date(v.substr(6, 4) + '-' + v.substr(3, 2) + '-' + v.substr(0, 2)).getTime() : '';
  }

}


