import { Component } from '@angular/core';

@Component({
    selector: 'icon-duckgo',
    template: `<svg class="icon__svg" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99998 2.5C7.27002 2.5 5.72634 3.29837 4.71751 4.54849L7.09629 5.5H10.9037L13.2825 4.54849C12.2738 3.29849 10.7299 2.5 8.99998 2.5ZM8.99998 1.5C6.84485 1.5 4.93497 2.54922 3.75304 4.1627L2.1857 3.53576C2.03165 3.47415 1.85707 3.49295 1.71969 3.58597C1.5823 3.67898 1.5 3.83409 1.5 4V12C1.5 12.1659 1.5823 12.321 1.71969 12.414C1.85707 12.5071 2.03165 12.5259 2.1857 12.4642L3.90607 11.7761C5.08442 13.4243 7.01499 14.5 9.19728 14.5C11.3252 14.5 13.214 13.4771 14.3991 11.8982L15.8143 12.4642C15.9683 12.5259 16.1429 12.5071 16.2803 12.414C16.4177 12.321 16.5 12.1659 16.5 12V4C16.5 3.83409 16.4177 3.67898 16.2803 3.58597C16.1429 3.49295 15.9683 3.47415 15.8143 3.53576L14.2469 4.16272C13.0648 2.54908 11.1552 1.5 8.99998 1.5ZM13.4311 11.511L10.9037 10.5H7.0963L4.86716 11.3917C5.87453 12.676 7.43989 13.5 9.19728 13.5C10.8997 13.5 12.4217 12.7269 13.4311 11.511ZM10.5 9.5V6.5H7.5V9.5H10.5ZM11.5 9.66149L15.5 11.2615V4.73852L11.5 6.33852V9.66149ZM2.5 4.73852L6.5 6.33852V9.66149L2.5 11.2615V4.73852Z" fill="black"/>
    </svg>
    `
})

export class IconDuckGoComponent { }
