import { Component, OnInit, Input } from '@angular/core';
import { AppState } from '../../../app.service';

@Component({
    selector: 'app-uzb-search-item',
    templateUrl: './uzb-search-item.component.html',
    styleUrls: []
})
export class UzbSearchItemComponent implements OnInit {

    @Input() public data: any = {};

    constructor(
        public appState: AppState,
    ) { }

    ngOnInit() {
    }

}
