import { Directive, ElementRef, Input, Renderer, AfterViewChecked, AfterViewInit } from '@angular/core';
import { UtilsService } from '../services/utils.service';
@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective implements AfterViewChecked, AfterViewInit {
  @Input('appHighlight') appHighlight: string;
  public text: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer,
    private utils: UtilsService
  ) { }

  public ngAfterViewInit() {
    this.text = this.utils.highlight(this.el.nativeElement.innerText, this.appHighlight);
  }

  public ngAfterViewChecked() {
    this.el.nativeElement.innerHTML = this.text;
  }
}
