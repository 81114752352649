import { Injectable } from '@angular/core';
import { tokenNotExpired } from 'angular2-jwt';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class ConfirmService {

  constructor(private router: Router,
    private http: HttpClient) { }

  public confirm(token) {
    return this.http.get(`/users/registration/confirm?token=${token}`)
    .pipe(map((res) => {
      return res;
    }));
  }
}
