import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-exclamationsign',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './exclamationsign.component.html'
})

export class IconExclamationsignComponent {}
