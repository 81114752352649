import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-coffee',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './coffee.component.html'
})

export class IconCoffeeComponent {}
