import {
    Component,
    OnInit,
    OnDestroy
  } from '@angular/core';
  import { ActivatedRoute, Router } from '@angular/router';
  import { HttpClient } from '@angular/common/http';
  import { SearchService } from '../shared/services/search.service';
  import { UtilsService } from '../shared/services/utils.service';
  import { AuthRegPopupService } from '../shared/components/auth-reg-popup/auth-reg-popup.service';
  import { AuthService } from '../shared/services/auth.service';
  import { AppState } from '../app.service';
  import { TranslateService } from '@ngx-translate/core';
  import { WindowRef } from '../shared/services/window.ref';
  import * as moment from 'moment';

  @Component({
    selector: 'app-tools',
    templateUrl: './tools.component.html'
  })
  export class ToolsComponent {}
