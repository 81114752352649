import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ToasterService } from 'angular2-toaster';
import { AuthRegPopupService } from '../auth-reg-popup/auth-reg-popup.service';
import { AppState } from './../../../app.service';
import { take } from 'rxjs/operators';
import { CabinetService } from '../../services/cabinet.service';
import { BehaviorSubjectService } from '../../services/behavior-subject.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  visibilityLogin;
  visibilityRecovery;
  waiting: boolean = false;
  showPassword: boolean = false;
  email: any;
  password: any;
  emailPattern = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i

  constructor(
    private auth: AuthService,
    private authRegPopup: AuthRegPopupService,
    private toast: ToasterService,
    public appState: AppState,
    public cabinetService: CabinetService,
    public subjectService: BehaviorSubjectService
    ) {
    this.visibilityLogin = true;
  }

  ngOnInit() {
    window['authContext'] = this
    const script = document.createElement("script");
    script.id = "gsi-client";
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = this.initGoogleAuth
    document.head.appendChild(script);
  }

  initGoogleAuth() {
    const promise = new Promise(resolve => {
      if(window['google']) {
        window['google'].accounts.id.initialize({
          client_id: '186980979291-spief9boq402di0rtbg0t7lebmdm9uli.apps.googleusercontent.com',
          callback: (response) => {
            resolve(response.credential)
          }
        })
        const config = {
          size: 'large',
          theme: 'outline',
          width: 332
        }
        if(window.innerWidth < 380) {
          config.size = 'middle'
          config.width = 278
        }
        if(window.innerWidth < 330) {
          config.size = 'small'
          config.width = 210
        }
        window['google'].accounts.id.renderButton(document.getElementById('google-btn'), config)
      }
    })
    promise.then((token: string) => {
      window['authContext']['loginWithGoogle'](token)
    })
  }

  login(email: string, password: string) {
    this.waiting = true;
    this.auth.login(email, password)
    .pipe(take(1))
    .subscribe((res) => {
      this.getUserInfo()
      this.waiting = false;
      this.authRegPopup.close();
      // if (res['firstLogin']) {
      //   this.toast.pop('success', 'Поздравляем вам начислено 1000 бонусных единиц для проверки двух карточек.');
      // }
    }, (err) => {
      this.waiting = false;
      if (err.error && err.error.error_description === 'disabled') {
        this.toast.pop('error', `Не удалось войти. Скорее всего вы не активировали аккаунт, либо вы заблокированы Администрацией сервиса.
        Обратитесь в службу поддержки: support@kompra.kz`);
      }
      if (err.error && err.error.error_description === 'blocked') {
        this.toast.pop('error', `Вы ввели неверный пароль слишком много раз. Мы заблокировали вас на 5 минут.
        За дополнительной информацией обратитесь в службу поддержки: support@kompra.kz`);
      }
      if (err && err.error && err.error.error_description !== 'disabled' && err.error.error_description !== 'blocked') {
        this.toast.pop('error', `Ошибка, проверьте правильность введенных данных`);
      }
      console.log(err);
    });
  }

  public loginWithGoogle(token: string): void {
    this.waiting = true;
    this.auth.loginWithGoogle(token)
    .pipe(take(1))
    .subscribe(() => {
      this.getUserInfo()
      this.waiting = false;
      this.authRegPopup.close();
    }, (err) => {
      this.waiting = false;
      if (err.error && err.error.error_description === 'disabled') {
        this.toast.pop('error', `Не удалось войти. Скорее всего вы не активировали аккаунт, либо вы заблокированы Администрацией сервиса.
        Обратитесь в службу поддержки: support@kompra.kz`);
      }
      if (err.error && err.error.error_description === 'blocked') {
        this.toast.pop('error', `Вы ввели неверный пароль слишком много раз. Мы заблокировали вас на 5 минут.
        За дополнительной информацией обратитесь в службу поддержки: support@kompra.kz`);
      }
      if (err && err.error && err.error.error_description !== 'disabled' && err.error.error_description !== 'blocked') {
        this.toast.pop('error', `Ошибка, проверьте правильность введенных данных`);
      }
      console.log(err);
    });
  }

  // checkBlackFriday() {
  //   const result: boolean = JSON.parse(sessionStorage.getItem('blackfridaylogin') || 'false')
  //   if(result === true) {
  //     this.router.navigateByUrl('/mycabinet/payment')
  //     sessionStorage.removeItem('blackfridaylogin')
  //   }
  // }

  restore(email: string) {
    this.waiting = true;
    this.auth.recovery(email)
    .pipe(take(1))
    .subscribe((res) => {
      this.waiting = false;
      this.authRegPopup.close();
      this.toast.pop('success', `На указанный Вами адрес электронной почты отправлено
      письмо с инструкцией для восстановления пароля.`);
    }, (err) => {
      this.waiting = false;
      if (err && err.error && err.error.error === 'UserNotFound') {
        this.toast.pop('error', `Введенный e-mail не зарегистрирован.`);
      }
      console.log(err);
    });
  }

  getUserInfo() {
    this.cabinetService.getInfo().toPromise()
    .then((res: any) => {
      localStorage.setItem('userInfo', JSON.stringify(res))
    }).catch(err => {
      console.log(err);
    });
  }

  ngOnDestroy(): void {
    document.head.querySelector('#gsi-client').remove()
    if(window['authContext']) {
      delete window['authContext']
    }
  }
}
