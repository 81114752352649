import { Routes } from '@angular/router';
import { PromoComponent } from './pages/promo.component';
import { ToolsComponent } from './pages/tools.component';
import { ContactsComponent } from './pages/contacts.component';
import { TariffComponent } from './pages/tariff.component';
import { PublicofferComponent } from './pages/publicoffer.component';
import { RestoreComponent } from './pages/restore.component';
import { ConfirmComponent } from './pages/confirm.component';
import { RegisterComponent } from './pages/register.component';
import { NotFoundComponent } from './pages/404.component';
import { AgreedGuard } from './shared/agreed/agreed.guard';
import { PhotoComponent } from './modules/photo/photo.component';

export const ROUTES: Routes = [
  { path: '', component: PromoComponent },
  { path: 'tools', component: ToolsComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'tariff', component: TariffComponent },
  // { path: '404', component: NotFoundComponent },
  { path: 'publicoffer', component: PublicofferComponent },
  { path: 'confirm', component: ConfirmComponent },
  { path: 'restore', component: RestoreComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'search', loadChildren: './modules/search/search.module#SearchModule' },
  { path: 'filter', loadChildren: './modules/contractors/contractors.module#ContractorsModule', canActivateChild: [AgreedGuard] },
  { path: 'bulk', loadChildren: './modules/bulkcheck/bulkcheck.module#BulkcheckModule', canActivateChild: [AgreedGuard] },
  { path: 'interrelation', loadChildren: './modules/interrelation/interrelation.module#InterrelationModule', canActivateChild: [AgreedGuard] },
  { path: 'scheme/:id', loadChildren: './modules/scheme/scheme.module#SchemeModule', canActivateChild: [AgreedGuard] },
  { path: 'card/:id', loadChildren: './modules/card/card.module#CardModule', canActivateChild: [AgreedGuard] },
  { path: 'cardrf/:inn', loadChildren: './modules/cardrf/cardrf.module#CardRfModule' },
  { path: 'country/:country/:inn', loadChildren: './modules/cardfc/cardfc.module#CardFcModule', canActivateChild: [AgreedGuard] },
  { path: 'mycabinet', loadChildren: './modules/cabinet#CabinetModule', canActivateChild: [AgreedGuard] },
  { path: 'admin', loadChildren: './modules/admin#AdminModule', canActivateChild: [AgreedGuard] },
  { path: 'monitoring', loadChildren: './modules/monitoring#MonitoringModule', canActivateChild: [AgreedGuard] },
  { path: 'phone', loadChildren: './modules/getcontact/getcontact.module#GetContactModule', canActivateChild: [AgreedGuard] },
  { path: 'kko', loadChildren: './modules/kko/kko.module#KkoModule', canActivateChild: [AgreedGuard] },
  { path: 'sanctions', loadChildren: './modules/sanctions/sanctions.module#SanctionsModule', canActivateChild: [AgreedGuard] },
  { path: 'comparison', loadChildren: './modules/comparision/comparision.module#ComparisionModule', canActivateChild: [AgreedGuard] },
  { path: 'offshores', loadChildren: './modules/offshores/offshores.module#OffshoresModule', canActivateChild: [AgreedGuard] },
  { path: 'internal-check', loadChildren: './modules/internal-check/internal-check.module#InternalCheckModule', canActivateChild: [AgreedGuard] },
  { path: 'photo', component: PhotoComponent },
  { path: '**', component: NotFoundComponent }
];
