import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-checkcircleo',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './checkcircleo.component.html'
})

export class IconCheckcircleoComponent {}
