import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-placeholder',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './placeholder.component.html'
})

export class IconPlaceholderComponent {}
