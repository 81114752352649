import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-signout',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './signout.component.html'
})

export class IconSignoutComponent {}
