import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { SubscribeFormComponent } from './components/subscribe-form/subscribe-form.component';
import { SrItemComponent } from './components/sr-item/sr-item.component';
import { TreeviewComponent } from './components/treeview/treeview.component';
import { LiveSearchComponent } from './components/livesearch/livesearch.component';
import { CompleterRemoteComponent } from './components/completer-remote/completer-remote.component';
import { CompleterLocaleComponent } from './components/completer-locale/completer-locale.component';
import { ItemListComponent } from './components/item-list/item-list.component';

import { AuthRegPopupComponent } from './components/auth-reg-popup/auth-reg-popup.component';
import { AuthRegPopupService } from './components/auth-reg-popup/auth-reg-popup.service';
import { AccessRightService } from './services/access-right.service';
import { AuthService } from './services/auth.service';
import { AdminService } from './services/admin.service';
import { AuthGuard } from './services/auth.guard';
import { AuthRfGuard } from './services/authrf.guard';
import { UtilsService } from './services/utils.service';
import { CabinetService } from './services/cabinet.service';
import { SearchService } from './services/search.service';
import { SubscribeService } from './services/subscribe.service';
import { JwtHelper } from 'angular2-jwt';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderSmComponent } from './components/loader-sm/loader-sm.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { Pagination2Component } from './components/pagination2/pagination2.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { InvoiceFormComponent } from './components/invoice/invoice-form.component';
import { InvoiceButtonComponent } from './components/invoice/invoice-button.component';
import { MatDatepickerComponent } from './components/mat-datepicker/mat-datepicker.component';
import { IconModule } from './svgicons/icon.module';
import { AuthInterceptor } from '../interceptor/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EdsModule } from '../eds/eds.module';

import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CustomMinDirective } from './directives/custom-min-validator.directive';
import { DebounceDirective } from './directives/debounce.directive';
import { HighlightDirective } from './directives/highlight.directive';
import { SocialShareDirective } from './directives/social-share.directive';
import { EqualValidatorDirective } from './directives/validate.equal.directive';
import { RouterModule } from '@angular/router';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { NumberOnlyDirective } from './directives/number.only.directive';
import { ModeService } from './services/mode.service';
import { WindowRef } from './services/window.ref';
import { TextMaskModule } from 'angular2-text-mask';

import { OrderByPipe } from './pipes/orderby';
import { DecodeStringPipe } from './pipes/decodestring';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { AuthExpiredInterceptor } from '../interceptor/auth-expired.interceptor';
import { R3knInterceptor } from '../interceptor/r3kn.interceptor';
import { AgreedPopupComponent } from './agreed/agreed-popup.component';
import { AgreedPopupService } from './agreed/agreed-popup.service';
import { AgreedGuard } from './agreed/agreed.guard';
import { AuthRegGuard } from './components/auth-reg-popup/auth-reg.guard';
import { DpDatePickerModule } from 'ng2-date-picker';
import {
  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatInputModule
} from '@angular/material';
import { MonitoringPopupService } from './components/monitoring-popup/monitoring-popup.service';
import { MonitoringPopupComponent } from './components/monitoring-popup/monitoring-popup.component';
import { NotesComponent } from './components/notes/notes.component';
import { PersonalDataComponent } from './components/personal-data/personal-data.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { TempPermissionGuard } from './guards/temp-permission.guard';
import { GtagService } from './services/gtag.service';
import { TariffService } from '../modules/admin/components/users/access-tab/services/tariff.service';
import { PhotoComponent } from '../modules/photo/photo.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { PhotoService } from './services/photo.service';
import { QuizModalComponent } from './components/quiz-modal/quiz-modal.component';
import { SurveyService } from './services/survey.service';
import { BehaviorSubjectService } from './services/behavior-subject.service';
import { PhoneMask } from './directives/phone-number.directive';
import { PhoneConfirmationComponent } from './components/phone-confirmation/phone-confirmation.component';
import { CardNotFoundComponent } from '../modules/cardfc/components/card-not-found/card-not-found.component';
import { CytoscapeService } from '../modules/scheme/cytoscape/cytoscape.service';
import { OwnerHierarchyComponent } from './components/owner-hierarchy/owner-hierarchy.component';
import { UzbSearchItemComponent } from './components/uzb-search-item/uzb-search-item.component';
import { SynchronizationComponent } from '../modules/card/unreliability/synchronization/synchronization.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    EdsModule,
    IconModule,
    ToasterModule,
    RouterModule,
    ReactiveFormsModule,
    TextMaskModule,
    DpDatePickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    TreeviewComponent,
    LiveSearchComponent,
    CompleterRemoteComponent,
    CompleterLocaleComponent,
    ItemListComponent,
    AuthRegPopupComponent,
    AgreedPopupComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    SubscribeFormComponent,
    PaginationComponent,
    Pagination2Component,
    ClickOutsideDirective,
    CustomMinDirective,
    DebounceDirective,
    HighlightDirective,
    SocialShareDirective,
    DatepickerComponent,
    InvoiceFormComponent,
    InvoiceButtonComponent,
    EqualValidatorDirective,
    LoaderComponent,
    MonitoringPopupComponent,
    LoaderSmComponent,
    SrItemComponent,
    MatDatepickerComponent,
    PersonalDataComponent,
    OrderByPipe,
    DecodeStringPipe,
    SafeHtmlPipe,
    NotesComponent,
    NumberOnlyDirective,
    OnlyNumberDirective,
    PhotoComponent,
    QuizModalComponent,
    PhoneMask,
    PhoneConfirmationComponent,
    CardNotFoundComponent,
    OwnerHierarchyComponent,
    UzbSearchItemComponent,
    SynchronizationComponent
  ],
  entryComponents: [AuthRegPopupComponent],
  exports: [
    TreeviewComponent,
    LiveSearchComponent,
    CompleterRemoteComponent,
    CompleterLocaleComponent,
    ItemListComponent,
    AuthRegPopupComponent,
    AgreedPopupComponent,
    CommonModule,
    EdsModule,
    FormsModule,
    DpDatePickerModule,
    HttpClientModule,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    SubscribeFormComponent,
    PaginationComponent,
    Pagination2Component,
    IconModule,
    ClickOutsideDirective,
    CustomMinDirective,
    DebounceDirective,
    HighlightDirective,
    SocialShareDirective,
    PhoneMask,
    DatepickerComponent,
    InvoiceFormComponent,
    InvoiceButtonComponent,
    ToasterModule,
    EqualValidatorDirective,
    LoaderComponent,
    MonitoringPopupComponent,
    LoaderSmComponent,
    SrItemComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatDatepickerComponent,
    PersonalDataComponent,
    OrderByPipe,
    SafeHtmlPipe,
    NumberOnlyDirective,
    NotesComponent,
    TranslateModule,
    QuizModalComponent,
    PhoneConfirmationComponent,
    CardNotFoundComponent,
    OwnerHierarchyComponent,
    UzbSearchItemComponent,
    SynchronizationComponent
  ],
  providers: [
    AuthRegPopupService,
    AgreedPopupService,
    MonitoringPopupService,
    AgreedGuard,
    AuthRegGuard,
    TempPermissionGuard,
    AccessRightService,
    AuthService,
    AdminService,
    AuthGuard,
    AuthRfGuard,
    JwtHelper,
    UtilsService,
    SearchService,
    CabinetService,
    ToasterService,
    SubscribeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: R3knInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true,
    },
    WindowRef,
    ModeService,
    GtagService,
    TariffService,
    PhotoService,
    SurveyService,
    BehaviorSubjectService,
    CytoscapeService
  ],
})
export class SharedModule { }
