import { Component, OnInit, HostBinding } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { SubscribeService } from '../shared/services/subscribe.service';
import { AuthRegPopupService } from '../shared/components/auth-reg-popup/auth-reg-popup.service';
import { WindowRef } from '../shared/services/window.ref';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-404',
  templateUrl: './404.component.html'
})
export class NotFoundComponent  {
  @HostBinding('class') class = 'layout';
  constructor(
  public auth: AuthService,
  public winRef: WindowRef,
  public subsribeService: SubscribeService,
  private titleService: Title,
  private metaService: Meta,
  public authRegPopupService: AuthRegPopupService) {}
}
