import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppState } from '../app.service';
import { Subject } from 'rxjs/Subject';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '../shared/services/utils.service';

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html'
})
export class RestoreComponent implements OnInit {

  public restore: any = {
    email: '',
    password: '',
    confirmPassword: '',
    restoreFormShow: true,
    successTextShow: false,
    showWaitingEnterBtn: false,
    token: '',
    id: ''
  };

  constructor(
    public appState: AppState,
    public router: Router,
    public http: HttpClient,
    public utils: UtilsService,
    public activatedRouter: ActivatedRoute
  ) {
    this.utils.setTitle();
    this.utils.setTag();
  }

  public ngOnInit() {
    this.activatedRouter.queryParams.subscribe((params: Params) => {
      this.restore.token = params['token'];
      this.restore.email = params['username'];
      this.restore.id = params['id'];
    });
  }

  public submitState(password) {
    this.restore.showWaitingEnterBtn = true;
    this.http.post('/users/changePassword', this.getFormUrlEncoded({
      password: password,
      token: this.restore.token,
      id: this.restore.id
    }), {headers: {contentType: 'application/x-www-form-urlencoded; charset=utf-8'}})
      .subscribe((res) => {
      this.restore.showWaitingEnterBtn = false;
      this.restore.successTextShow = true;
      this.restore.restoreFormShow = false;
      this.router.navigateByUrl('/');
    });
  }

  private getFormUrlEncoded(obj): string {
    const formBody = [];

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(obj[key]);
        formBody.push(encodedKey + '=' + encodedValue);
      }
    }
    return formBody.join('&');
  }

}
