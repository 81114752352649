import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { AuthRegPopupService } from './auth-reg-popup.service';

@Injectable()
export class AuthRegGuard implements CanActivateChild {

  constructor(
    private router: Router,
    private authService: AuthService,
    private authRegPopupService: AuthRegPopupService
  ) { }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.authorized) {
      this.authRegPopupService.open();
      this.router.navigate([`/`]);
      return false;
    }
    return true;
  }

}
