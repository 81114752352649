import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Message } from '@stomp/stompjs';

import { Subscription } from 'rxjs/Subscription';
import { StompRService, StompConfig } from '@stomp/ng2-stompjs';
import { tokenNotExpired } from 'angular2-jwt';

type Key = 'company' | 'founder' | 'cases';

interface Model {
  company: Subject<any>;
  founder: Subject<any>;
  cases: Subject<any>;
}
interface Subcribe {
  company: Subscription;
  founder: Subscription;
  cases: Subscription;
}

interface Messages {
  company: Observable<Message>;
  founder: Observable<Message>;
  cases: Observable<Message>;
}

@Injectable()
export class WebsocketService {
  public messages: Messages = {
    company: new Observable<Message>(),
    founder: new Observable<Message>(),
    cases: new Observable<Message>()
  };
  private subscription: Subcribe = {
    company: new Subscription(),
    founder: new Subscription(),
    cases: new Subscription()
  };
  private data: Model = {
    company: new Subject<any>(),
    founder: new Subject<any>(),
    cases: new Subject<any>()
  };

  constructor(
    private stompRService: StompRService
  ) { }

  public initStomp() {
    let protocol: string = location.protocol === 'https:' ? 'wss' : 'ws';
    let token: any = JSON.parse(localStorage.getItem('token'));
    let validToken: any = token && token.access_token && tokenNotExpired('access_token', token.access_token) ? token.access_token : '';

    let url = `${protocol}://${location.hostname}:${location.port}/ws?access_token=${validToken}`;

    let config: StompConfig = {
      url: url,
      headers: {
        login: '',
        passcode: '',
        durable: 'false'
      },
      heartbeat_in: 10000,
      heartbeat_out: 10000,
      reconnect_delay: 0,
      debug: false
    };

    this.stompRService.config = config;
    if (validToken) {
      this.stompRService.initAndConnect();
    }
  }

  public subscribe(bin: string, key: Key) {
    let identityKey = Math.random().toString(36).substring(2, 10);
    this.messages[key] = this.stompRService.subscribe(`/topic/${key}.${bin}`, { id: `id-${identityKey}`, ack: `client`});
    this.subscription[key] = this.messages[key].subscribe((message: Message) => {
      this.data[key].next(JSON.parse(message.body));
      message.ack();
    });
  }

  public unsubscribes() {
    for (const key in this.subscription) {
      if (this.subscription.hasOwnProperty(key) && this.subscription[key]) {
        this.subscription[key].unsubscribe();
        this.subscription[key] = null;
      }
    }
    for (const key in this.messages) {
      if (this.messages.hasOwnProperty(key) && this.messages[key]) {
        this.messages[key] = null;
      }
    }
  }

  public disconnect() {
    this.unsubscribes();
    this.stompRService.disconnect();
  }

  public getData(key: Key): Observable<any> {
    return this.data[key].asObservable();
  }
}
