import {
  Component,
  Input,
  forwardRef,
} from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-mat-datepicker',
  templateUrl: './mat-datepicker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatDatepickerComponent),
      multi: true
    },
    DatePipe
  ],
})
export class MatDatepickerComponent implements ControlValueAccessor {
  @Input() title: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() isDisabled: boolean;
  @Input() isRequired: boolean;
  @Input() maxDate: any;
  @Input() minDate: any;
  public innerValue: Date = new Date()
  public focused: boolean = false;
  public uid: string;
  public mask = {
    guide: true,
    showMask : true,
    mask: [/\d/, /\d/, '.', /\d/, /\d/, '.',/\d/, /\d/,/\d/, /\d/]
  };
  public hasError: boolean = false

  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_: any) => void = () => { };

  constructor(public utils: UtilsService, public dateAdapter: DateAdapter<Date>, private datePipe: DatePipe) {
    this.placeholder = '';
    this.uid = this.utils.shortGuid();
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; }
  }

  get value() {
    let result
    try {
      result = this.datePipe.transform(this.innerValue, 'dd/MM/yyyy')
    } catch (error) {
      result = this.datePipe.transform(new Date(), 'dd/MM/yyyy')
    }
    return result
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  public onBlur(): void {
    this.onTouchedCallback();
  }

  public writeValue(value: any): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.checkFocus();
    }
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
    this.checkFocus();
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
    this.checkFocus();
  }

  public focus(): void {
    this.focused = true;
  }

  public blur(): void {
    this.checkFocus();
  }

  public checkFocus(): void {
    this.focused = this.utils.isEmpty(this.innerValue) ? false : true;
  }

  public onChange(event: Date): void {
    this.value = event;
    this.onBlur();
  }
  
  public todate(value: string): void {
    value = value.split('').filter(v => v != '_').join('')
    let month = 13
    if(value.length > 9) {
      month = +value.split('.')[1]
    }
    if(value === '..') {
      this.value = null
    }
    if((value.length < 10) || month > 12) {
      this.hasError = true
      if(value === '..') {
        this.hasError = false
      }
      return
    }
    this.value = new Date(value);
    this.hasError = false
  }
}
