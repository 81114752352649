import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-info',
  encapsulation: ViewEncapsulation.None,
  template: `<svg version="1.1" class="icon__svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512">
	<path d="M234.999 0c-129.777 0-235 105.26-235 235 0 129.816 105.222 235 235 235s235-105.183 235-235C470 105.26 364.778 0 234.999 0Zm0 424.515A189.516 189.516 0 1 1 424.516 235a189.4 189.4 0 0 1-189.517 189.515Zm0-320.281a39.8 39.8 0 1 1-39.8 39.8 39.8 39.8 0 0 1 39.8-39.8Zm53.066 240.685a11.372 11.372 0 0 1-11.372 11.371h-83.387a11.371 11.371 0 0 1-11.371-11.371v-22.742a11.371 11.371 0 0 1 11.371-11.371h11.371v-60.645h-11.371a11.372 11.372 0 0 1-11.371-11.371v-22.742a11.372 11.372 0 0 1 11.371-11.371h60.645a11.372 11.372 0 0 1 11.371 11.371v94.758h11.371a11.372 11.372 0 0 1 11.372 11.371Z"/>
</svg>
  `
})

export class IconInfoComponent { }
