import {Component, ElementRef, EventEmitter, OnInit, Output, Renderer2} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  currentYear: number = new Date().getFullYear()
  @Output() scrollToParent: EventEmitter<void> = new EventEmitter<void>();
  constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

  ngOnInit() {
  }
  scrollTo() {
    this.scrollToParent.emit();
  }
}
