import { Component, HostBinding } from '@angular/core';
import { AgreedPopupService } from './agreed-popup.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppState } from '../../app.service';
import { ToasterService } from 'angular2-toaster';

@Component({
    selector: 'app-agreed-popup',
    templateUrl: './agreed-popup.component.html'
})
export class AgreedPopupComponent {
  @HostBinding('class') class = 'layout__overlay layout__overlay--active';
  constructor(
    public router: Router,
    public appState: AppState,
    public toast: ToasterService,
    public activatedRoute: ActivatedRoute,
    public agreedPopupService: AgreedPopupService
  ) {}

  agree() {
    this.appState.agree().subscribe((res) => {
      let user = JSON.parse(localStorage.getItem('user'));
      user.termsAgreed = true;
      localStorage.setItem('user', JSON.stringify(user));
      this.agreedPopupService.close();
    }, (err) => {
      this.toast.pop('error', 'Техническая ошибка!');
    });
  }
}
