import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-invoice-button',
  templateUrl: './invoice-button.component.html'
})
export class InvoiceButtonComponent implements OnInit {
  @Output() public save: any = new EventEmitter();
  visibilityModal: boolean;
  toast: any;
  constructor() {
    this.visibilityModal = false;
  }

  ngOnInit() {
  }

  formSave(e) {
    this.save.emit(true);
    this.visibilityModal = false;
  }
}
