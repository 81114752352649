import {
  Component, ElementRef, EventEmitter, Input, keyframes, OnChanges,
  OnInit, Output, Renderer, SimpleChange, OnDestroy,
  animate, state, style, transition, trigger
} from '@angular/core';

import { Calendar } from './calendar';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-material-datepicker',
  animations: [
    trigger('calendarAnimation', [
      transition('* => left', [
        animate(180, keyframes([
          style({ transform: 'translateX(105%)', offset: 0.5 }),
          style({ transform: 'translateX(-130%)', offset: 0.51 }),
          style({ transform: 'translateX(0)', offset: 1 })
        ]))
      ]),
      transition('* => right', [
        animate(180, keyframes([
          style({ transform: 'translateX(-105%)', offset: 0.5 }),
          style({ transform: 'translateX(130%)', offset: 0.51 }),
          style({ transform: 'translateX(0)', offset: 1 })
        ]))
      ])
    ])
  ],
  styleUrls: ['./datepicker.scss'],
  templateUrl: './datepicker.component.html'
})
export class DatepickerComponent implements OnInit, OnChanges, OnDestroy {

  // two way bindings
  @Output() public dateChange = new EventEmitter<Date>();

  @Input() get date(): Date { return this.dateVal; }
  set date(val: Date) {
    this.dateVal = val;
    this.dateChange.emit(val);
  }

  // api bindings
  @Input() public disabled: boolean;
  @Input() public accentColor: string;
  @Input() public altInputStyle: boolean;
  @Input() public dateFormat: string;
  @Input() public fontFamily: string;
  @Input() public rangeStart: Date = new Date('1.1.1991');
  @Input() public rangeEnd: Date = new Date();
  // data
  @Input() public placeholder = 'Выберите дату';
  @Input() public inputText: string;
  // view logic
  @Input() public showCalendar: boolean;
  // events
  @Output() public select = new EventEmitter<Date>();
  // time
  @Input() public calendarDays: number[];
  @Input() public currentMonth: string;
  @Input() public dayNames: string[];
  @Input() public hoveredDay: Date;
  public calendar: Calendar;
  public currentMonthNumber: number;
  public currentYear: number;
  public hour: number;
  public minute: number;
  public months: string[];
  public years: number[] = [];
  // animation
  public animate: string;
  // colors
  public colors: { [id: string]: string };
  // listeners
  public clickListener: Function;
  public dateVal: Date;

  constructor(private renderer: Renderer, private elementRef: ElementRef, utils: UtilsService) {
    this.dateFormat = 'YYYY-MM-DD';
    // view logic
    this.showCalendar = false;
    // colors
    this.colors = {
      black: '#333333',
      blue: '#1285bf',
      lightGrey: '#f1f1f1',
      white: '#ffffff'
    };
    this.accentColor = this.colors['blue'];
    this.altInputStyle = false;
    // time
    this.calendar = new Calendar();
    this.dayNames = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
    this.months = [
      'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль',
      'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', ' Декабрь'
    ];
    // listeners
    this.clickListener = renderer.listenGlobal('document', 'click', (event: MouseEvent) => this.handleGlobalClick(event));
  }

  public ngOnInit() {
    this.setDate();
    let startYear: number = new Date().getFullYear();
    while (startYear !== 1990) {
      this.years.push(startYear);
      startYear--;
    }
  }

  public ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    if ((changes['date'] || changes['dateFormat'])) {
      this.setDate();
    }
  }

  public ngOnDestroy() {
    this.clickListener();
  }

  // State Management
  // ------------------------------------------------------------------------------------
  public closeCalendar(): void {
    this.showCalendar = false;
    this.setDate();
  }

  public setCurrentValues(date: Date) {
    let d: any = new Date(date);
    this.currentMonthNumber = d.getMonth();
    this.currentMonth = this.months[this.currentMonthNumber];
    this.currentYear = d.getFullYear();
    const calendarArray = this.calendar.monthDays(this.currentYear, this.currentMonthNumber);
    this.calendarDays = [].concat.apply([], calendarArray);
  }

  public setDate(): void {
    if (this.date) {
      this.setInputText(this.date);
      this.setCurrentValues(this.date);
    } else {
      this.inputText = '';
      this.setCurrentValues(new Date());
    }
  }

  public setCurrentMonth(monthNumber: number) {
    this.currentMonth = this.months[monthNumber];
    const calendarArray = this.calendar.monthDays(this.currentYear, this.currentMonthNumber);
    this.calendarDays = [].concat.apply([], calendarArray);
  }

  public setHoveredDay(day: Date): void {
    this.hoveredDay = day;
  }

  public removeHoveredDay(day: Date): void {
    this.hoveredDay = null;
  }

  public setInputText(date: Date): void {
    let d: any = new Date(date);
    let month: string = (d.getMonth() + 1).toString();
    if (month.length < 2) {
      month = `0${month}`;
    }
    let day: string = (d.getDate()).toString();
    if (day.length < 2) {
      day = `0${day}`;
    }
    let hour: string = (d.getHours()).toString();
    if (hour.length < 2) {
      hour = `0${hour}`;
    }
    let minute: string = (d.getMinutes()).toString();
    if (minute.length < 2) {
      minute = `0${minute}`;
    }

    let inputText: string;
    switch (this.dateFormat.toUpperCase()) {
      case 'YYYY-MM-DD':
        inputText = `${d.getFullYear()}.${month}.${day}`;
        break;
      case 'MM-DD-YYYY':
        inputText = `${month}.${day}.${d.getFullYear()}`;
        break;
      case 'DD-MM-YYYY':
        inputText = `${day}.${month}.${d.getFullYear()}`;
        break;
      case 'DD-MM-YYYY HH:MM':
        inputText = `${day}.${month}.${d.getFullYear()} ${hour}:${minute}`;
        break;
      default:
        inputText = `${d.getFullYear()}.${month}.${day}`;
        break;
    }

    this.inputText = inputText;
  }

  // Click Handlers
  // ------------------------------------------------------------------------------------
  public setYear(year: string): void {
    let newYear: number = parseInt(year, 10);
    let newMonth: number = this.currentMonthNumber;

    let newDate = new Date(newYear, newMonth);
    if (!this.rangeStart || newDate.getTime() >= this.rangeStart.getTime()) {
      this.currentYear = newYear;
      this.currentMonthNumber = newMonth;
      this.setCurrentMonth(newMonth);
    }
  }

  public onArrowLeftClick(): void {
    const currentMonth: number = this.currentMonthNumber;
    let newYear: number = this.currentYear;
    let newMonth: number;

    if (currentMonth === 0) {
      newYear = this.currentYear - 1;
      newMonth = 11;
    } else {
      newMonth = currentMonth - 1;
    }

    let newDate = new Date(newYear, newMonth);
    if (!this.rangeStart || newDate.getTime() >= this.rangeStart.getTime()) {
      this.currentYear = newYear;
      this.currentMonthNumber = newMonth;
      this.setCurrentMonth(newMonth);
      this.triggerAnimation('left');
    }
  }

  public onArrowRightClick(): void {
    const currentMonth: number = this.currentMonthNumber;
    let newYear: number = this.currentYear;
    let newMonth: number;

    if (currentMonth === 11) {
      newYear = this.currentYear + 1;
      newMonth = 0;
    } else {
      newMonth = currentMonth + 1;
    }

    let newDate = new Date(newYear, newMonth);
    if (!this.rangeEnd || newDate.getTime() <= this.rangeEnd.getTime()) {
      this.currentYear = newYear;
      this.currentMonthNumber = newMonth;
      this.setCurrentMonth(newMonth);
      this.triggerAnimation('right');
    }
  }

  public onCancel(): void {
    this.closeCalendar();
  }

  public onInputClick(): void {
    this.showCalendar = !this.showCalendar;
  }

  public onSelectDay(day: Date): void {
    this.date = day;
    if (this.hour) {
      this.date.setHours(this.hour);
    }
    if (this.minute) {
      this.date.setMinutes(this.minute);
    }
    this.select.emit(day);
    this.showCalendar = !this.showCalendar;
  }

  public onSelectHour(hour: number): void {
    this.date.setHours(hour);
    this.select.emit(this.date);
    this.setDate();
  }

  public onSelectMinute(minute: number): void {
    this.date.setMinutes(minute);
    this.select.emit(this.date);
    this.setDate();
  }

  // Listeners
  // ------------------------------------------------------------------------------------
  public handleGlobalClick(event: MouseEvent): void {

    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closeCalendar();
    }
  }

  // Helpers
  // ------------------------------------------------------------------------------------
  public getDayBackgroundColor(day: Date): string {
    let color = this.colors['white'];
    if (this.isChosenDay(day)) {
      color = this.accentColor;
    } else if (this.isCurrentDay(day)) {
      color = this.colors['lightGrey'];
    }
    return color;
  }

  public getDayFontColor(day: Date): string {
    let color = this.colors['black'];
    if (this.isChosenDay(day)) {
      color = this.colors['white'];
    }
    return color;
  }

  public isChosenDay(day: Date): boolean {
    if (day) {
      return this.date ? new Date(day).toDateString() === new Date(this.date).toDateString() : false;
    } else {
      return false;
    }
  }

  public isCurrentDay(day: Date): boolean {
    if (day) {
      return new Date(day).toDateString() === new Date().toDateString();
    } else {
      return false;
    }
  }

  public isHoveredDay(day: Date): boolean {
    return this.hoveredDay ? this.hoveredDay === day && !this.isChosenDay(day) : false;
  }

  public triggerAnimation(direction: string): void {
    this.animate = direction;
    setTimeout(() => this.animate = 'reset', 185);
  }
}
