import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-facebook',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './facebook.component.html'
})

export class IconFacebookComponent {}
