import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-car-banner',
    template: '<div id="car-banner"></div>',
    styles: [`
    #car-banner {
        margin-top: auto;
        z-index: 100;
        cursor: pointer;
    }
    @media(min-width: 761px) {
        #car-banner {
            height: 120px;
            background: url('/assets/img/Banner_car_desktop.png') no-repeat;
        }
    }
    @media(max-width: 760px) {
        #car-banner {
            object-fit: contain;
            height: 228px;
            margin: 0 auto;
            background: url('/assets/img/Banner_car_mobile.png') no-repeat;
            background-size: cover; 
        }
    }
`]
})
export class CarBannerComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
