import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-bookmark-alt',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './bookmark-alt.component.html'
})

export class IconBookmarkAltComponent {}
