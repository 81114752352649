import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs';

@Injectable()
export class EdsService {
  private webSocket = new WebSocket('wss://127.0.0.1:13579/');
  private heartbeat_msg = '--heartbeat--';
  private heartbeat_interval = null;
  private missed_heartbeats = 0;
  private missed_heartbeats_limit_min = 5;
  private missed_heartbeats_limit_max = 50;
  private missed_heartbeats_limit = this.missed_heartbeats_limit_min;
  private callback = null;
  private choosingCert = false;
  private eprocModuleStatus = false;
  private newChain = null;
  private saveChain = false;

  private data: Subject<any> = new Subject<any>();

  constructor() {
  }

  setMissedHeartbeatsLimitToMax = () => {
    this.missed_heartbeats_limit = this.missed_heartbeats_limit_max;
  }

  setMissedHeartbeatsLimitToMin = () => {
    this.missed_heartbeats_limit = this.missed_heartbeats_limit_min;
  }

  openDialog = () => {
    if (confirm('Ошибка при подключений к прослойке. Убедитесь что программа NCLayer запущена и нажмите ОК') === true) {
      location.reload();
    }
  }

  webSocketInit = () => {
    this.webSocket.onopen = (event) => {
      if (this.heartbeat_interval === null) {
        this.missed_heartbeats = 0;
        this.heartbeat_interval = setInterval(this.pingLayer, 2000);
      }
      console.log('Connection opened');
      this.checkVersion();
    };

    this.webSocketOnClose();

    this.webSocket.onmessage = (event) => {
      if (event.data === this.heartbeat_msg) {
        this.missed_heartbeats = 0;
        return;
      } else if (this.choosingCert) {
        this.choosingCert = false;
      }
      const result: any = JSON.parse(event.data);
      if (this.callback === 'checkVersion' && result['code'] === '200') {
        this.eprocModuleStatus = true;
      }
      if (this.saveChain) {
        this.newChain = result['newChain'];
      } else {
        this.newChain = null;
      }
      if (result != null) {
        const rw = {
          method: this.callback,
          result: result['responseObject'],
          secondResult: result['secondResult'],
          errorCode: result['code'],
          getResult: () => {
            return rw.result;
          },
          getSecondResult: () => {
            return rw.secondResult;
          },
          getErrorCode: () => {
            return rw.errorCode;
          }
        };
        // this[this.callback](rw);
        this.data.next(rw);
      }
      this.setMissedHeartbeatsLimitToMin();
    };
  }

  webSocketOnClose() {
    this.webSocket.onclose = (event) => {
      if (event.wasClean) {
        console.log('connection has been closed');
      } else {
        console.log('Connection error');
        this.openDialog();
      }
      this.eprocModuleStatus = false;
      console.log('Code: ' + event.code + ' Reason: ' + event.reason);
    };
  }

  pingLayer = () => {
    console.log('pinging...');
    try {
      this.missed_heartbeats++;
      if (this.missed_heartbeats >= this.missed_heartbeats_limit) {
        throw new Error('Too many missed heartbeats.');
      }
      this.webSocket.send(this.heartbeat_msg);
    } catch (e) {
      clearInterval(this.heartbeat_interval);
      this.heartbeat_interval = null;
      console.warn('Closing connection. Reason: ' + e.message);
      this.webSocket.close();
    }
  }

  getData = (): Observable<any> => {
    return this.data.asObservable();
  }

  getActiveTokens = () => {
    let getActiveTokens = {
      'module': 'kz.gov.pki.knca.commonUtils',
      'method': 'getActiveTokens'
    };
    this.callback = 'getActiveTokens';
    this.setMissedHeartbeatsLimitToMax();
    this.choosingCert = true;
    this.webSocket.send(JSON.stringify(getActiveTokens));
  }

  getKeyInfo = (storageName) => {
    let getKeyInfo = {
      'module': 'kz.gov.pki.knca.commonUtils',
      'method': 'getKeyInfo',
      'args': [storageName]
    };
    this.callback = 'getKeyInfo';
    this.setMissedHeartbeatsLimitToMax();
    this.choosingCert = true;
    this.webSocket.send(JSON.stringify(getKeyInfo));
  }

  signXml(storageName, keyType, xmlToSign, saveChain?) {
    let signXml = {
      'module': 'kz.gov.pki.knca.commonUtils',
      'method': 'signXml'
    };
    signXml['args'] = [storageName, keyType, xmlToSign, '', ''];
    this.saveChain = saveChain ? true : false;
    this.callback = 'signXml';
    this.setMissedHeartbeatsLimitToMax();
    this.choosingCert = true;
    this.webSocket.send(JSON.stringify(signXml));
  }

  checkVersion() {
    let signXml = {
      'module': 'kz.sapa.eproc.osgi.EprocModule',
      'method': 'version'
    };
    this.callback = 'checkVersion';
    this.setMissedHeartbeatsLimitToMax();
    this.choosingCert = true;
    this.webSocket.send(JSON.stringify(signXml));
  }

  createCMSSignatureFromFile = (storageName, keyType, filePath, flag) => {
    let createCMSSignatureFromFile = {
      'module': 'kz.gov.pki.knca.commonUtils',
      'method': 'createCMSSignatureFromFile',
      'args': [storageName, keyType, filePath, flag]
    };
    this.callback = 'createCMSSignatureFromFile';
    this.setMissedHeartbeatsLimitToMax();
    this.choosingCert = true;
    this.webSocket.send(JSON.stringify(createCMSSignatureFromFile));
  }

  showFileChooser(fileExtension, currentDirectory) {
    let showFileChooser = {
      'module': 'kz.gov.pki.knca.commonUtils',
      'method': 'showFileChooser',
      'args': [fileExtension, currentDirectory]
    };
    this.callback = 'showFileChooser';
    this.setMissedHeartbeatsLimitToMax();
    this.choosingCert = true;
    this.webSocket.send(JSON.stringify(showFileChooser));
  }

}
