import { Component, OnInit, HostBinding } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html'
})
export class ContactsComponent implements OnInit {
  @HostBinding('class') class = 'layout';

  constructor(
    private titleService: Title,
    private metaService: Meta
    ) { }

  ngOnInit() {
    this.titleService.setTitle(`Служба поддержки пользователей Kompra - звоните и пишите нам по всем вопросам.`);
    this.metaService.updateTag({
      name: 'description',
      content: `Мы на связи 24/7 - задавайте вопросы и делитесь комментариями.`
    });
    // this.metaService.updateTag({
    //   name: 'keywords',
    //   content: ``
    // });
  }

}
