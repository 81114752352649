import { take } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AuthRegPopupService } from '../auth-reg-popup/auth-reg-popup.service';
import { WindowRef } from '../../services/window.ref';
import { AppState } from '../../../app.service';
import { Router, NavigationEnd } from '@angular/router';
import { GtagService } from '../../services/gtag.service';
import { CabinetService } from '../../services/cabinet.service';
import { ToasterService } from 'angular2-toaster';
import { BehaviorSubjectService } from '../../services/behavior-subject.service';

declare var grecaptcha: any

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
  errorText: string;
  errors: any = {}
  waiting: boolean;
  success: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;

  public reg = {
    phone: '+998',
    email: '',
    password: '',
    confirmPassword: '',
    iAgree: false
  };
  emailPattern = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i

  googleAuthScript: HTMLScriptElement
  captchaScript: HTMLScriptElement

  private readonly siteKey: string = '6LduRUEnAAAAAPClF1s3kPWHImrYXl12r7W2fMSx'


  constructor(
    public authRegPopupService: AuthRegPopupService,
    protected auth: AuthService,
    public router: Router,
    private winRef: WindowRef,
    public gtagService: GtagService,
    public cs: CabinetService,
    private toast: ToasterService,
    public appState: AppState,
    public subjectService: BehaviorSubjectService
  ) {
    this.success = false;
    this.waiting = false;
  }

  ngOnInit() {
    this.createCaptcha();
    this.createGoogleScript()

  }
  createGoogleScript() {
    window['authContext'] = this
    this.googleAuthScript = document.createElement("script");
    this.googleAuthScript.id = "gsi-client";
    this.googleAuthScript.src = "https://accounts.google.com/gsi/client";
    this.googleAuthScript.onload = this.initGoogleAuth
    document.head.appendChild(this.googleAuthScript);
  }

  createCaptcha() {
    this.captchaScript = document.createElement("script");
    this.captchaScript.src = 'https://www.google.com/recaptcha/api.js?render=' + this.siteKey;
    document.head.appendChild(this.captchaScript);
  }


  initGoogleAuth() {
    const promise = new Promise(resolve => {
      if (window['google']) {
        window['google'].accounts.id.initialize({
          client_id: '186980979291-spief9boq402di0rtbg0t7lebmdm9uli.apps.googleusercontent.com',
          callback: (response) => {
            resolve(response.credential)
          }
        })
        const config = {
          size: 'large',
          theme: 'outline',
          width: 332
        }
        if (window.innerWidth < 380) {
          config.size = 'middle'
          config.width = 278
        }
        if (window.innerWidth < 330) {
          config.size = 'small'
          config.width = 210
        }
        window['google'].accounts.id.renderButton(document.getElementById('google-btn'), config)
      }
    })
    promise.then((token: string) => {
      window['authContext']['loginWithGoogle'](token)
    })
  }

  public loginWithGoogle(token: string): void {
    this.waiting = true;
    this.auth.loginWithGoogle(token)
      .pipe(take(1))
      .subscribe(() => {
        this.waiting = false;
        this.authRegPopupService.close();
      }, (err) => {
        this.waiting = false;
        if (err.error && err.error.error_description === 'disabled') {
          this.toast.pop('error', `Не удалось войти. Скорее всего вы не активировали аккаунт, либо вы заблокированы Администрацией сервиса.
        Обратитесь в службу поддержки: support@kompra.kz`);
        }
        if (err.error && err.error.error_description === 'blocked') {
          this.toast.pop('error', `Вы ввели неверный пароль слишком много раз. Мы заблокировали вас на 5 минут.
        За дополнительной информацией обратитесь в службу поддержки: support@kompra.kz`);
        }
        if (err && err.error && err.error.error_description !== 'disabled' && err.error.error_description !== 'blocked') {
          this.toast.pop('error', `Ошибка, проверьте правильность введенных данных`);
        }
        console.log(err);
      });
  }

  getUserInfo() {
    this.cs.getInfo().toPromise()
      .then((res: any) => {
        localStorage.setItem('userInfo', JSON.stringify(res))
      }).catch(err => {
        console.log(err);
      });
  }

  execute() {
    grecaptcha.ready(() => {
      grecaptcha.execute(this.siteKey, {action: 'registration'}).then((token: string) => {
        this.registration(token)
      });
    });
  }

  registration(captchaResponse: string) {

    this.errorText = '';
    this.waiting = true;
    const source = sessionStorage.getItem('utm_campaign')
    const data = source ?
    { email: this.reg.email, password: this.reg.password, source, phone: this.reg.phone.replace(/[^0-9+]/g, '') } : 
    { email: this.reg.email, password: this.reg.password, phone: this.reg.phone.replace(/[^0-9+]/g, '') }

    this.auth.registration(data, captchaResponse).subscribe((response) => {
      if (location.hostname === 'kompra.uz') {
        let self = this;
        setTimeout(function () {
          self.winRef.nativeWindow.dataLayer.push({ 'event': 'registration' });
        }, 100);
      }
      this.success = true;
      this.authRegPopupService.close();
      let arr = this.router.url.split('/');
      if (arr && arr.length && arr[1] === 'card') {
        let id = parseInt(arr[2], 10);
        localStorage.setItem('redirectId', id + '');
      }
      this.router.navigate(['/'])
      this.auth.setReferer(null);
      this.auth.login(this.reg.email, this.reg.password)
        .pipe(take(1))
        .subscribe((r) => {
          this.appState.set('phone-confirmation', true)
        });
    }, (err) => {
      this.waiting = false;
      if (err && err.error && err.error.error === 'UserAlreadyExist') {
        this.errors.emailError = 'Пользователь уже существует!';
      } else if (err && err.error && err.error.error === 'InvalidOldPassword') {
        this.errorText = 'Неправильный старый пароль!';
      } else if (err && err.error && err.error.error === 'UserNotFound') {
        this.errorText = 'Пользователь не найден!';
      } else if (err && err.error && err.error.error === 'MailError') {
        this.errorText = 'Не правильный емейл!';
      } else if (err && err.error && err.error.error === 'InternalError') {
        this.errorText = 'Системная ошибка, попробуйте позднее!';
      } else if (err && err.error && err.error.error.includes('PhoneAlreadyExist')) {
        this.errors.phoneError = 'Пользователь с таким номером уже существует.';
      } else {
        this.errorText = err.error.message;
      }
    });
    this.gtagService.gtag_report_registration()
  }
  ngOnDestroy(): void {
    document.head.removeChild(this.captchaScript)
    document.head.removeChild(this.googleAuthScript)
  }
}
