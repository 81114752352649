import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-caretleftdouble',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './caretleftdouble.component.html'
})

export class IconCaretleftdoubleComponent {}
