import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BehaviorSubjectService {
  private dataSources = new Map<string, BehaviorSubject<any>>();

  constructor() {}

  getData(key: string) {
    if (!this.dataSources.has(key)) {
      this.dataSources.set(key, new BehaviorSubject<any>(null));
    }
    return this.dataSources.get(key).asObservable();
  }

  updateData(key: string, data: any) {
    if (!this.dataSources.has(key)) {
      this.dataSources.set(key, new BehaviorSubject<any>(null));
    }
    this.dataSources.get(key).next(data);
  }
}
