import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthRegPopupService } from '../components/auth-reg-popup/auth-reg-popup.service';
import { AuthService } from './auth.service';
import { AppState } from '../../app.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private appState: AppState,
    private router: Router,
    private authRegPopupService: AuthRegPopupService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(state.url.split('/').includes('scheme') && route.queryParams && route.queryParams.mode === 'demo') {
      return true
    }
    let arr = state.url.split('/');
    let self = this;
    setTimeout(function() {
      self.appState.set('targetLink', state.url);
    }, 10);
    if (!(this.auth.authorized || this.appState.state['referralCode'])) {
      this.authRegPopupService.open();
      if (parseInt(arr[2], 10)) {
        this.router.navigate([`/card/${parseInt(arr[2], 10)}/report`]);
      } else {
        this.router.navigate([`/`]);
      }
      return false;
    }
    return true;
  }

}
