import {
    Component,
    ViewEncapsulation
} from '@angular/core';

@Component({
    selector: 'icon-empty-file',
    encapsulation: ViewEncapsulation.None,
    template: `
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_13858_146179)">
            <path d="M67.7969 15.2969L54.6875 2.1875C53.2812 0.78125 51.375 -0.015625 49.3906 -0.015625H17.5C13.3594 2.32831e-10 10 3.35937 10 7.5V72.5C10 76.6406 13.3594 80 17.5 80H62.5C66.6406 80 70 76.6406 70 72.5V20.6094C70 18.625 69.2031 16.7031 67.7969 15.2969ZM61.8906 20H50V8.10938L61.8906 20ZM17.5 72.5V7.5H42.5V23.75C42.5 25.8281 44.1719 27.5 46.25 27.5H62.5V72.5H17.5Z" fill="#E6E8ED"/>
            </g>
            <defs>
            <clipPath id="clip0_13858_146179">
            <rect width="80" height="80" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    `
})

export class EmptyFileIcon { }
