import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AppState } from '../app.service';
import { UtilsService } from '../shared/services/utils.service';

@Injectable()
export class R3knInterceptor implements HttpInterceptor {
  constructor(public appState: AppState, public utils: UtilsService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.headers.has('No-Intercept')) {
      const headers = req.headers.delete('No-Intercept');
      req = req.clone({ headers });
      return next.handle(req);
    }
    
    let a = [], b, o, l, I; b = this.appState.state;
    for (const key in b) {
      if (b.hasOwnProperty(key)) {
        if (key === 'fail') { a.push(b[key]); }
        if (key === 'baNaNa') { a.push(parseInt(b[b[key]], 16)); }
      }
    }
    I = this.utils.hashCode(req.url); a.push(I);
    l = a && a.length && a.reduce((c, x) => c + x);
    o = btoa(l + parseInt('f*ck', 16));
    let headers = {
      r: btoa(`k${o}a`)
    };
    if (b['referralCode']) {
      headers['referral_code'] = b['referralCode'];
    }
    req = req.clone({ setHeaders: headers });

    // if login with google
    if(req.url.includes('/oauth/google')) {
      const empty = req.clone({});
      return next.handle(empty)
    }
    return next.handle(req);
  }
}
