import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
// import { CanActivate } from '@angular/router';
import { AgreedPopupService } from './agreed-popup.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AgreedGuard implements CanActivateChild {

  constructor(
    private router: Router,
    private authService: AuthService,
    private agreedPopupService: AgreedPopupService
  ) { }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.authorized && !this.authService.agreed) {
      this.agreedPopupService.open();
      this.router.navigate([`/`]);
      return false;
    }
    return true;
  }

}
