import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '../../shared/services/utils.service';
import { AuthService } from '../../shared/services/auth.service';
import { CabinetService } from '../../shared/services/cabinet.service';
import { AuthRegPopupService } from '../../shared/components/auth-reg-popup/auth-reg-popup.service';
import { SearchService } from '../../shared/services/search.service';
import { WebsocketService } from './ws.service';
import { DomSanitizer, Title, Meta } from '@angular/platform-browser';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/observable/of';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Chart } from 'angular-highcharts';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModeService } from '../../shared/services/mode.service';
import { AccessRightService } from '../../shared/services/access-right.service';
import { ToasterService } from 'angular2-toaster';
import { AppState } from '../../app.service';

export type Sub = {
  [key: string]: Subscription
};

export type InternalStateType = {
  [key: string]: any
};

interface Params {
  name: string;
  value: string | number;
}

@Injectable()
export class CardState {

  chartOption$: Subject<any[]> = new BehaviorSubject<any>([]);
  model$: Subject<any> = new BehaviorSubject<any>({});
  founders$: Subject<any> = new BehaviorSubject<any>({});
  cases$: Subject<any> = new BehaviorSubject<any>({});
  model: any = {};
  okeds: any = [];
  counter: number;
  timeout: any = {};
  sub: Sub = {};

  constructor(
    private http: HttpClient,
    public authService: AuthService,
    public accessRightService: AccessRightService,
    public cabinetService: CabinetService,
    public authPopupService: AuthRegPopupService,
    public searchService: SearchService,
    public modeService: ModeService,
    public router: Router,
    public toast: ToasterService,
    public ws: WebsocketService,
    public utils: UtilsService,
    private titleService: Title,
    private metaService: Meta,
    private appState: AppState,
    public sanitizer: DomSanitizer
  ) {
    this.counter = 0;
  }

  private _state: InternalStateType = {};

  // already return a clone of the current state
  public get state() {
    return this._state = this._clone(this._state);
  }
  // never allow mutation
  public set state(value) {
    throw new Error('do not mutate the `.state` directly');
  }

  public get(prop?: any) {
    // use our state getter for the clone
    const state = this._state;
    return state.hasOwnProperty(prop) ? state[prop] : state;
  }

  public set(prop: string, value: any) {
    this.model[prop] = value;
    // internally mutate our state
    return this._state[prop] = value;
  }

  public clear() {
    this.counter = 0;
    this.model = {};
    this._state = {};
    this.clearExtendedData();
    this.ws.disconnect();
    for (const key in this.timeout) {
      if (this.timeout.hasOwnProperty(key)) {
        clearTimeout(this.timeout[key]);
      }
    }
    for (const key in this.sub) {
      if (this.sub.hasOwnProperty(key)) {
        this.sub[key].unsubscribe();
      }
    }
  }

  public clearExtendedData() {
    this.counter = 0;
    // this._state.founders = undefined;
    this._state.tenders = undefined;
    this._state.license = undefined;
    this._state.contract = undefined;
    this._state.history = undefined;
    this._state.cases = undefined;
    this._state.taxes = undefined;
    this._state.kompra = undefined;
    this._state.boardMembers = undefined;
    this._state.shareholder = undefined;
    this._state.affiliations = undefined;
    this._state.accountants = undefined;
    this._state.executives = undefined;
    this._state.relations = undefined;
    this._state.branches = undefined;
    this._state.bankruptyRehabilitation = undefined;
    this._state.sameOwner = undefined;
    this._state.sameLawAddress = undefined;
    // this.model.founders = undefined;
    this.model.tenders = undefined;
    this.model.license = undefined;
    this.model.contract = undefined;
    this.model.history = undefined;
    this.model.cases = undefined;
    this.model.taxes = undefined;
    this.model.kompra = undefined;
    this.model.boardMembers = undefined;
    this.model.shareholder = undefined;
    this.model.affiliations = undefined;
    this.model.accountants = undefined;
    this.model.executives = undefined;
    this.model.relations = undefined;
    this.model.branches = undefined;
    this.model.bankruptyRehabilitation = undefined;
    this.model.sameOwner = undefined;
    this.model.sameLawAddress = undefined;
  }

  private _clone(object: InternalStateType) {
    // simple object clone
    return Object.assign({}, object);
  }

  check() {
    this.counter = 0;
    if (this.model.id) {
      if ((this.authService.authorized || this.appState.state['referralCode']) && this.model.id) {
        this.sub.status = this.cabinetService.getStatus(this.model.id).subscribe((res) => {
          this.set('status', res['status']);
          this.set('schemeStatus', res['scheme']);
          this.set('schemeRefund', res['schemeRefund']);
          this.set('historyStatus', res['history']);
          this.set('type', res && res['person'] ? 'FL' : 'UL');
          this.set('boughtInfo', res);
          if (res['status'] === 'REFILL') {
            this.loadOpenData();
          } else if (res['status'] === 'BUY') {
            this.loadOpenData();
          } else if (res['status'] === 'REACHED_LIMIT') {
            this.loadOpenData();
            this.toast.pop('error', 'Количество проверок исчерпано!');
          } else if (res['status'] === 'PATH_NOT_ALLOWED') {
            this.loadOpenData();
            this.toast.pop('error', 'Отсутствует право доступа!');
          } else if (res['status'] === 'EXPIRED') {
            this.loadOpenData();
            this.toast.pop('error', 'Срок действия доступа истек!');
          } else if (res['status'] === 'BOUGHT') {
            this.loadOpenById('EXTENDED');
          }
          // this.loadOpenReviews()
          // this.getRating()
          // if (this.appState.state.referralCode) {
          //   this.getRating();
          // }
          if (res && res['person']) {
            this.set('type', 'FL');
            this.router.navigate(['/card', this.model.id, 'report'], { queryParams: { type: 'fl' } });
          }
        }, (err) => {
          console.log(err);
        });
        this.model.token = JSON.parse(localStorage.getItem('token'));
      } else {
        this.set('status', 'NOTAUTH');
        this.loadOpenData();
      }
    }
  }

  getExtendedData() {
    if (this.authService.authorized || this.appState['referralCode']) {
      if (this.model.status === 'REFILL') {
        this.set('refillPopupVisibility', true);
      } else if (this.model.status === 'BUY') {
        this.set('buyPopupVisibility', true);
      } else {
        this.check();
      }
    } else {
      this.authPopupService.open();
    }
  }

  loadOpenData() {
    // все бесплатные
    this.clearExtendedData();
    this.loadOpenById();
    this.loadOpenStatus();
    this.loadOpenFounders();
    this.loadOpenTenders();
    this.loadOpenLicenses();
    if (this.model.data && !this.model.data.ip && this.model.type !== 'FL') {
      this.loadOpenReviews()
      this.getRating()
      this.loadOpenTaxes();
    }
  }

  checkReportStatus() {
    if (this.model.dataLoading || this.model.statusLoading ||
      this.model.foundersLoading || this.model.tendersLoading ||
      this.model.licensesLoading || this.model.casesLoading ||
      this.model.taxesLoading || this.model.stkzLoading ||
      this.model.industLoading) {
      this.set('reportLoading', true);
      // || this.model.branchesLoading
    } else {
      this.set('reportLoading', false);
    }
    // if (this.model.data && !this.model.data.ip && this.model.data && this.model.data.iin) {
    //   this.set('type', 'FL');
    //   this.router.navigate(['/card', this.model.id, 'report'], { queryParams: {type: 'fl'} });
    // }
  }

  loadOpenReviews(page?, size?, timeRange?: 'month' | 'year', dateFrom?, dateTo?, order?: 'asc' | 'desc'): void {
    this.set('reviewsLoading', true);
    let r = this.get('reviews') || {};
    let arr = [];
    r.page = page || 0;
    r.size = size || 20;
    arr.push({ name: 'page', value: r.page });
    arr.push({ name: 'size', value: r.size });
    if (timeRange) { r.timeRange = timeRange || null; arr.push({ name: 'timeRange', value: timeRange }); }
    if (dateFrom) { r.dateFrom = dateFrom || null; arr.push({ name: 'dateFrom', value: dateFrom }); }
    if (dateTo) { r.dateTo = dateTo || null; arr.push({ name: 'dateTo', value: dateTo }); }
    if (order) { r.order = order || 'asc'; arr.push({ name: 'order', value: order }); }
    let p = this.utils.createUrlParams(arr);
    this.http.get(`/cabinet/reviews/open/${this.model.id}${p}`)
    .subscribe(
      (res: any) => {
        this.set('reviewsLoading', false);
        this.set('reviews', res);
      }, 
      (err) => {
        this.set('reviewsLoading', false)
        this.set('reviewsError', true)
      });
  }

  loadCompanyWorkersCount(id) {
    return this.http.get(`/company/workersCount/${id}`).toPromise()
  }
  
  loadOpenById(type?: 'EXTENDED' | 'OPEN') {
    this.set('dataLoading', true);
    this.set('dataError', false);
    this.checkReportStatus();
    if (type && type === 'EXTENDED') {
      this.sub.extendData = this.searchService.getByIdExtendedData(this.model.id).subscribe((res) => {
        this.set('dataLoading', false);
        this.setOpenData(res, type);
        this.checkReportStatus();
        if (this.counter < 1) {
          this.loadExtendedData();
        }
      }, (err) => {
        if (err && err.error['message'] === 'reached-limit') {
          this.toast.pop('error', 'Количество проверок исчерпано!');
          this.set('v403', true);
        } else if (err && err.error['message'] === 'requested-path-not-allowed') {
          this.toast.pop('error', 'Отсутствует право доступа!');
          this.set('v403', true);
        }
        this.set('dataLoading', false);
        this.set('dataError', true);
        this.checkReportStatus();
      });
    } else {
      this.sub.byId = this.searchService.getById(this.model.id).subscribe((res) => {
        this.set('dataLoading', false);
        this.setOpenData(res);
        this.checkReportStatus();
      }, (err) => {
        this.set('dataLoading', false);
        this.set('dataError', true);
        this.checkReportStatus();
      });
    }
  }

  getQuarantineStatus() {
    this.set('quarantineStatusLoading', true);
    this.http.get(`/api2/quarantine_access?identifier=${this.model.data && this.model.data.bin
      || this.model.data && this.model.data.iin}`).subscribe((res) => {
        this.set('quarantine', res);
        this.set('quarantineStatusLoading', false);
      }, (err) => this.set('quarantineStatusLoading', false));
  }

  getSchemeStatus(r?, type?) {
    this.set('schemeStatusLoading', true);
    this.http.get(`/node/${type === 'start' ? 'start' : 'status'}?id=${this.model.id}${r ? '&refresh=true' : ''}`).subscribe((res) => {
      this.set('scheme', res);
      this.set('schemeStatusLoading', false);
      if (res['status'] !== null && !(res['status'] === 'YES')) {
        let self = this;
        setTimeout(function () {
          self.getSchemeStatus();
        }, 30000);
      }
    }, (err) => this.set('schemeStatusLoading', false));
  }
  getPublicPerson() {
    this.set('publicPersonLoading', true);
    this.http.get(`/company/${this.model.id}/publicPerson`).subscribe((res: any) => {
      let pp = res && res.length && res[0].content && res[0].content;
      this.set('publicPersonFull', res);
      this.set('publicPerson', pp);
      this.set('publicPersonLoading', false);
    }, (err) => this.set('publicPersonLoading', false));
  }
  loadOpenStatus() {
    this.set('statusLoading', true);
    this.set('statusError', false);
    this.checkReportStatus();
    this.sub.openStatus = this.http.get(`/open-company/${this.model.id}/statuses`).subscribe((res) => {
      this.set('statusLoading', false);
      this.checkReportStatus();
    }, (err) => {
      this.set('statusLoading', false);
      this.set('statusError', true);
      this.checkReportStatus();
      // if (err.status === 404) {
      //   this.router.navigateByUrl('/404');
      // }
    });
  }
  loadOpenFounders() {
    this.set('foundersLoading', true);
    this.set('foundersError', false);
    this.checkReportStatus();
    this.sub.openFounders = this.http.get(`/open-company/${this.model.id}/founders`).subscribe((res) => {
      this.set('foundersLoading', false);
      this.checkReportStatus();
    }, (err) => {
      this.set('foundersLoading', false);
      this.set('foundersError', true);
      this.checkReportStatus();
      // if (err.status === 404) {
      //   this.router.navigateByUrl('/404');
      // }
    });
  }
  loadOpenTenders() {
    this.set('tendersLoading', true);
    this.set('tendersError', false);
    this.checkReportStatus();
    this.sub.openTenders = this.http.get(`/open-company/${this.model.id}/tenders`).subscribe((res) => {
      this.set('tendersLoading', false);
      this.checkReportStatus();
    }, (err) => {
      this.set('tendersLoading', false);
      this.set('tendersError', true);
      this.checkReportStatus();
      // if (err.status === 404) {
      //   this.router.navigateByUrl('/404');
      // }
    });
  }
  loadOpenLicenses() {
    this.set('licensesLoading', true);
    this.set('licensesError', false);
    this.checkReportStatus();
    this.sub.openLicenses = this.http.get(`/open-company/${this.model.id}/licenses`).subscribe((res) => {
      this.set('licensesLoading', false);
      this.checkReportStatus();
    }, (err) => {
      this.set('licensesLoading', false);
      this.set('licensesError', true);
      this.checkReportStatus();
      // if (err.status === 404) {
      //   this.router.navigateByUrl('/404');
      // }
    });
  }
  loadOpenCases() {
    this.set('casesLoading', true);
    this.set('casesError', false);
    this.checkReportStatus();
    this.sub.openCases = this.http.get(`/open-company/${this.model.id}/cases`).subscribe((res) => {
      this.set('casesLoading', false);
      this.checkReportStatus();
    }, (err) => {
      this.set('casesLoading', false);
      this.set('casesError', true);
      this.checkReportStatus();
      // if (err.status === 404) {
      //   this.router.navigateByUrl('/404');
      // }
    });
  }
  // loadOpenContracts() {
  //   this.set('contractsLoading', true);
  //   this.set('contractsError', false);
  //   this.checkReportStatus();
  //   this.sub.openContracts = this.http.get(`/open-company/${this.model.id}/contracts`).subscribe((res) => {
  //     this.set('contractsLoading', false);
  //     this.checkReportStatus();
  //   }, (err) => {
  //     this.set('contractsLoading', false);
  //     this.set('contractsError', true);
  //     this.checkReportStatus();
  //     if (err.status === 404) {
  //       this.router.navigateByUrl('/404');
  //     }
  //   });
  // }
  loadOpenTaxes() {
    this.set('taxesLoading', true);
    this.set('taxesError', false);
    this.checkReportStatus();
    this.sub.openTaxes = this.http.get(`/open-company/${this.model.id}/taxes`).subscribe((res) => {
      this.set('taxesLoading', false);
      this.checkReportStatus();
    }, (err) => {
      this.set('taxesLoading', false);
      this.set('taxesError', true);
      this.checkReportStatus();
      // if (err.status === 404) {
      //   this.router.navigateByUrl('/404');
      // }
    });
  }
  loadExtendedStatus() {
    this.set('statusLoading', true);
    this.set('statusError', false);
    this.checkReportStatus();
    this.sub.statuses = this.http.get(`/company/${this.model.id}/statuses`).subscribe((res) => {
      this.set('statusLoading', false);
      this.set('statusList', this.orderBy(res));
      this.checkReportStatus();
    }, (err) => {
      this.set('statusLoading', false);
      this.set('statusError', true);
      this.checkReportStatus();
    });
  }

  loadExtendedFlIp() {
    this.set('flipLoading', true);
    this.set('flipError', false);
    this.checkReportStatus();
    this.sub.searchIin = this.http.post(`api/search/company`, { code: 'KZ', filter: { biniin: this.model.data && this.model.data.iin } }).subscribe((res) => {
      this.set('flipLoading', false);
      this.set('flipList', res && res['content']);
      this.checkReportStatus();
    }, (err) => {
      this.set('flipLoading', false);
      this.set('flipError', true);
      this.checkReportStatus();
      // if (err.status === 404) {
      //   this.router.navigateByUrl('/404');
      // }
    });
  }

  loadExtendedUl() {
    this.set('flipLoading', true);
    this.set('flipError', false);
    this.sub.ulFounder = this.http.get(`/company/founded/${this.model.id}`)
      .subscribe(
        (res: any) => {
          if(res.graphStatus === 'SYNC') {
            setTimeout(() => {
              this.loadExtendedUl()
            }, 3000);
          }
          if(res.graphStatus === 'YES') {
            this.set('flipLoading', false);
            this.set('flipList', Array.isArray(res) ? res : [res]);
          }
          this.checkReportStatus();
        }, 
        (err) => {
          this.set('flipLoading', false);
          this.set('flipError', true);
          this.checkReportStatus();
          this.toast.pop('error', 'Техническая ошибка');
        }
      );
  }
  
  loadExtendedFlPart() {
    clearTimeout(this.timeout.fl);
    this.set('flpartLoading', true);
    this.set('flpartError', false);
    this.checkReportStatus();
    this.sub.singleId = this.http.get(`/node/single?id=${this.model.id}`).subscribe((res) => {
      let arr = [];
      if (res && res['founderList'] && res['founderList'].length) {
        res['founderList'].forEach((el) => {
          el['foundedEdoc'] = res['foundedEdoc'];
          arr.push(el);
        });
      }
      if (res && res['foundedCompanyList'] && res['foundedCompanyList'].length) {
        res['foundedCompanyList'].forEach((el) => {
          el['foundedEdoc'] = res['foundedEdoc'];
          arr.push(el);
        });
      }
      if (res && res['ownedCompanyList'] && res['ownedCompanyList'].length) {
        res['ownedCompanyList'].forEach((el) => {
          el['foundedEdoc'] = res['foundedEdoc'];
          arr.push(el);
        });
      }
      if (res && res['graphStatus'] === 'SYNC') {
        let self = this;
        this.timeout.fl = setTimeout(function () {
          self.loadExtendedFlPart();
        }, 15000);
      } else {
        this.set('flpartLoading', false);
        this.set('flpartList', arr);
        this.set('flpart', res);
      }
      this.checkReportStatus();
    }, (err) => {
      this.set('flpartLoading', false);
      this.set('flpartError', true);
      this.checkReportStatus();
    });
  }

  loadExtendedFlDeath() {
    this.sub.flDeath = this.http.get(`/fl/status?iin=${this.model.data.iin}`).subscribe((res) => {
      this.set('flDeath', res);
    }, (err) => {
    });
  }

  updateExtendedFlPart() {
    clearTimeout(this.timeout.flUpdate);
    this.set('flpartLoading', true);
    this.set('flpartError', false);
    this.checkReportStatus();
    this.sub.singleUpdateId = this.http.get(`/node/single/update?id=${this.model.id}`).subscribe((res) => {
      let arr = [];
      if (res && res['founderList'] && res['founderList'].length) {
        res['founderList'].forEach((el) => {
          el['foundedEdoc'] = res['foundedEdoc'];
          arr.push(el);
        });
      }
      if (res && res['foundedCompanyList'] && res['foundedCompanyList'].length) {
        res['foundedCompanyList'].forEach((el) => {
          el['foundedEdoc'] = res['foundedEdoc'];
          arr.push(el);
        });
      }
      if (res && res['ownedCompanyList'] && res['ownedCompanyList'].length) {
        res['ownedCompanyList'].forEach((el) => {
          el['foundedEdoc'] = res['foundedEdoc'];
          arr.push(el);
        });
      }
      if (res && res['graphStatus'] === 'SYNC') {
        let self = this;
        this.timeout.flUpdate = setTimeout(function () {
          self.loadExtendedFlPart();
        }, 15000);
      } else {
        this.set('flpartLoading', false);
        this.set('flpartList', arr);
        this.set('flpart', res);
      }
      this.checkReportStatus();
    }, (err) => {
      this.set('flpartLoading', false);
      this.set('flpartError', true);
      this.checkReportStatus();
    });
  }

  loadExtendedFounders() {
    clearTimeout(this.timeout.founders);
    this.set('foundersLoading', true);
    this.set('foundersError', false);
    this.checkReportStatus();
    this.sub.founders = this.http.get(`/founders/${this.model.id}${'?v=' + new Date().getTime()}`).subscribe((res) => {
      this.set('foundersLoading', false);
      let data = Object.assign({}, this.model.data);
      if (res && Array.isArray(res)) {
        data.founderList = res;
      }
      if (res && res['foundersStatus'] === 'SYNC') {
        let self = this;
        this.timeout.founders = setTimeout(function () {
          self.loadExtendedFounders();
        }, 30000);
      }
      if (res && res['foundersNum']) {
        data.foundersNum = res && res['foundersNum'];
      }
      this.set('data', data);
      this.founders$.next(data);
      this.loadOpenById('EXTENDED');
      this.checkReportStatus();
    }, (err) => {
      this.set('foundersLoading', false);
      if (this.model.data && this.model.data.founderList && !this.model.data.founderList.length) {
        this.set('foundersError', true);
      }
      this.checkReportStatus();
    });
    // this.http.get(`/company/${this.model.data && this.model.data.bin ? this.model.data.bin :
    //   this.model.data && this.model.data.iin ? this.model.data.iin : this.model.data && this.model.data.rnn}/founders`).subscribe((res) => {
    //   console.log(res);
    // }, (err) => {
    //   console.log(err);
    // });
  }
  updateExtendedFounders() {
    clearTimeout(this.timeout.founders);
    this.set('foundersLoading', true);
    this.set('foundersError', false);
    this.checkReportStatus();
    this.sub.founderUpdate = this.http.get(`/founders/${this.model.id}/update`).subscribe((res) => {
      this.set('foundersLoading', false);
      let data = Object.assign({}, this.model.data);
      if (res && Array.isArray(res)) {
        data.founderList = res;
      }
      if (res && res['foundersStatus'] === 'SYNC') {
        let self = this;
        this.timeout.founders = setTimeout(function () {
          self.loadExtendedFounders();
        }, 30000);
      }
      if (res && res['foundersNum']) {
        data.foundersNum = res && res['foundersNum'];
      }
      this.set('data', data);
      this.founders$.next(data);
      this.loadOpenById('EXTENDED');
      this.checkReportStatus();
    }, (err) => {
      this.set('foundersLoading', false);
      if (this.model.data && this.model.data.founderList && !this.model.data.founderList.length) {
        this.set('foundersError', true);
      }
      this.checkReportStatus();
    });
  }
  loadExtendedTenders(page, filter?, params: {name: string, value: string}[] = []) {
    this.set('tendersLoading', true);
    this.set('tendersError', false);
    const allParams = [{
      name: 'company',
      value: this.model.id
    }, {
      name: 'size',
      value: this.model.tenders && this.model.tenders.size ? this.model.tenders.size : 10
    }, {
      name: 'page',
      value: page ? page : 0
    }].concat(params)
    let p: string = this.utils.createUrlParams(allParams);
    let f: any = {
      filters: {}
    };
    if (filter && filter.region) {
      f.filters.region = filter.region;
    }
    if (filter && filter.area) {
      f.filters.area = filter.area;
    }
    if (filter && filter.category) {
      f.filters.category = filter.category;
    }
    if (filter && filter.method) {
      f.filters.method = filter.method;
    }
    this.checkReportStatus();
    this.sub.tenders = this.http.post(`/tenders/_filter${p}`, f && Object.keys(f.filters).length > 0 ? f : '').subscribe((res) => {
      let r = res ? res : {};
      r['page'] = page ? page : 0;
      this.set('tendersLoading', false);
      this.set('tenders', r);
      this.checkReportStatus();
    }, (err) => {
      this.set('tendersLoading', false);
      this.set('tendersError', true);
      this.checkReportStatus();
    });
  }
  loadExtendedLicenses(page) {
    this.set('licensesLoading', true);
    this.set('licensesError', false);
    let size = this.model.license && this.model.license.size ? this.model.license.size : 10;
    let p = page ? page : 0;
    let u = this.utils.getUinType(this.model.data && this.model.data.bin) === 'bin' ? `bin=${this.model.data.bin}` :
      this.model.data && this.model.data.iin ? `iin=${this.model.data.iin}` : `rnn=${this.model.data && this.model.data.rnn}`;
    this.checkReportStatus();
    this.sub.license = this.http.get(`/company/license?${u}&size=${size}&page=${p}`).subscribe((res) => {
      let r = res ? res : {};
      r['page'] = page ? page : 0;
      this.set('licensesLoading', false);
      this.set('license', r);
      this.checkReportStatus();
    }, (err) => {
      this.set('licensesLoading', false);
      this.set('licensesError', true);
      this.checkReportStatus();
    });
  }
  loadExtendedDfo(page, filter?) {
    this.set('dfoLoading', true);
    this.set('dfoError', false);
    this.checkReportStatus();
    let size = this.model.dfo && this.model.dfo.size ? this.model.dfo.size : 10;
    let p = page ? page : 0;
    this.sub.dfo = this.http.get(`/company/${this.model.data.id}/certificates?size=${size}&page=${p}&typeId=${1}`).subscribe((res) => {
      let r = res ? res : {};
      r['page'] = page ? page : 0;
      this.set('dfoLoading', false);
      this.set('dfo', r);
      this.checkReportStatus();
    }, (err) => {
      this.set('dfoLoading', false);
      this.set('dfoError', true);
      this.checkReportStatus();
    });
  }
  loadExtendedStkz(page, filter?) {
    this.set('stkzLoading', true);
    this.set('stkzError', false);
    this.checkReportStatus();
    let size = this.model.stkz && this.model.stkz.size ? this.model.stkz.size : 10;
    let p = page ? page : 0;
    this.sub.stkz = this.http.get(`/company/${this.model.data.id}/certificates?size=${size}&page=${p}&typeId=${2}`).subscribe((res) => {
      let r = res ? res : {};
      r['page'] = page ? page : 0;
      this.set('stkzLoading', false);
      this.set('stkz', r);
      this.checkReportStatus();
    }, (err) => {
      this.set('stkzLoading', false);
      this.set('stkzError', true);
      this.checkReportStatus();
    });
  }
  loadExtendedIndust(page, filter?) {
    this.set('industLoading', true);
    this.set('industError', false);
    this.checkReportStatus();
    let size = this.model.indust && this.model.indust.size ? this.model.indust.size : 10;
    let p = page ? page : 0;
    this.sub.indust = this.http.get(`/company/${this.model.data.id}/certificates?size=${size}&page=${p}&typeId=${3}`).subscribe((res) => {
      let r = res ? res : {};
      r['page'] = page ? page : 0;
      this.set('industLoading', false);
      this.set('indust', r);
      this.checkReportStatus();
    }, (err) => {
      this.set('industLoading', false);
      this.set('industError', true);
      this.checkReportStatus();
    });
  }
  loadExtendedSmk(page, filter?) {
    this.set('smkLoading', true);
    this.set('smkError', false);
    this.checkReportStatus();
    let size = this.model.smk && this.model.smk.size ? this.model.smk.size : 10;
    let p = page ? page : 0;
    this.sub.smk = this.http.get(`/company/${this.model.data.id}/certificates?size=${size}&page=${p}&typeId=${4}`).subscribe((res) => {
      let r = res ? res : {};
      r['page'] = page ? page : 0;
      this.set('smkLoading', false);
      this.set('smk', r);
      this.checkReportStatus();
    }, (err) => {
      this.set('smkLoading', false);
      this.set('smkError', true);
      this.checkReportStatus();
    });
  }
  loadExtendedEA3C(page, filter?) {
    this.set('ea3cLoading', true);
    this.set('ea3cError', false);
    this.checkReportStatus();
    let size = this.model.ea3c && this.model.ea3c.size ? this.model.ea3c.size : 10;
    let p = page ? page : 0;
    this.sub.ea3c = this.http.get(`/company/${this.model.data.id}/certificates?size=${size}&page=${p}&typeId=${5}`).subscribe((res) => {
      let r = res ? res : {};
      r['page'] = page ? page : 0;
      this.set('ea3cLoading', false);
      this.set('ea3c', r);
      this.checkReportStatus();
    }, (err) => {
      this.set('ea3cLoading', false);
      this.set('ea3cError', true);
      this.checkReportStatus();
    });
  }
  loadExtendedDekl1(page, filter?) {
    this.set('dekl1Loading', true);
    this.set('dekl1Error', false);
    this.checkReportStatus();
    let size = this.model.dekl1 && this.model.dekl1.size ? this.model.dekl1.size : 10;
    let p = page ? page : 0;
    this.sub.dekl1 = this.http.get(`/company/${this.model.data.id}/declarations?size=${size}&page=${p}&typeId=${1}`)
      .subscribe((res) => {
        let r = res ? res : {};
        r['page'] = page ? page : 0;
        this.set('dekl1Loading', false);
        this.set('dekl1', r);
        this.checkReportStatus();
      }, (err) => {
        this.set('dekl1Loading', false);
        this.set('dekl1Error', true);
        this.checkReportStatus();
      });
  }
  loadExtendedDekl2(page, filter?) {
    this.set('dekl2Loading', true);
    this.set('dekl2Error', false);
    this.checkReportStatus();
    let size = this.model.dekl2 && this.model.dekl2.size ? this.model.dekl2.size : 10;
    let p = page ? page : 0;
    this.sub.dekl2 = this.http.get(`/company/${this.model.data.id}/declarations?size=${size}&page=${p}&typeId=${2}`)
      .subscribe((res) => {
        let r = res ? res : {};
        r['page'] = page ? page : 0;
        this.set('dekl2Loading', false);
        this.set('dekl2', r);
        this.checkReportStatus();
      }, (err) => {
        this.set('dekl2Loading', false);
        this.set('dekl2Error', true);
        this.checkReportStatus();
      });
  }
  loadExtendedContracts(page, filter?) {
    this.set('contractsLoading', true);
    this.set('contractsError', false);
    let p: string = this.utils.createUrlParams([{
      name: 'company',
      value: this.model.id
    }, {
      name: 'size',
      value: this.model.contracts && this.model.contracts.size ? this.model.contracts.size : 10
    }, {
      name: 'page',
      value: page ? page : 0
    }]);
    let f: any = {
      filters: {}
    };
    // if (filter && filter.region) {
    //   f.filters.region = filter.region;
    // }
    // if (filter && filter.area) {
    //   f.filters.area = filter.area;
    // }
    // if (filter && filter.category) {
    //   f.filters.category = filter.category;
    // }
    // if (filter && filter.method) {
    //   f.filters.method = filter.method;
    // }
    this.checkReportStatus();
    this.sub.contracts = this.http.get(`/company/${this.model.data.id}/contracts${p}`).subscribe((res) => { // f && Object.keys(f.filters).length > 0 ? f : ''
      if (res && res['content'] && res['content'].length) {
        res['content'].forEach((el) => {
          if(typeof el.content === 'string') {
            el.content = JSON.parse(el.content);
          }
        });
      }
      let r = res ? res : {};
      r['page'] = page ? page : 0;
      this.set('contractsLoading', false);
      this.set('contracts', r);
      this.checkReportStatus();
    }, (err) => {
      this.set('contractsLoading', false);
      this.set('contractsError', true);
      this.checkReportStatus();
    });
  }
  loadExtendedCases(page) {
    this.set('casesLoading', true);
    this.set('casesError', false);
    let p: string = this.utils.createUrlParams([{
      name: 'company',
      value: this.model.id
    }, {
      name: 'size',
      value: this.model.cases && this.model.cases.size ? this.model.cases.size : 10
    }, {
      name: 'page',
      value: page ? page : 0
    }]);
    this.checkReportStatus();
    this.sub.cases = this.http.get(`/cases${p}`).subscribe((res) => {
      let r = res ? res : {};
      r['page'] = page ? page : 0;
      this.set('casesLoading', false);
      this.set('cases', r);
      this.checkReportStatus();
    }, (err) => {
      this.set('casesLoading', false);
      this.set('casesError', true);
      this.checkReportStatus();
    });
  }
  loadExtendedCasesByRequest(page) {
    clearTimeout(this.timeout.cases);
    if (!(this.model.casesByRequest && this.model.casesByRequest.content && this.model.casesByRequest.content.length)) {
      this.set('casesByRequestLoading', true);
    }
    this.set('casesByRequestError', false);
    let p: string = this.utils.createUrlParams([{
      name: 'iinbin',
      value: this.model && this.model.data && (this.model.data.bin || this.model.data.iin)
    }, {
      name: 'size',
      value: this.model.casesByRequest && this.model.casesByRequest.size ? this.model.casesByRequest.size : 10
    }, {
      name: 'page',
      value: page ? page : 0
    }]);
    this.checkReportStatus();
    return this.http.get(`/api2/cases/list${p}`).toPromise()
      .then((res) => {
      this.set('casesByRequestLoading', false);
      this.set('casesByRequestStatus', res['status']);
      if (res && res['status'] === 'SYNC') {
        let self = this;
        this.timeout.cases = setTimeout(function () {
          self.loadExtendedCasesByRequest(0);
        }, 10000);
        if (res && res['content'] && res['content'] === 0) {
          this.set('casesByRequestLoading', true);
        }
      }
      if (res && res['content'] && Array.isArray(res['content'])) {
        this.set('casesByRequestLoading', false);
        let r = res ? res : {};
        r['page'] = page ? page : 0;
        r['totalCount'] = res['type1Count'] + res['type2Count'] + res['type3Count'];
        this.set('casesByRequest', r);
        this.checkReportStatus();
      }
      return this.model.casesByRequest
    })
    .catch((err) => {
      this.set('casesByRequestLoading', false);
      this.set('casesByRequestError', true);
      this.checkReportStatus();
    });
  }

  loadScoring() {
    this.set('scoringLoading', true);
    this.set('scoringError', false);
    this.checkReportStatus();
    let u = this.utils.getUinType(this.model.data && this.model.data.bin) === 'bin' ? `bin=${this.model.data.bin}` :
      this.model.data && this.model.data.iin ? `iin=${this.model.data.iin}` : `rnn=${this.model.data && this.model.data.rnn}`;
    this.sub.openScoring = this.http.get(`/company/risk-degree?${u}`).subscribe((res) => {
      if (res && ['INIT', 'SYNC'].includes(res['status'])) {
        let self = this;
        this.timeout.loadScoring = setTimeout(function () {
          self.loadScoring();
        }, 15000);
      } else {
        this.set('scoring', res);
        this.set('scoringLoading', false);
        this.checkReportStatus();
      }
    }, (err) => {
      this.set('scoringLoading', false);
      this.set('scoringError', true);
      this.checkReportStatus();
    });
  }

  loadIdn() {
    this.set('idnLoading', true);
    this.set('idnError', false);
    this.sub.idn = this.http.get(`/company/${this.model.id}/idn`).subscribe((res) => {
      this.set('idn', res);
      this.set('idnLoading', false);
    }, (err) => {
      this.set('idnLoading', false);
      this.set('idnError', true);
    });
  }

  updateTaxes() {
    this.set('taxesLoading', true);
    this.sub.taxes = this.http.get(`/taxes/update?company=${this.model.id}`).subscribe(
      (res) => {
        this.loadExtendedTaxes();
      },
      (err) => {
        console.error(err);
        this.set('taxesLoading', false);
      }
    );
  }
  loadExtendedTaxes() {
    this.set('taxesLoading', true);
    this.set('taxesError', false);
    this.checkReportStatus();
    this.sub.taxes = this.http.get(`/taxes?company=${this.model.id}`).subscribe((res) => {
      this.loadExtendedStatus();
      this.loadExtendedTaxesDetails();
      this.set('taxesLoading', false);
      let taxes = res;
      let arr = [];
      if (res['content'] && res['content'].length) {
        res['content'].forEach(el => {
          el['status'] = 'SYNC';
          arr.push(el);
        });
        taxes['content'] = arr;
        // let lco = JSON.parse(JSON.stringify(this.model.lineChartOptions ? this.model.lineChartOptions : {}));
        let lco = this.model.lineChartOptions ? this.model.lineChartOptions : {};
        lco = lco && lco.series && lco.series[0] && lco.series[0].data && lco.series[0].data.length ? lco : new Chart({
          series: [{
            data: []
          }]
        });

        // lco = lco && lco.dataTable && lco.dataTable.length ? lco : {
        //   chartType: 'LineChart',
        //   dataTable: [
        //     ['Год', 'Сумма', { role: 'annotation' }, { role: 'style' }]
        //   ],
        //   options: {
        //     legend: 'none'
        //   }
        // };
        let years = [];
        let amounts = [];
        let arrs = JSON.parse(JSON.stringify(res['content']));
        arrs.sort((a, b) => a.amount - b.amount);
        let maxTax = arrs[arrs.length - 1];
        this.set('maxTax', maxTax);
        let averageSum = this.utils.gaussRound(res['total'] / res['content'].length, 2);
        this.set('maxTax', maxTax);
        this.set('averageSum', averageSum);
        this.set('taxes', taxes);
        res['content'].sort((a, b) => {
          return a && b && (a.year < b.year) ? -1 : (a.year > b.year) ? 1 : 0;
        }).forEach((el, idx, array) => {
          if (el.year) {
            if (idx === array.length - 1) {
              el.checked = true;
            }
            years.push(el.year);
            amounts.push(parseFloat(this.utils.gaussRound(el.amount, 2)));
          }
        });
        this.set('curTax', {
          year: res['content'][res['content'].length - 1].year,
          amount: res['content'][res['content'].length - 1].amount,
          dynamics: res['content'][res['content'].length - 1].dynamics,
        });
        this.loadExtendedTaxesDetailsKbk();
        let minYear = Math.min.apply(null, years);
        this.set('minYear', minYear);
        let self = this;
        lco = new Chart({
          title: {
            text: null
          },
          chart: {
            type: 'column'
          },
          plotOptions: {
            column: {
              dataLabels: {
                enabled: true
              },
              events: {
                click: function (e) {
                  self.set('curTax', res['content'].find((el) => el.amount === e['point'].y))
                }
              }
            },
            series: {
              shadow: false,
            }
          },
          xAxis: {
            title: null,
            categories: years
          },
          yAxis: {
            title: null
          },
          series: [{
            name: 'Налоговые отчисления',
            data: amounts
          }]
        });

        // this.set('lineChartOptions', JSON.parse(JSON.stringify(lco)));
        this.set('lineChartOptions', lco);
        this.checkReportStatus();
        this.chartOption$.next(lco);
      }
      if (res && res['status'] === null) {
        this.set('taxesError', true);
      }
      this.set('taxes', taxes);
    }, (err) => {
      this.set('taxesLoading', false);
      this.set('taxesError', true);
      this.checkReportStatus();
    });
  }
  loadExtendedTaxesDetails() {
    this.sub.taxes = this.http.get(`/tax-details/${this.model.id}`).subscribe((res) => {
      this.set('taxesLoading', false);
      // this.set('tax-details', res);
      if (this.model.taxes && this.model.taxes.content && this.model.taxes.content.length) {
        this.model.taxes.content.sort((a, b) => {
          return a && b && (a.year < b.year) ? -1 : (a.year > b.year) ? 1 : 0;
        }).forEach((el) => {
          let obj = res['content'].find(e => e.year === el.year);
          if (obj && obj.content && obj.content.length) {
            el.status = res['status'] === true ? 'YES' : res['status'] === false ? 'NO' : 'OFF';
          } else {
            el.status = 'NO';
            el.content = [];
          }
        });
      }
    }, (err) => {
    });
  }
  loadExtendedTaxesDetailsKbk() {
    let t = this.model.taxes && this.model.taxes.content;
    let p = t && t.length ? '?years=' + t.filter((r) => r.checked).map((r) => r.year).join('&years=') : '';
    this.sub.taxes = this.http.get(`/tax-details/kbk/${this.model.id}${p}`).subscribe((res: any) => {
      if (res && res.length) {
        res.forEach((el) => {
          el.checked = true;
        });
        this.set('tdKbk', res);
      } else {
        this.set('tdKbk', []);
      }
    }, (err) => {
    });
  }
  exportTaxesDetailsKbk() {
    let t = this.model.taxes && this.model.taxes.content;
    let y = t && t.length ? t.filter((r) => r.checked).map((r) => r.year) : [];
    let k = this.model.tdKbk;
    let k2 = k && k.length ? k.filter((r) => r.checked).map((r) => r.kbk) : [];
    let p = t && t.length ? '?years=' + y.join('&years=') : '';
    let p2 = k && k.length ? (p ? '&kbks=' : '?kbks=') + k2.join('&kbks=') : '';
    this.utils.exportDoc(`/tax-details/kbk/${this.model.id}/export${p}${p2}&access_token=` + this.model.token.access_token,
      '.xlsx', `kbk${y.join('-')}_${k2.join('-')}`);
  }

  loadExtendedTaxesDetailsYear(details) {
    let year = details.year;
    return this.http.get(`/tax-details/${this.model.id}?year=${year}`).pipe(map((res: any) => {
      // this.set('taxesLoading', false);
      // this.set('tax-details', res);
      if (this.model.taxes && this.model.taxes.content && this.model.taxes.content.length) {
        this.model.taxes.content.sort((a, b) => {
          return a && b && (a.year < b.year) ? -1 : (a.year > b.year) ? 1 : 0;
        });
        let i = this.model.taxes.content.findIndex((el) => el.year === year);
        let o = this.model.taxes.content.find((el) => el.year === year);
        let obj = res['content'].find(e => e.year === year);
        if (obj && obj.content && obj.content.length) {
          let counts = {};
          o.status = res['status'] === true ? 'YES' : res['status'] === false ? 'NO' : 'OFF';
          obj.content.sort((el1, el2) => el1.kbk && el2.kbk ? el1.kbk - el2.kbk : -1);
          obj.content.forEach((e) => {
            e.summa = parseFloat(e.summa);
            if (e.kbk) {
              let kbkId = 'id' + e.kbk;
              counts[kbkId] = (counts[kbkId] ? counts[kbkId] : 0) + 1;
            }
          });
          obj.content.forEach((e) => {
            if (e.kbk) {
              let kbkId = 'id' + e.kbk;
              e[kbkId] = counts[kbkId];
            }
          });
          o.content = obj.content;
        } else {
          o.status = 'NO';
          o.content = [];
        }
        this.model.taxes.content[i] = o;
        return o;
      }
      return details;
    }));
  }
  loadExtendedBranches() {
    this.set('branchesLoading', true);
    this.set('branchesError', false);
    this.checkReportStatus();
    this.sub.openBranches = this.http.get(`/branches?companyId=${this.model.id}${'&v=' + new Date().getTime()}`).subscribe((res) => {
      if (res && res['branchesStatus'] === 'SYNC') {
        let self = this;
        this.timeout.branchesStatus = setTimeout(function () {
          self.loadExtendedBranches();
        }, 15000);
      } else {
        this.set('branchesLoading', false);
        this.set('branches', res);
        this.checkReportStatus();
      }
    }, (err) => {
      this.set('branchesLoading', false);
      this.set('branchesError', true);
      this.checkReportStatus();
    });
  }
  // loadExtendedVirusActivity() {
  //   let bin: any = this.model.data && this.model.data.bin && this.model.data.bin.length === 12 ? 'biniin=' + this.model.data.bin : '';
  //   let iin: any = this.model.data && this.model.data.iin && this.model.data.iin.length === 12 ? 'biniin=' + this.model.data.iin : '';
  //   this.set('vaLoading', true);
  //   this.set('vaError', false);
  //   this.checkReportStatus();
  //   this.sub.virusActivity = this.http.get(`api2/quarantine_access?identifier=${bin || iin}`).subscribe((res) => {
  //     this.set('vaLoading', false);
  //     this.set('va', res);
  //     this.checkReportStatus();
  //   }, (err) => {
  //     this.set('vaLoading', false);
  //     this.set('vaError', true);
  //     this.checkReportStatus();
  //   });
  // }
  loadExtendedBankruptyRehabilitation() {
    this.set('bankruptyRehabilitationLoading', true);
    this.set('bankruptyRehabilitationError', false);
    this.checkReportStatus();
    this.sub.bankruptyRehabilitation = this.http.get(`../../../assets/mock/rehabNbank.json`).subscribe((res) => {
      this.set('bankruptyRehabilitationLoading', false);
      this.set('bankruptyRehabilitation', res);
      this.checkReportStatus();
    }, (err) => {
      this.set('bankruptyRehabilitationLoading', false);
      this.set('bankruptyRehabilitationError', true);
      this.checkReportStatus();
    });
  }
  loadExtendedSameOwner() {
    let bin: any = this.model.data && this.model.data.bin && this.model.data.bin.length === 12 ? 'biniin=' + this.model.data.bin : '';
    let iin: any = this.model.data && this.model.data.iin && this.model.data.iin.length === 12 ? 'biniin=' + this.model.data.iin : '';
    this.set('sameOwnerLoading', true);
    this.set('sameOwnerError', false);
    this.checkReportStatus();
    this.sub.sameOwner = this.http.get(`api/search/company/same_owner?${bin || iin}`).subscribe((res) => {
      this.set('sameOwnerLoading', false);
      this.set('sameOwner', res);
      this.checkReportStatus();
    }, (err) => {
      this.set('sameOwnerLoading', false);
      this.set('sameOwnerError', true);
      this.checkReportStatus();
    });
  }
  loadExtendedSameLawAddress() {
    let bin: any = this.model.data && this.model.data.bin && this.model.data.bin.length === 12 ? 'biniin=' + this.model.data.bin : '';
    let iin: any = this.model.data && this.model.data.iin && this.model.data.iin.length === 12 ? 'biniin=' + this.model.data.iin : '';
    this.set('sameLawAddressLoading', true);
    this.set('sameLawAddressError', false);
    this.checkReportStatus();
    this.sub.sameLawAddress = this.http.get(`api/search/company/same_law_address?${bin || iin}`).subscribe((res) => {
      this.set('sameLawAddressLoading', false);
      this.set('sameLawAddress', res);
      this.checkReportStatus();
    }, (err) => {
      this.set('sameLawAddressLoading', false);
      this.set('sameLawAddressError', true);
      this.checkReportStatus();
    });
  }
  loadExtendedNds() {
    let bin: any = this.model.data && this.model.data.bin && this.model.data.bin.length === 12 ? 'bin=' + this.model.data.bin : '';
    let iin: any = this.model.data && this.model.data.iin && this.model.data.iin.length === 12 ? 'iin=' + this.model.data.iin : '';
    let rnn: any = this.model.data && this.model.data.rnn && this.model.data.rnn.length === 12 ? 'rnn=' + this.model.data.rnn : '';
    this.set('ndsLoading', true);
    this.set('ndsError', false);
    this.sub.nds = this.http.get(`/company/nds?${bin}${iin}${bin || iin ? '' : rnn}`).subscribe((res: any) => {
      if(res && res.requestStatus === 'SYNC') {
        setTimeout(() => {
          this.loadExtendedNds()
        }, 5000);
        return
      }
      this.set('ndsLoading', false);
      this.set('nds', res);
    }, (err) => {
      this.set('ndsError', true);
      this.set('ndsLoading', false);
    });
  }
  loadExtendedWs() {
    let urlBin: any = this.model.data && this.model.data.bin ? 'bin=' + this.model.data.bin : '';
    let urlIin: any = this.model.data && this.model.data.iin ? 'iin=' + this.model.data.iin : '';
    let urlRnn: any = this.model.data && this.model.data.rnn ? 'rnn=' + this.model.data.rnn : '';
    let obj = {};
    Array.from(Array(30).keys()).forEach(i => {
      obj[`id${i}`] = { status: 'SYNC' };
    });
    this.set('kompra', obj);
    this.ws.subscribe(this.model && this.model.data && this.model.data.bin ? this.model.data.bin :
      this.model.data && this.model.data.iin ? this.model.data.iin : this.model.data && this.model.data.rnn, 'company');
    this.setWsCompany();
    if (this.model.data && !this.model.data.ip && this.model.type !== 'FL' &&
      !this.utils.isFilial(this.model.data && this.model.data.bin) && !this.utils.isNoResident(this.model.data && this.model.data.bin)) {
      this.ws.subscribe(this.model.data && this.model.data.bin ? this.model.data.bin :
        this.model.data && this.model.data.iin ? this.model.data.iin : this.model.data && this.model.data.rnn, 'founder');
      this.setWsFounders();
    }
    this.ws.subscribe(this.model.data && this.model.data.bin ? this.model.data.bin :
      this.model.data && this.model.data.iin ? this.model.data.iin : this.model.data && this.model.data.rnn, 'cases');
    this.setWsCases();
    let self = this;
    this.timeout.kompra = setTimeout(function () {
      self.getKompra(urlBin, urlIin, urlRnn);
    }, 2000);
  }
  getKompra(urlBin, urlIin, urlRnn) {
    this.sub.kompra = this.http.get(`/kompra?${urlBin}${urlIin}${urlBin || urlIin ? '' : urlRnn}${'&v=' + new Date().getTime()}`)
      .subscribe((res) => {
        let kompra = {};
        let data = res && res['content'];
        if (data && Array.isArray(data)) {
          data.forEach((el) => {
            kompra[`id${el.type.id}`] = this.createKompraObj(el);
          });
          this.set('kompra', kompra);
          this.activating(this.model.typeId);
          this.calculateCount(kompra);
          let self = this;
          this.timeout.kompra1 = setTimeout(function () { self.loadExtendedKompra(); }, 15000);
          this.timeout.kompra2 = setTimeout(function () { self.loadExtendedKompra(); }, 30000);
          this.timeout.kompra3 = setTimeout(function () { self.loadExtendedKompra(); }, 45000);
          this.timeout.kompra4 = setTimeout(function () { self.loadExtendedKompra(); }, 60000);
          this.timeout.kompra5 = setTimeout(function () { self.loadExtendedKompra(); }, 90000);
          this.timeout.kompra6 = setTimeout(function () { self.loadExtendedKompra(); }, 120000);
        }
      }, (err) => {
        console.log(err);
      });
  }
  loadExtendedKompra() {
    let urlBin: any = this.model.data && this.model.data.bin ? 'bin=' + this.model.data.bin : '';
    let urlIin: any = this.model.data && this.model.data.iin ? 'iin=' + this.model.data.iin : '';
    let urlRnn: any = this.model.data && this.model.data.rnn ? 'rnn=' + this.model.data.rnn : '';
    this.sub.kompra = this.http.get(`/kompra?${urlBin}${urlIin}${urlBin || urlIin ? '' : urlRnn}${'&=' + new Date().getTime()}`)
      .subscribe((res) => {
        let kompra = {};
        let data = res && res['content'];
        if (data && Array.isArray(data)) {
          data.forEach((el) => {
            kompra[`id${el.type.id}`] = this.createKompraObj(el);
          });
          // this.set('kompra', JSON.parse(JSON.stringify(kompra)));
          this.set('kompra', kompra);
          this.calculateCount(kompra);
          let self = this;
          setTimeout(function () {
            self.activating(self.model.typeId);
          }, 1);
        }
      }, (err) => {
        console.log(err);
      });
  }

  loadExtendedData() {
    let self = this;
    this.counter++;
    this.ws.initStomp();
    // все доступные и сами данные
    // if (this.authService.user.subscribed) {
    this.sub.viewHistory = this.http.post(`/cabinet/viewHistory`, { companyId: this.model.id }).subscribe();
    // }
    if (this.model.type !== 'FL') {
      this.loadExtendedStatus();
      this.loadExtendedTenders(0);
      this.loadExtendedLicenses(0);
      this.loadExtendedContracts(0);
      // this.loadExtendedDfo(0);
      this.loadExtendedStkz(0);
      this.loadExtendedIndust(0);
      this.loadExtendedSmk(0);
      this.loadExtendedEA3C(0);
      this.loadExtendedDekl1(0);
      this.loadExtendedDekl2(0);
      this.loadExtendedOwner();
      this.loadExtendedSanctions();
      this.loadExtendedReviews();
      // this.getSchemeStatus();
      if (this.accessRightService.enabledCompany) {
        this.loadIdn();
      }
      this.loadScoring();
      if (this.model.data && !this.model.data.ip) {
        this.loadExtendedTaxes();
        this.loadExtendedBranches();
        this.loadExtendedBankruptyRehabilitation();
        this.getRating();
        if(this.model.status === 'BOUGHT' && this.accessRightService.enabledNds) {
          this.loadExtendedNds();
        }
      }
      if (this.model.data.opi) {
        this.loadExtendedBoardMembers(0);
        this.loadExtendedShareHolders(0);
        this.loadExtendedAccountants(0);
        this.loadExtendedExecutives(0);
        this.loadExtendedRelations(0);
      }
      this.getQuarantineStatus();
      this.loadExtendedSameLawAddress();
      this.loadExtendedSameOwner();
      // this.loadExtendedArrests();
    }
    this.loadExtendedAffiliations(0);
    if (this.model.data && !this.model.data.ip && this.model.type !== 'FL' &&
      !this.utils.isFilial(this.model.data && this.model.data.bin) && !this.utils.isNoResident(this.model.data && this.model.data.bin) &&
      (this.accessRightService.enabledHistory || this.model.historyStatus)) {
      // Перенесен в сам компонент
      // this.loadCompanyHistory();
    }
    // this.loadExtendedCases(0);
    this.loadExtendedCasesByRequest(0);
    if (this.model.data && !this.model.data.ip && this.model.type !== 'FL' &&
      !this.utils.isFilial(this.model.data && this.model.data.bin) && !this.utils.isNoResident(this.model.data && this.model.data.bin)) {
      this.loadExtendedFounders();
    }
    if (this.model.type === 'FL') {
      this.loadExtendedFlIp();
      this.loadExtendedFlPart();
      this.loadExtendedFlDeath();
      this.loadExtendedBoardMembers(0);
      this.loadExtendedShareHolders(0);
      this.loadExtendedAffiliations(0);
      this.loadExtendedAccountants(0);
      this.loadExtendedExecutives(0);
      this.loadExtendedRelations(0);
      this.loadExtendedLicenses(0);
      this.loadDebtor();
      // this.getSchemeStatus();
      this.getPublicPerson();
    }
    // if (this.authService.enabledScheme || this.model.schemeStatus) {
    //   this.loadExtendedSheme();
    // }
    setTimeout(() => {
      self.loadExtendedWs();
      // if (self.model.type === 'UL' && self.model.data.bin && self.model.data.registerDate) {
      //   this.loadExtendedFines();
      // } else {
      //   this.set('finesError', true);
      // }
    }, 100);
  }

  setOpenData(res: any, type?) {
    this.model$.next(Object.assign({}, res));
    let data = Object.assign({}, res);
    if (data.bin && data.bin[4] == 6) {
      data.ip = true;
    }
    if (!data.ip && data.iin) {
      this.set('type', 'FL');
      this.router.navigate(['/card', data && data.id ? data.id : '', 'report'], { queryParams: { type: 'fl' } });
    }
    if (res && res.registerDate) {
      data.rangeDate = this.utils.timePassed(res.registerDate, 'YM');
    }
    if (this.model.type === 'FL') {
      data.birthDay = this.utils.getBirthDayFromIIN(res.iin);
      data.age = this.utils.timePassed(data.birthDay, 'YEAR');
    }
    data['privatePractice'] = {
      type1: { totalElements: 0 },
      type2: { totalElements: 0 },
      type3: { totalElements: 0 },
      type4: { totalElements: 0 },
      type5: { totalElements: 0 },
      type6: { totalElements: 0 },
      type7: { totalElements: 0 },
      type8: { totalElements: 0 }
    };
    if (data.privatePracticeList && data.privatePracticeList.length) {
      data.privatePracticeList.forEach((el) => {
        data['privatePractice'][`type${el.type.id}`] = el;
        data['privatePractice'][`type${el.type.id}`].totalElements = 1;
      });
    }
    data.preLoader = false;
    if (res && res.statusList && res.statusList.statusList && res.statusList.statusList.length) {
      this.set('statusList', this.orderBy(res.statusList.statusList));
    }
    this.getKeywords(data);
    // if (data.ip && data.iin && this.model.type !== 'FL' && this.authService.authorized && type && type === 'EXTENDED') {
    //   this.loadExtendedRegDate()
    // }
    if (res && res.oked) {
      this.sub.oked = this.getOkedByCode(res.oked).subscribe((obj) => {
        data.oked = obj;
      });
    }
    if (res && res.secondaryOked) {
      let okeds = [];
      if (res && res.secondaryOked) {
        res.secondaryOked.split(',').forEach((el, i) => {
          if (!this.utils.isEmpty(el)) {
            this.sub['secondareOked' + i] = this.getOkedByCode(el).subscribe((obj) => {
              okeds.push(obj);
            });
          }
        });
      }
      data.secondaryOked = okeds;
    }
    if ((new Date().getTime() - res.foundersFetched) > 86400000
      && ['d.kaidarova@bazis.kz'].includes(this.authService.user)
      && type && type === 'EXTENDED') {
      this.updateExtendedFounders();
    }
    this.set('data', data);
    // let i: number = res.statusList.statusList.length;
    // let statusList: any = res.statusList.statusList;
    // let tm: any = new Date().getTime();
    // let tm86: any = new Date().getTime() - 86000000;
    // let liquedSatus = true;
    // while (i--) {
    //   if (statusList[i] && statusList[i].type &&
    //     statusList[i].type.id && statusList[i].type.id === 10) {
    //     if (tm86 < statusList[i].type.lastUpdated < tm) {
    //       liquedSatus = false;
    //       break;
    //     }
    //   }
    // }
  }

  setTitle() {
    if (!this.model.openData.ip && this.model.openData.iin) {
      this.titleService.setTitle(`${this.model.openData.fullName || this.model.openData.name} ИИН ${this.model.openData.iin}`);
      this.metaService.updateTag({
        name: 'description',
        content: `${this.model.openData.fullName || this.model.openData.name} ⭐️ - ИИН ${this.model.openData.iin}`
      });
      this.metaService.updateTag({
        name: 'keywords',
        content: `${this.model.openData.fullName}, ${this.model.openData.name}, ${this.model.openData.iin}`
      });
    } else {
      this.titleService.setTitle(`${this.model.openData.name} - ${this.model.openData.owner
        && this.model.openData.owner.name} БИН ${this.model.openData.bin || this.model.openData.iin}`);
      this.metaService.updateTag({
        name: 'description',
        content: `${this.model.openData.fullName ? this.model.openData.fullName :
          this.model.openData.name} ⭐️ - БИН ${this.model.openData.bin
          || this.model.openData.iin}, Руководитель: ${this.model.openData.owner
          && this.model.openData.owner.name}, ${this.model.openData.lawAddress}`
      });
      this.metaService.updateTag({
        name: 'keywords',
        content: `${this.model.openData.fullName}, ${this.model.openData.name}, ${this.model.openData.bin
          || this.model.openData.iin}, ${this.model.openData.field.join(', ')}, ${this.model.openData.owner
          && this.model.openData.owner.name}, ${this.model.openData.lawAddress}`
      });
    }
  }

  loadExtendedRegDate() {
    let data = Object.assign({}, this.model.data);
    this.set('registerDateLoading', true);
    this.set('registerDateError', false);
    this.sub.registerDate = this.searchService.getRegisterDate(data.iin).subscribe((el: any) => {
      if(el && el.queueStatus === 'OFF') {
        this.set('registerDateOff', true);
      }
      if(el && el.queueStatus === 'SYNC') {
        setTimeout(() => {
          this.loadExtendedRegDate()
        }, 5000);
        return
      }
      this.set('registerDateOff', false);
      this.set('registerDateLoading', false);
      this.set('registerDateError', false);
      this.set('registerDate', true);
      data.registerDate = el.registerDate ? new Date(this.utils.formatDateDMY(el.registerDate)).getTime() : data.registerDate;
      data.removalDate = el.removalDate ? new Date(this.utils.formatDateDMY(el.removalDate)).getTime() : data.removalDate;
      data.stopPeriods = el.stopPeriods ? el.stopPeriods : data.stopPeriods;
      data.name = el.name ? el.name : data.name;
      data.rnn = el.rnn ? el.rnn : data.rnn;
      data.reason = el.reason ? el.reason : data.reason;
      this.set('data', data);
    }, err => {
      this.set('registerDateLoading', false);
      this.set('registerDateError', true);
      this.set('registerDate', true);
    });
  }

  loadExtendedOwner() {
    this.sub.owner = this.http.get(`/company/${this.model.data.id}/owners`).subscribe((res) => {
      this.set('owner', res);
    }, (err) => {
      console.log(err);
    });
  }

  loadExtendedSanctions(page?) {
    let p: string = this.utils.createUrlParams([{
      name: 'size',
      value: this.model.sanctions && this.model.sanctions.size ? this.model.sanctions.size : 10
    }, {
      name: 'page',
      value: page ? page : 0
    }]);
    this.set('sanctionsLoading', true);
    this.set('sanctionsError', false);
    this.sub.sanctions = this.http.get(`/company/${this.model.data.id}/sanctions${p}`).subscribe((res) => {
      res['page'] = page;
      this.set('sanctions', res);
      this.set('sanctionsLoading', false);
    }, (err) => {
      console.log(err);
      this.set('sanctionsLoading', false);
      this.set('sanctionsError', true);
    });
  }

  loadExtendedFines(page?) {
    if (this.sub.fines) {
      this.sub.fines.unsubscribe();
    }
    let p: string = this.utils.createUrlParams([{
      name: 'size',
      value: this.model.fines && this.model.fines.size ? this.model.fines.size : 10
    }, {
      name: 'page',
      value: page ? page : 0
    }]);
    this.set('finesLoading', true);
    this.set('finesError', false);
    this.sub.fines = this.http.get(`/penalty/ul?bin=${this.model.data.bin}&reg_date=${this.utils.formatDate(this.model.data.registerDate)}`).subscribe((res) => {
      this.model.fines = { ...res };
      this.set('fines', this.model.fines);
      this.set('finesLoading', false);
    }, (err) => {
      console.log(err);
      this.set('finesLoading', false);
      this.set('finesError', true);
    });
  }

  loadExtendedBoardMembers(page) {
    this.set('boardMembersLoading', true);
    this.set('boardMembersError', false);
    let p: string = this.utils.createUrlParams([{
      name: 'size',
      value: this.model.boardMembers && this.model.boardMembers.size ? this.model.boardMembers.size : 10
    }, {
      name: 'page',
      value: page ? page : 0
    }]);
    this.sub.boardMembers = this.http.get(`/company/${this.model.data.id}/board-members${p}`).subscribe((res) => {
      res['page'] = page;
      this.set('boardMembersLoading', false);
      this.set('boardMembers', res);
    }, (err) => {
      this.set('boardMembersLoading', false);
      this.set('boardMembersError', true);
    });
  }

  loadExtendedShareHolders(page) {
    this.set('shareholdersLoading', true);
    this.set('shareholdersError', false);
    let arr = [];
    if (this.model.shareholders && !this.utils.isEmpty(this.model.shareholders.name)) {
      arr.push({
        name: 'name',
        value: this.model.shareholders.name
      });
    }
    arr.push({
      name: 'size',
      value: this.model.shareholders && this.model.shareholders.size ? this.model.shareholders.size : 50
    });
    arr.push({
      name: 'page',
      value: page ? page : 0
    });
    let p: string = this.utils.createUrlParams(arr);
    this.sub.shareholders = this.http.get(`/company/${this.model.data.id}/shareholders${p}`).subscribe((res) => {
      res['page'] = page;
      res['name'] = this.model.shareholders && this.model.shareholders.name;
      this.set('shareholdersLoading', false);
      this.set('shareholders', res);
    }, (err) => {
      this.set('shareholdersLoading', false);
      this.set('shareholdersError', true);
    });
  }

  loadExtendedAffiliations(page) {
    this.set('affiliationsLoading', true);
    this.set('affiliationsError', false);
    let arr = [];
    if (this.model.affiliations && !this.utils.isEmpty(this.model.affiliations.name)) {
      arr.push({
        name: 'trgName',
        value: this.model.affiliations.name
      });
    }
    arr.push({
      name: 'size',
      value: this.model.affiliations && this.model.affiliations.size ? this.model.affiliations.size : 50
    });
    arr.push({
      name: 'page',
      value: page ? page : 0
    });
    let p: string = this.utils.createUrlParams(arr);
    this.sub.affiliations = this.http.get(`/company/${this.model.data.id}/affiliations${p}`).subscribe((res: any) => {
      res['page'] = page;
      res['name'] = this.model.affiliations && this.model.affiliations.name;
      this.set('affiliationsLoading', false);
      this.set('affiliations', res);
      const sortedByDate: any[] = res && res.content.sort((a, b) => b.reportDate - a.reportDate) || []
      if(sortedByDate.length) {
        this.set('affiliationsDate', sortedByDate[0].reportDate);
      }
    }, (err) => {
      this.set('affiliationsLoading', false);
      this.set('affiliationsError', true);
    });
  }

  loadExtendedArrests() {
    this.set('arrestsLoading', true);
    this.set('arrestsError', false);
    this.sub.arrests = this.http.get(`/arrests?identifier=${this.model.data.bin || this.model.data.iin}`).subscribe((res) => {
      this.set('arrestsLoading', false);
      this.set('arrests', res);
    }, (err) => {
      this.set('arrestsLoading', false);
      this.set('arrestsError', true);
    });
  }

  loadExtendedAccountants(page) {
    this.set('accountantsLoading', true);
    this.set('accountantsError', false);
    let p: string = this.utils.createUrlParams([{
      name: 'size',
      value: this.model.accountants && this.model.accountants.size ? this.model.accountants.size : 10
    }, {
      name: 'page',
      value: page ? page : 0
    }]);
    this.sub.accountants = this.http.get(`/company/${this.model.data.id}/accountants${p}`).subscribe((res) => {
      res['page'] = page;
      this.set('accountantsLoading', false);
      this.set('accountants', res);
    }, (err) => {
      this.set('accountantsLoading', false);
      this.set('accountantsError', true);
    });
  }

  loadExtendedExecutives(page) {
    this.set('executivesLoading', true);
    this.set('executivesError', false);
    let p: string = this.utils.createUrlParams([{
      name: 'size',
      value: this.model.executives && this.model.executives.size ? this.model.executives.size : 10
    }, {
      name: 'page',
      value: page ? page : 0
    }]);
    this.sub.executives = this.http.get(`/company/${this.model.data.id}/executives${p}`).subscribe((res) => {
      res['page'] = page;
      this.set('executivesLoading', false);
      this.set('executives', res);
    }, (err) => {
      this.set('executivesLoading', false);
      this.set('executivesError', true);
    });
  }

  loadExtendedRelations(page) {
    this.set('relationsLoading', true);
    this.set('relationsError', false);
    let arr = [];
    if (this.model.relations && !this.utils.isEmpty(this.model.relations.name)) {
      arr.push({
        name: 'trgName',
        value: this.model.relations.name
      });
    }
    arr.push({
      name: 'size',
      value: this.model.relations && this.model.relations.size ? this.model.relations.size : 50
    });
    arr.push({
      name: 'page',
      value: page ? page : 0
    });
    let p: string = this.utils.createUrlParams(arr);
    this.sub.relations = this.http.get(`/company/${this.model.data.id}/relations${p}`).subscribe((res) => {
      res['page'] = page;
      res['name'] = this.model.relations && this.model.relations.name;
      this.set('relationsLoading', false);
      this.set('relations', res);
    }, (err) => {
      this.set('relationsLoading', false);
      this.set('relationsError', true);
    });
  }

  loadSmi() {
    this.set('smiLoading', true);
    this.set('smiError', false);
    return this.http.get(`/company/media/${this.model.id}`)
  }

  loadSmiSearchResults() {
    this.set('searchResultLoading', true);
    this.set('searchResultError', false);
    return this.http.get(`/api/get_data/by_orgname/${this.model.id}`)
  }

  loadVacancies() {
    this.set('vacanciesLoading', true);
    this.set('vacanciesError', false);
    return this.http.get(`/api/get_data/vacancies/${this.model.id}`)
  }
  
  loadDebtor() {
    this.set('debtorLoading', true);
    this.set('debtorError', false);
    this.sub.debtor = this.http.get(`/api/debtor-service?iin=${this.model.data.iin}`)
      .subscribe(
        res => {
          this.set('debtorError', false);
          this.set('debtorLoading', false);
          this.set('debtor', res);
        },
        err => {
          this.set('debtorError', true);
          this.set('debtorLoading', false);
          console.error(err)
        }
      )
  }
  
  /* Отзывы */
  loadExtendedReviews(page?, size?, timeRange?: 'month' | 'year', dateFrom?, dateTo?, order?: 'asc' | 'desc') {
    this.set('reviewsLoading', true);
    let r = this.get('reviews') || {};
    let arr = [];
    r.page = page || 0;
    r.size = size || 20;
    arr.push({ name: 'companyId', value: this.model.data.id });
    arr.push({ name: 'page', value: r.page });
    arr.push({ name: 'size', value: r.size });
    if (timeRange) { r.timeRange = timeRange || null; arr.push({ name: 'timeRange', value: timeRange }); }
    if (dateFrom) { r.dateFrom = dateFrom || null; arr.push({ name: 'dateFrom', value: dateFrom }); }
    if (dateTo) { r.dateTo = dateTo || null; arr.push({ name: 'dateTo', value: dateTo }); }
    if (order) { r.order = order || 'asc'; arr.push({ name: 'order', value: order }); }
    let p = this.utils.createUrlParams(arr);
    this.sub.getReviews = this.http.get(`/cabinet/reviews${p}`).subscribe((res) => {
      this.set('reviews', { ...r, ...res });
      this.set('reviewsLoading', false);
    }, (err) => {
      this.set('reviewsLoading', false);
      this.set('reviewsError', true);
    });
  }

  saveReviews(reviewBody, grade) {
    if (reviewBody && reviewBody.length > 5000) {
      this.toast.pop('error', 'Не более 5000 символов');
      return false;
    }
    this.sub.getReviews = this.http.post(`/cabinet/reviews`, {
      companyId: this.model.data.id,
      grade: grade,
      reviewBody: reviewBody,
      country: "kz",
      companyName: this.model.data.fullName || this.model.data.name,
      iin: this.model.data.iin,
      bin: this.model.data.bin,
      ip: this.model.data.ip
    }).subscribe((res) => {
      this.loadExtendedReviews();
      this.model.reviews.reviewVisibility = false;
      this.toast.pop('success', 'Отправлено на модерацию');
    }, (err) => {
    });
  }

  /* Отзывы */

  getDFO(id, page, type, name) {
    let params = [{
      name: 'size',
      value: 20
    }, {
      name: 'page',
      value: page ? page : 0
    }];
    if (name) {
      params.push({
        name: type === 'shareholders' ? 'name' : 'trgName',
        value: name
      });
    }
    let p: string = this.utils.createUrlParams(params);
    return this.http.get(`/company/${id}/${type}${p}`).pipe(map((res) => {
      return res;
    }));
  }

  getOked(full?, child?) {
    let f = full ? '?full=true' : '';
    let c = child ? '?parent_id=' + child : '';
    return this.http.get(`/api2/oked${f || c}`).pipe(map((res: Array<any>) => {
      return res;
    }));
  }

  getOkedByCode(code) {
    return this.http.get(`/api2/oked?oked_id=${code}`).pipe(map((res: Array<any>) => {
      return res;
    }));
  }

  setWsCompany() {
    this.sub.wsCompany = this.ws.getData('company').subscribe((res) => {
      // let kompra: any = JSON.parse(JSON.stringify(this.model.kompra ? this.model.kompra : {}));
      let kompra: any = this.model.kompra ? this.model.kompra : {};
      kompra[`id${res.type.id}`] = this.createKompraObj(res);
      // if (res.type.id === 14) {
      //   kompra[`id14`] = {object: this.getMock()};
      // }
      // this.set('kompra', JSON.parse(JSON.stringify(kompra)));
      this.set('kompra', kompra);
      this.calculateCount(kompra);
    });
  }

  updateKompra(typeId) {
    let urlBin: any = this.model.data && this.model.data.bin ? 'bin=' + this.model.data.bin : '';
    let urlIin: any = this.model.data && this.model.data.iin ? 'iin=' + this.model.data.iin : '';
    let urlRnn: any = this.model.data && this.model.data.rnn ? 'rnn=' + this.model.data.rnn : '';
    this.model.kompra[`id${typeId}`].status = 'SYNC';
    this.sub.kompra = this.http.get(`/kompra/update?${urlBin}${urlIin}${urlBin || urlIin ? '' : urlRnn}&typeId=${typeId}`)
      .subscribe((res: any) => {
        let kompra = Object.assign({}, this.model.kompra);
        kompra[`id${res.type.id}`] = this.createKompraObj(res);
        kompra[`id${this.model.typeId}`].active = true;
        this.set('kompra', kompra);
        let self = this;
        setTimeout(function () {
          self.activating(self.model.typeId);
        }, 100);
        this.calculateCount(kompra);
      }, (err) => {
        console.log(err);
      });
  }

  setWsFounders() {
    this.sub.wsFounder = this.ws.getData('founder').subscribe((res) => {
      this.set('foundersLoading', false);
      this.set('foundersError', false);
      let data = Object.assign({}, this.model.data);
      if (res && res['owner'] && res['owner'].appointmentDate) {
        data.owner.appointmentDate = res['owner'].appointmentDate;
        this.set('data', data);
      }
      if (res && res['lawAddress']) {
        data.lawAddress = res['lawAddress'];
        this.set('data', data);
      }
      if (res && Array.isArray(res)) {
        data.founderList = res;
      }
      if (res && res['foundersNum']) {
        data.foundersNum = res && res['foundersNum'];
      }
      this.set('data', data);
      this.founders$.next(data);
      this.loadOpenById('EXTENDED');
    });
  }

  // Перенесен в сам компонент
  loadCompanyHistory() {
    clearTimeout(this.timeout.history);
    this.set('historyLoading', true);
    this.set('historyError', false);
    let self = this;
    this.sub.history = this.http.get(`/company-history?identifier=${this.model.data && this.model.data.bin
      || this.model.data && this.model.data.iin || this.model.data && this.model.data.rnn}`).subscribe((res) => {
        if (res && res['companyHistoryChanges'] && res['companyHistoryChanges'].length || res['status'] === 'YES') {
          let obj = res;
          obj['companyHistoryChanges'].forEach((el) => el.date = new Date(this.utils.formatDateYMDRec(el.date)).getTime());
          obj['companyHistoryChanges'].sort((a, b) => a.date - b.date);
          this.set('historyLoading', false);
          this.set('history', obj);
          if (res && res['companyHistoryChanges'] && res['companyHistoryChanges'].length &&
            res['companyHistoryChanges'].some((el) => el.status === 'SYNC')) {
            this.timeout.history = setTimeout(function () {
              self.loadCompanyHistory();
            }, 15000);
          }
        } else {
          this.timeout.history = setTimeout(function () {
            self.loadCompanyHistory();
          }, 15000);
        }
      }, (err) => {
        this.set('historyLoading', false);
        if (err && err.error['message'] === 'reached-limit') {
          this.toast.pop('error', 'Количество проверок исчерпано!');
          this.set('v403', true);
        } else if (err && err.error['message'] === 'requested-path-not-allowed') {
          this.toast.pop('error', 'Отсутствует право доступа!');
          this.set('v403', true);
        }
      });
  }

  getFoundedHistory(identifier: number | string) {
    this.set('foundedHistoryLoading', true)
    this.sub.foundedHistory = this.http
      .get('/web-api-gateway/fl_check/history?identifier=' + identifier)
      .subscribe(
        (res: any) => {
          if(res && res.foundedEdoc === 'Ошибка') {
            this.set('foundedHistoryEdocError', true)
          }
          this.set('foundedHistory', res)
          this.set('foundedHistoryLoading', false)
          this.set('foundedHistoryLoaded', true)
        },
        err => {
          this.set('foundedHistoryLoading', false)
          console.error(err)
        }
      )
  }

  loadExtendedSheme() {
    this.sub.scheme = this.http.get(`/node?id=${this.model.data.id}&depth=${2}`).subscribe((res) => res);
  }

  // loadCompanyHistoryDetails(type, date) {
  //   this.set('historyDetailsLoading', true);
  //   this.set('historyDetailsError', false);
  //   this.sub.historyDetails = this.http.get(`/company-history/detail?identifier=${this.model.data && this.model.data.bin ||
  //     this.model.data && this.model.data.iin}&type=${type}&date=${encodeURIComponent(date)}`).subscribe((res) => {
  //     this.set('historyDetailsLoading', false);
  //     this.set('historyDetails', res);
  //   }, (err) => {
  //     this.set('historyDetailsLoading', false);
  //   });
  // }

  setWsCases() {
    this.sub.wsCases = this.ws.getData('cases').subscribe((res) => {
      this.loadExtendedCasesByRequest(0);
    });
  }

  getKeywords(company: any) {
    let keywords = this.model.keywords ? this.model.keywords : [];
    if (company && company.name && keywords.indexOf(company.name.split(',')[0]) === -1) {
      keywords.push(company.name.split(',')[0]);
    }
    if (company && company.rnn && keywords.indexOf(company.rnn) === -1) {
      keywords.push(company.rnn);
    }
    if (company && company.bin && keywords.indexOf(company.bin) === -1) {
      keywords.push(company.bin);
    }
    if (company && company.iin && keywords.indexOf(company.iin) === -1) {
      keywords.push(company.iin);
    }
    if (company && company.owner && company.owner.name) {
      company.owner.name.split(' ').forEach((el) => {
        if (keywords.indexOf(el) === -1) {
          keywords.push(el);
        }
      });
    }
    this.set('keywords', keywords);
  }

  isActing(arr: any) {
    let status = true;
    if (arr && arr.length > 0) {
      let i: number = arr.length;
      while (i--) {
        /*
          2, ‘Снято с учета’
          3, 'Признано лжепредприятием'
          5, 'Признано банкротом'
          6, 'Признано бездействующим'
          7, ‘Регистрация признана недействительной’
          11, ‘Ликвидирован’
        */
        if (arr[i].type && arr[i].type.id && [2, 3, 5, 6, 7, 11].indexOf(arr[i].type.id) > -1) {
          status = false;
          break;
        }
        /*
          10. ‘На стадии ликвидации’
        */
        if (arr[i].type && arr[i].type.id && arr[i].type.id === 10 && arr[i].type === 'true') {
          status = false;
          break;
        }
        if (arr[i].type && arr[i].type.id && arr[i].type.id === 19 && arr[i].type === 'true') {
          status = false;
          break;
        }
      }
    }
    return status;
  }

  public orderBy(res: any) {
    let arr: any = [];
    res.forEach((el: any, i: number) => {
      arr.push(this.mapID(el));
    });
    return arr;
  }

  public mapID(el: any) {
    let obj: any = el;
    if (el && el.type) {
      if (el.type.id === 1) {
        obj.orderId = 1;
      }
      if (el.type.id === 2) {
        obj.orderId = 2;
      }
      if (el.type.id === 3) {
        obj.orderId = 7;
      }
      if (el.type.id === 4) {
        obj.orderId = 8;
      }
      if (el.type.id === 5) {
        obj.orderId = 6;
      }
      if (el.type.id === 6) {
        obj.orderId = 3;
      }
      if (el.type.id === 7) {
        obj.orderId = 4;
      }
      if (el.type.id === 8) {
        obj.orderId = 9;
      }
      if (el.type.id === 9) {
        obj.orderId = 10;
      }
      if (el.type.id === 10) {
        obj.orderId = 5;
      }
      if (el.type.id === 18) {
        obj.orderId = 11;
      }
      if (el.type.id === 19) {
        obj.orderId = 12;
      }
      if (el.type.id === 20) {
        obj.orderId = 13;
      }
      if (el.type.id === 11) {
        obj.orderId = 14;
      }
      if (el.type.id === 12) {
        obj.orderId = 15;
      }
    }
    return obj;
  }

  activating(id: number) {
    // let kompra: any = JSON.parse(JSON.stringify(this.model.kompra ? this.model.kompra : {}));
    let kompra: any = this.model.kompra ? this.model.kompra : {};
    if (kompra && kompra.id1) {
      for (let k in kompra) {
        if (k === `id${id}`) {
          kompra[k].active = true;
          // kompra.lastUpdated = kompra[k].lastUpdated;
        } else {
          kompra[k].active = false;
        }
      }
      // this.set('kompra', JSON.parse(JSON.stringify(kompra)));
      this.set('kompra', kompra);
    }
  }

  createKompraObj(res: any) {
    let obj: any = {};
    if (res.type.id === 1 || res.type.id === 2 || res.type.id === 9 || res.type.id === 15 || res.type.id === 17 || res.type.id === 23) {
      obj.allSum = 0;
    }
    obj.id = res.id;
    obj.created = res.created;
    obj.lastUpdated = res.lastUpdated;
    obj.type = res.type;
    obj.bin = res.bin;
    obj.iin = res.iin;
    obj.status = res.status;
    obj.source = res.source;
    if (res.type.id === 13 || res.type.id === 8 || res.type.id === 12 || res.type.id === 11 ||
      res.type.id === 22 || res.type.id === 18 || res.type.id === 21 || res.type.id === 20 ||
      res.type.id === 24 || res.type.id === 25) {
      obj.content = [];
      if (res.content && Array.isArray(res.content) && res.content.length > 0) {
        res.content.forEach((el: any, i: number) => {
          obj.content[i] = el;
          if (res.type.id === 32 || res.type.id === 33 || res.type.id === 34 || res.type.id === 35 ||
            res.type.id === 36 || res.type.id === 37) {
            obj.content[i].content = JSON.parse(el.content);
          }
          // if (res.type.id === 11 || res.type.id === 20) {
          //   if (el && el.content && Array.isArray(el.content) && el.content.length > 0 && el.content[0].img_url) {
          //     obj.content[i].imgSrc = this.sanitizer.bypassSecurityTrustUrl(el.content[0].img_url);
          //   }
          // } else {
          if (el && el.content && Array.isArray(el.content) && el.content.length > 0 && el.content[0].img) {
            obj.content[i].imgSrc = this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64,' +
              el.content[0].img.replace('data:image/jpg;base64,', ''));
          }
          // }
        });
      } else {
        obj.content = res.content;
      }
    } else {
      obj.content = res.content;
    }
    if (res.type.id === 32 || res.type.id === 33 || res.type.id === 34
      || res.type.id === 35 || res.type.id === 36 || res.type.id === 37) {
      obj.content = res.content;
      if (res.content && res.content.content) {
        obj.content['content'] = res.content.content;
      }
    }
    if (res.content && Array.isArray(res.content) && res.content.length > 0) {
      obj.groupContent = this.groupContent(res.content, res.type.id);
      obj.count = res.content.length;
      if (res.type.id === 1 && obj.content && Array.isArray(obj.content) && obj.content.length > 0) {
        obj.content.forEach((el: any, i: number) => {
          obj.allSum += el.sum;
        });
      }
      if (res.type.id === 2 && obj.content && Array.isArray(obj.content) && obj.content.length > 0) {
        obj.content.forEach((el: any, i: number) => {
          obj.allSum += el.sum;
        });
      }
      if (res.type.id === 9 && res.content && Array.isArray(res.content) && res.content.length > 0) {
        res.content.forEach((el: any, i: number) => {
          if (el && Array.isArray(el) && el.length > 0) {
            el.forEach((val: any, ind: number) => {
              obj.allSum += val.sum;
            });
          } else {
            obj.allSum += el.sum;
          }
        });
      }
      if (res.type.id === 15 && res.content && Array.isArray(res.content) && res.content.length > 0) {
        res.content.forEach((el: any, i: number) => {
          if (el && Array.isArray(el) && el.length > 0) {
            el.forEach((val: any, ind: number) => {
              obj.allSum += val.sum;
            });
          } else {
            obj.allSum += el.sum;
          }
        });
      }
      if (res.type.id === 17 && res.content && Array.isArray(res.content) && res.content.length > 0) {
        res.content.forEach((el: any, i: number) => {
          if (el && Array.isArray(el) && el.length > 0) {
            el.forEach((val: any, ind: number) => {
              obj.allSum += val.sum;
            });
          }
        });
      }
      if (res.type.id === 23 && res.content && Array.isArray(res.content) && res.content.length > 0) {
        res.content.forEach((el: any, i: number) => {
          if (el && Array.isArray(el) && el.length > 0) {
            el.forEach((val: any, ind: number) => {
              obj.allSum += val.sum;
            });
          }
        });
      }
    }

    return obj;
  }

  groupContent(item: any, typeId: number) {
    let obj: any = {};
    if (typeId === 9 || typeId === 17 || typeId === 23) {
      obj = [];

      if (item && Array.isArray(item) && item.length > 0) {
        item.forEach((el: any, i: number) => {
          let person: any = {};
          person.allSum = 0;
          if (el && Array.isArray(el) && el.length > 0) {
            person.content = el;
            el.forEach((val: any, ind: number) => {
              person.allSum += val.sum;
              person.name = val.debtor;
            });
          } else {
            person.content = [el];
            person.allSum += el.sum;
            person.name = el.debtor;
          }
          obj.push(person);
        });
      }
    } else {
      if (item && Array.isArray(item) && item.length > 0) {
        item.forEach((el: any, i: number) => {
          let elem: any = Object.assign({}, el);
          if (typeId === 1) {
            elem.date = el.banStartDate;
          }
          if (typeId === 5) {
            elem.date = el.court_decision_date;
          }
          if (typeId === 6) {
            elem.date = el.inclusion_date;
          }
          if (typeId === 16) {
            elem.date = el.act_pub_dt;
          }
          let date: any = new Date();
          if (typeId === 1 || typeId === 2 || typeId === 5 || typeId === 6 || typeId === 16) {
            if (typeId === 1) {
              date = new Date(el.banStartDate);
            }
            if (typeId === 2) {
              date = new Date(el.ipStartDate);
            }
            if (typeId === 5) {
              date = new Date(el.court_decision_date);
            }
            if (typeId === 6) {
              date = new Date(el.inclusion_date);
            }
            if (typeId === 16) {
              date = new Date(el.act_pub_dt);
            }
          } else {
            date = new Date(el.date);
          }
          let year: any = date.getFullYear();
          if (obj.hasOwnProperty(`y${year}`)) {
            obj[`y${year}`][obj[`y${year}`].length] = elem;
          } else {
            obj[`y${year}`] = [];
            obj[`y${year}`][0] = elem;
          }
        });
      }
    }
    return obj;
  }

  calculateCount(data: any) {
    const categories = {
      activity: ['id1', 'id2', 'id3', 'id14', 'id16', 'id28', 'id39'],
      bankrupt: ['id32', 'id33', 'id34', 'id35', 'id36', 'id37', 'id4'],
      purchases: ['id5', 'id6', 'id38', 'id40', 'id41', 'id45'],
      arests: ['id42', 'id43', 'id44'],
      director: ['id8', 'id9', 'id10', 'id11', 'id12', 'id13', 'id15', 'id24', 'id26'],
      fl: ['id8', 'id9', 'id10', 'id11', 'id12', 'id13', 'id15', 'id24', 'id26', 'id28'],
      founders: ['id17', 'id18', 'id19', 'id20', 'id21', 'id22', 'id23', 'id25', 'id27']
    };
  
    const keyToCategory: { [key: string]: string } = {};
    for (const [category, keys] of Object.entries(categories)) {
      for (const key of keys) {
        keyToCategory[key] = category;
      }
    }
  
    const counts = {
      all: 0,
      activity: 0,
      arests: 0,
      purchases: 0,
      director: 0,
      founders: 0,
      bankrupt: 0,
      yes: 0, no: 0, off: 0, sync: 0,
      activityYes: 0, activityNo: 0, activityOff: 0, activitySync: 0,
      bankruptYes: 0, bankruptNo: 0, bankruptOff: 0, bankruptSync: 0,
      purchasesYes: 0, purchasesNo: 0, purchasesOff: 0, purchasesSync: 0,
      arestsYes: 0, arestsNo: 0, arestsOff: 0, arestsSync: 0,
      directorYes: 0, directorNo: 0, directorOff: 0, directorSync: 0,
      foundersYes: 0, foundersNo: 0, foundersOff: 0, foundersSync: 0,
      allFl: 0, flYes: 0, flNo: 0, flSync: 0, flOff: 0
    };
  
    for (const [key, value] of Object.entries(data)) {
      const category = keyToCategory[key];
      if (!category) {
        continue;
      }
  
      counts.all++;

      if (category !== 'fl') {
        counts[category]++;
      }
      if (category === 'fl') {
        counts.allFl++;
      }
  
      const status = (value as any).status;
      if (status === 'YES') {
        counts.yes++;
        counts[`${category}Yes`]++;
      } else if (status === 'NO') {
        counts.no++;
        counts[`${category}No`]++;
      } else if (status === 'SYNC' || status === 'INIT') {
        counts.sync++;
        counts[`${category}Sync`]++;
      } else if (status === 'OFF') {
        counts.off++;
        counts[`${category}Off`]++;
      }
    }
  
    if(this.model.debtor) {
      counts.allFl++
      if(this.model.debtor.status === 'NO') {
        counts.flNo++
      }
      if(this.model.debtor.status === 'YES') {
        counts.flYes++
      }
    }
    
    this.set('kompraCount', counts)
  }

  getRating() {
    let interval
    if(this.model.data && !this.model.data.id && !this.model.id){
      interval = setInterval(() => {
        this.getRating()
      }, 1000);
    } 
    if(this.model.id || this.model.data && this.model.data.id) {
      if(interval) {
        clearInterval(interval)
      }
      this.set('okedLoading', true);
      this.set('okedRating', null);
      this.http.get(`/open-company/${this.model.id || this.model.data.id}/okedRating`).subscribe((res) => {
        this.set('okedLoading', false);
        this.set('okedRating', res);
      });
    }
  }
}
