import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { EdsService } from './eds.service';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'app-eds',
  templateUrl: './eds.component.html'
})
export class EdsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public xmldata: string;
  @Input() public isDisabled: boolean;
  @Input() public needUser: boolean;
  @Input() public userAgreementXml: string;
  @Input() public btnLabel: string;
  @Input() public keytype: 'SIGN' | 'AUTH' | 'ALL';
  @Input() public signBtnLabel: string;
  @Output() public signXml: any = new EventEmitter();
  @Output() public setUser: any = new EventEmitter();
  public certChoosing = false;
  public signInfo;
  public model = this.initModel();
  signedXml = {
    userAgreementXml: undefined,
    userXml: undefined
  }

  initModel() {
    return {
      storageAlias: 'PKCS12', /*Тип хранилища
        Ваш Компьютер: PKCS12,
        Казтокен: AKKaztokenStore,
        Личное Удостоверение: AKKZIDCardStore,
        EToken Java 72k: AKEToken72KStore,
        AK JaCarta: AKJaCartaStore*/
      fileExtension: 'P12',
      storagePath: undefined, /*Путь хранилища*/
      password: undefined,
      alias: undefined,
      startDate: undefined,
      endDate: undefined,
      profile: undefined,
      user: {
        firstname: undefined,
        secondname: undefined,
        middlename: undefined,
        iin: undefined,
        bin: undefined,
        country: undefined,
        region: undefined,
        city: undefined,
        organizationName: undefined,
        email: undefined,
      },
      oid: {
        commonName: undefined,
        surname: undefined,
        iin: undefined,
        country: undefined,
        locale: undefined,
        region: undefined,
        organizationName: undefined,
        bin: undefined,
        email: undefined,
        componentDomain: undefined,
        businessCategory: undefined,
      },
      oidParams: {
        commonName: '2.5.4.3', /*Общепринятое имя(CN)*/
        surname: '2.5.4.4', /*Фамилия(SURNAME)*/
        iin: '2.5.4.5', /*ИИН(SERIALNUMBER)*/
        country: '2.5.4.6', /*Страна(C)*/
        locale: '2.5.4.7', /*Локация(L)*/
        region: '2.5.4.8', /*Область(S)*/
        organizationName: '2.5.4.10', /*Название организации(O)*/
        bin: '2.5.4.11', /*БИН(OU)*/
        email: '1.2.840.113549.1.9.1', /*Адрес электронной почты(E)*/
        componentDomain: '0.9.2342.19200300.100.1.25', /*Компонент домена(DC)*/
        businessCategory: '2.5.4.15', /*Бизнес категория(BC))*/
      },
      signXml: undefined,
      lang: 'kk',
      errorCode: undefined
    }
  }

  constructor(
    private edsService: EdsService) {
    this.signInfo = false;
    this.needUser = false;
  }

  ngOnInit() {
    this.edsService.webSocketInit();

    this.edsService.getData().subscribe((res) => {
      this.model.errorCode = undefined;
      this.certChoosing = false;
      this.signInfo = false;
      if (res.errorCode === '200') {
        if (res.method === 'getKeyInfo') {
          if (res.result.certNotAfter >= new Date().getTime()) {
            this.model.alias = res.result.alias;
            this.model.startDate = res.result.certNotBefore;
            this.model.endDate = res.result.certNotAfter;
            this.model.profile = res.result.subjectDn;
            this.model.user = this.parseProfile(res.result.subjectDn);
            this.setUser.emit(this.model.user);
          } else {
            this.model.errorCode = `Истек срок действия сертификата: ${this.formatDate(res.result.certNotBefore)} - ${this.formatDate(res.result.certNotAfter)}`
          }
        }
        if (res.method === 'signXml') {
          this.model.signXml = res.result;
          this.sendSignXml(res.result);
        }
      } else {
        this.model.errorCode = res.errorCode + ' попробуйте еще раз.';
        if (res.errorCode === 'EMPTY_KEY_LIST') {
          this.model.storagePath = undefined;
          this.model.password = undefined;
          if (this.keytype === 'AUTH') {
            this.model.errorCode = `Выберите сертификат для авторизации (с префиксом AUTH)`;
          }
          if (this.keytype === 'SIGN') {
            this.model.errorCode = 'Выберите сертификат для подписи (с префиксом RSA или GOST)';
          }
        } else if (res.errorCode === 'WRONG_PASSWORD' && res.result > -1) {
          this.model.errorCode = 'Неправильный пароль! Количество оставшихся попыток: ' + res.result;
        } else if (res.errorCode === 'WRONG_PASSWORD') {
          this.model.errorCode = 'Неправильный пароль!';
        } else {
          this.model.errorCode = res.errorCode;
        }

        /*
        TODO сделать проверку
        {
            "VALID_SIGN": "Подпись верна",
            "CORRUPTED_CERT": "Сертификат поврежден",
            "CORRUPTED_XML": "XML повреждена",
            "CERTIFICATE_EXPIRED": "Сертификат истек",
            "CERTIFICATE_NOT_YET_VALID": "Сертификат ещё не вступил в силу",
            "CERTIFICATE_CHAIN_INVALID": "Неверная цепочка сертификатов",
            "CERTIFICATE_REVOKED": "Сертификат отозван",
            "BAD_SIGNATURE": "Подпись не верна"
        }
        */
      }
    });
  }

  ngOnChanges() {
    if (this.xmldata) {
      this.sign();
    }
  }

  choiceCert() {
    this.certChoosing = true;
    this.edsService.getKeyInfo(this.model.storageAlias);
  }

  getXml() {
    this.setUser.emit({
      firstname: 'undefined',
      secondname: 'undefined',
      middlename: 'undefined',
      iin: 'undefined',
      bin: 'undefined',
      country: 'undefined',
      region: 'undefined',
      city: 'undefined',
      organizationName: 'undefined',
      email: 'undefined',
    });
  }

  sign() {
    this.edsService.signXml(this.model.storageAlias, 'SIGNATURE', this.xmldata);
  }

  sendSignXml(xml) {
    this.signXml.emit(xml);
  }

  choiceOtherSertificate = () => {
    this.model = this.initModel();
    this.choiceCert();
    this.setUser.emit(null);
  }

  parseProfile = (profile) => {
    const user = {
      firstname: undefined,
      secondname: undefined,
      middlename: undefined,
      iin: undefined,
      bin: undefined,
      country: undefined,
      region: undefined,
      city: undefined,
      organizationName: undefined,
      email: undefined,
    };
    const arr = profile.split(',');
    arr.forEach((el) => {
      if (el.indexOf('CN=') > -1) {
        user.firstname = el.split('=')[1].split(' ')[1];
      }
      if (el.indexOf('SURNAME=') > -1) {
        user.secondname = el.split('=')[1];
      }
      if (el.indexOf('G=') > -1) {
        user.middlename = el.split('=')[1];
      }
      if (el.indexOf('SERIALNUMBER=') > -1) {
        user.iin = el.split('=')[1].replace('IIN', '');
      }
      if (el.indexOf('OU=') > -1) {
        user.bin = el.split('=')[1].replace('BIN', '');
      }
      if (el.indexOf('C=') > -1) {
        user.country = el.split('=')[1];
      }
      if (el.indexOf('L=') > -1) {
        user.region = el.split('=')[1];
      }
      if (el.indexOf('S=') > -1) {
        user.city = el.split('=')[1];
      }
      if (el.indexOf('O=') > -1) {
        user.organizationName = el.split('=')[1];
      }
      if (el.indexOf('E=') > -1) {
        user.email = el.split('=')[1];
      }
    });
    return user;
  }

  getEndDate = (str, format: 'date' | 'timestamp') => {
    let arr = str.split('|');
    const date = arr[arr.length - 1];
    arr = date.split(/[\s\.:]+/);
    const endDate = new Date();
    endDate.setDate(arr[0]);
    endDate.setMonth(arr[1] - 1);
    endDate.setFullYear(arr[2]);
    endDate.setHours(arr[3]);
    endDate.setMinutes(arr[4]);
    endDate.setSeconds(0);
    return format === 'date' ? endDate : endDate.getTime();
  }

  formatDate(value: any) {
    let d: any = new Date(parseInt(value, 10));
    return `${this.putZero(d.getDate())}-${this.putZero(d.getMonth() + 1)}-${d.getFullYear()}`;
  }

  putZero(n: number) {
    return n.toString().length > 1 ? n : `0${n}`;
  }

  ngOnDestroy() {
    this.edsService.webSocketOnClose();
  }
}
