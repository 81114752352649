import { Directive, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { Subject } from 'rxjs/Subject';

@Directive({
  selector: '[ngModel][appDebounce]',
})
export class DebounceDirective implements OnInit {
  @Output() debounced = new EventEmitter<any>();

  @Input() appDebounce: number;
  public debounceTime = 2000;

  private isFirstChange = true;

  constructor(public model: NgControl) {
  }

  public ngOnInit() {
    this.model.valueChanges
      .debounceTime(this.appDebounce ? this.appDebounce : this.debounceTime)
      .distinctUntilChanged()
      .subscribe((modelValue) => {
        if (this.isFirstChange) {
          this.isFirstChange = false;
        } else {
          this.debounced.emit(modelValue ? String(modelValue).trim() : modelValue);
        }
      });
  }
}
