import {
    Component,
    ViewEncapsulation
} from '@angular/core';

@Component({
    selector: 'icon-google',
    encapsulation: ViewEncapsulation.None,
    template: `<svg class="icon__svg" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 1.25C5.27849 1.25 2.25 4.27849 2.25 8C2.25 11.7215 5.27849 14.75 9 14.75C12.7215 14.75 15.75 11.7215 15.75 8V7.11905H9.5V8.88095H13.2707C13.4307 8.88095 13.581 8.95751 13.6751 9.0869C13.7692 9.2163 13.7957 9.3829 13.7464 9.5351C13.0979 11.536 11.2167 12.9881 9 12.9881C6.24912 12.9881 4.0119 10.7509 4.0119 8C4.0119 5.24912 6.24912 3.0119 9 3.0119C9.94107 3.0119 10.8414 3.26905 11.6283 3.7591L12.7071 2.35772C11.6066 1.63113 10.3363 1.25 9 1.25ZM1.25 8C1.25 3.72621 4.72621 0.25 9 0.25C10.7246 0.25 12.3601 0.805783 13.7243 1.856C13.8294 1.93689 13.898 2.05621 13.9151 2.18771C13.9323 2.31921 13.8964 2.45212 13.8155 2.5572L12.1308 4.74573C11.9623 4.96454 11.6484 5.00538 11.4296 4.83694C10.728 4.29687 9.88968 4.0119 9 4.0119C6.80141 4.0119 5.0119 5.80141 5.0119 8C5.0119 10.1986 6.80141 11.9881 9 11.9881C10.5181 11.9881 11.8425 11.1347 12.516 9.88095H9C8.72386 9.88095 8.5 9.65709 8.5 9.38095V6.61905C8.5 6.34291 8.72386 6.11905 9 6.11905H16.25C16.5261 6.11905 16.75 6.34291 16.75 6.61905V8C16.75 12.2738 13.2738 15.75 9 15.75C4.72621 15.75 1.25 12.2738 1.25 8Z" fill="black"/>
    </svg>
    `
})

export class IconGoogleComponent { }
