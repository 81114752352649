import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-cog',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './cog.component.html'
})

export class IconCogComponent {}
