import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppState } from '../../../app.service';

@Injectable()
export class MonitoringPopupService {
  constructor(private appState: AppState, private http: HttpClient) { }

  open(identifier?) {
    this.appState.set('monitoringPopupVisibility', true);
    if (identifier) {
      this.appState.set('monitoringIdentifier', identifier);
    }
  }

  close() {
    this.appState.set('monitoringPopupVisibility', false);
  }

  getMonitoring() {
    return this.http.get(`/monitoring/attributes`).pipe(map((res) => {
      return res;
    }));
  }

  getParameters(type: 'UL' | 'IP') {
    return this.http.get(`/api/parameters/?type=${type}`).pipe(map((res) => {
      return res;
    }));
  }

  getClient(id) {
    return this.http.get(`/api/client/${id}`).pipe(map((res) => {
      return res;
    }));
  }

  deleteClient(id) {
    return this.http.delete(`/api/client/${id}`).pipe(map((res) => {
      return res;
    }));
  }

  postClient(data) {
    return this.http.post(`/api/client/`, data).pipe(map((res) => {
      return res;
    }));
  }

  putClient(data) {
    return this.http.patch(`/api/client/${data.identifier}/`, data).pipe(map((res) => {
      return res;
    }));
  }

  getSavedMonitoring(identifier) {
    return this.http.get(`/monitoring/attributes?identifier=${identifier}`).pipe(map((res) => {
      return res;
    }));
  }

  saveMonitoring(data) {
    return this.http.post(`/monitoring`, data).pipe(map((res) => {
      return res;
    }));
  }
}
