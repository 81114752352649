import { Component, OnInit } from '@angular/core';
// import {FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl} from '@angular/forms';
import { AuthRegPopupService } from '../shared/components/auth-reg-popup/auth-reg-popup.service';
import { AuthService } from '../shared/services/auth.service';
import { GtagService } from '../shared/services/gtag.service';
import { UtilsService } from '../shared/services/utils.service';
import { WindowRef } from '../shared/services/window.ref';
import { BehaviorSubjectService } from '../shared/services/behavior-subject.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {
  // form: FormGroup;
  // errorText: string;
  // waiting: boolean;
  // success: boolean;

  // constructor(
  //   private fb: FormBuilder,
  //   protected auth: AuthService,
  //   private winRef: WindowRef
  // ) {
  //   this.initForm();
  //   this.success = false;
  // }

  // ngOnInit() {
  // }
  // registration(body) {
  //   let {username, password} = body;
  //   this.errorText = '';
  //   this.waiting = true;
  //   this.auth.registration(username, password).subscribe((response) => {
  //     if (location.hostname === 'kompra.kz') {
  //       let self = this;
  //       setTimeout(function() {
  //         self.winRef.nativeWindow.dataLayer.push({'event': 'registration'});
  //       }, 100);
  //     }
  //     // this.auth.login(username, password).subscribe((res) => {
  //     //   this.appState.set('balance', res['balance']);
  //     //   this.waiting = false;
  //     //   this.authRegPopupService.close();
  //     // });
  //     this.success = true;
  //   }, (err) => {
  //     console.log(err);

  //     this.waiting = false;
  //     if (err && err.error && err.error.error === 'UserAlreadyExist') {
  //       this.errorText = 'Пользователь уже существует!';
  //     } else {
  //       this.errorText = err.error.message;
  //     }
  //   });
  // }

  // private initForm(): void {
  //   this.form = this.fb.group({
  //     username: ['', [Validators.email, Validators.required]],
  //     password: ['', [Validators.required, Validators.minLength(6)]],
  //     re_password: ['', [Validators.required, Validators.minLength(6), this.twinValidator]],
  //     agree: [false, Validators.requiredTrue]
  //   });
  // }

  // private twinValidator(control: AbstractControl) {
  //   if (!control.root || !control.root.get('password')) {
  //     return null;
  //   }
  //   const similar = control.value === control.root.get('password').value;
  //   return similar ? null : {'twin': true};
  // }

  errorText: string;
  waiting: boolean;
  success: boolean;
  public reg = {
    email: '',
    password: '',
    confirmPassword: '',
    lastName: '',
    firstName: '',
    iAgree: false
  };
  emailPattern = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i 

  constructor(
    public authRegPopupService: AuthRegPopupService,
    public utils: UtilsService,
    protected auth: AuthService,
    // public router: Router,
    private winRef: WindowRef,
    private gtagService: GtagService,
    public subjectService: BehaviorSubjectService
    ) {
    this.success = false;
    this.waiting = false;
    this.utils.setTitle();
    this.utils.setTag();
  }

  ngOnInit() {}
  
  registration(email: string, password: string, lastName: string, firstName: string) {
    this.errorText = '';
    this.waiting = true;
    const source = sessionStorage.getItem('utm_campaign')
    const data = source ? {email, password, lastName, firstName, source} : {email, password, lastName, firstName}
    this.auth.registration(data).subscribe((response) => {
      if (location.hostname === 'kompra.kz') {
        let self = this;
        setTimeout(function() {
          self.winRef.nativeWindow.dataLayer.push({'event': 'registration'});
        }, 100);
      }
      this.success = true;
      this.gtagService.gtag_report_registration()
      // let arr = this.router.url.split('/');
      // if (arr && arr.length && arr[1] === 'card') {
      //   let id = parseInt(arr[2], 10);
      //   localStorage.setItem('redirectId', id + '');
      // }
    }, (err) => {
      this.waiting = false;
      if (err && err.error && err.error.error === 'UserAlreadyExist') {
        this.errorText = 'Пользователь уже существует!';
      } if (err && err.error && err.error.error === 'InvalidOldPassword') {
        this.errorText = 'Неправильный старый пароль!';
      } if (err && err.error && err.error.error === 'UserNotFound') {
        this.errorText = 'Пользователь не найден!';
      } if (err && err.error && err.error.error === 'MailError') {
        this.errorText = 'Не правильный емейл!';
      } if (err && err.error && err.error.error === 'InternalError') {
        this.errorText = 'Системная ошибка, попробуйте позднее!';
      } else {
        this.errorText = err.error.message;
      }
    });
  }

}
