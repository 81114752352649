import { Component, OnInit, OnDestroy, HostBinding, ViewChild, ElementRef, AfterViewChecked, HostListener } from '@angular/core';
import { AuthRegPopupService } from '../auth-reg-popup/auth-reg-popup.service';
import { AuthService } from '../../services/auth.service';
import { CabinetService } from '../../services/cabinet.service';
import { AppState } from '../../../app.service';
import { TranslateService } from '@ngx-translate/core';
import { ModeService } from '../../services/mode.service';
import { DataService } from '../../../data.service';
import { ToasterService } from 'angular2-toaster';
import { WindowRef } from '../../services/window.ref';
import { Router } from '@angular/router';
import { AccessRightService } from '../../services/access-right.service';
import { BehaviorSubjectService } from '../../services/behavior-subject.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewChecked {
  @HostBinding('class') class = 'layout__header';
  @ViewChild('navbar') navbar: ElementRef;
  visibilityLoginModal: boolean = false;
  visibilityAdminMenu: boolean = false;
  visibilityLang: boolean = false;
  visibilityServices: boolean = false;
  mobileMenu: boolean = false;
  selectedText: string;
  reportComment: string;
  reportSended: boolean = false;
  visibilityContacts: boolean = false;
  activationVisibility: boolean = false;
  smsModal: boolean = false;
  smsWaiting: boolean = false;
  report: boolean = false;
  phone: any;
  sms: any;
  interval: any;
  second: number = 120;
  mask: any[] = ['+', '7', /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  // notification;
  // visibilityNotificationBar;
  heigthNavbar;
  phoneConfirmation: boolean = false;
  public user: any = {}
  
  constructor(
    public authRegPopupService: AuthRegPopupService,
    public authService: AuthService,
    public accessRightService: AccessRightService,
    private cs: CabinetService,
    public appState: AppState,
    public translate: TranslateService,
    public modeService: ModeService,
    public dataService: DataService,
    private winRef: WindowRef,
    public toast: ToasterService,
    private router: Router,
    public subjectService: BehaviorSubjectService
    ) {
    let b = this.winRef.nativeWindow && this.winRef.nativeWindow.f;
    let l = this.winRef.nativeWindow && this.winRef.nativeWindow.s;
    let q = 11111111111111111 === 11111111111111112 && l ? l : this.cs.utils.getNav();
    this.appState.state[b] = this.cs.utils.hashCode(q);
    // this.visibilityNotificationBar = false;
  }

  @HostListener('document:keyup', ['$event'])
  public onChange(e: KeyboardEvent) {
    // ctrl + enter
    if (e.ctrlKey && e.keyCode === 13) {
      if (this.winRef.nativeWindow.getSelection) {
        this.selectedText = this.winRef.nativeWindow.getSelection().toString();
      } else if (document['selection'] && document['selection'].type !== 'Control') {
        this.selectedText = document['selection'].createRange().text;
      }
      this.report = true;
      return;
    }
  }
  
  setNavbarNavigation(v) {
    this.appState.set('navbarNavigationVisible', v);
  }

  sendReport() {
    // this.cs.sendReport({
    //   selectedText: this.selectedText,
    //   comment: this.reportComment,
    //   url: location.href,
    //   email: this.authService.user
    // }).subscribe((res) => {
    //   this.reportSended = true;
    //   this.clearReport();
    // }, (err) => {
    //   this.toast.pop('error', 'Ошибка при отправке!');
    // });
    console.log('Отправка на бэк данных', this.selectedText, this.reportComment, location.href, this.authService.user);
    this.reportSended = true;
    this.clearReport();
  }

  clearReport() {
    this.report = false;
    this.reportSended = false;
    this.reportComment = null;
  }

  checkActivation(user) {
    if (user && (!user.subscribed && user.phone === null)) {
      this.activationVisibility = true;
    }
  }

  closeActivation() {
    this.activationVisibility = false;
  }

  ngOnInit() {
    if (this.authService.authorized) {
      this.getUserInfo()
      if (typeof this.appState.get('favCount') !== 'number') {
        this.cs.getCount().subscribe((res: any) => {
          this.appState.set('favCount', res.chosenCompanyCount);
        });
      }
      this.cs.getBalance().subscribe((res: any) => {
        this.appState.set('balance', res['balance']);
      });
      this.checkActivation(JSON.parse(localStorage.getItem('user')));
      this.appState.updateCurrentTariff()
    } else {
      this.authService.clearStorage();
    }

    this.dataService.getDataObs('user').subscribe((user) => {
      this.getUserInfo()
      this.checkActivation(user);
    });
    this.appState.set('phoneConfirm', JSON.parse(localStorage.getItem('phoneConfirmation') || 'false'))
  }

  logout() {
    this.appState.set('showInternalChecking', false)
    this.authService.logout()
  }

  showSmsActivation() {
    this.smsModal = true;
  }

  // getNotification() {
  //   this.cs.getBanner().subscribe((res) => {
  //     this.notification = res;
  //   });
  //   let self = this;
  //   // setTimeout(() => {
  //   //   self.getNotification();
  //   // }, 300000);
  // }

  getTooltipInfo() {
    if(!this.user.firstName || !this.user.lastName) {
      return `
        Аккаунт: ${this.appState.state.currentTariff === 'Баланс' ? 'Балансник' : 'Подписчик'}
        ${this.user.email || ''}
      `
    }
    return `
      Аккаунт: ${this.appState.state.currentTariff === 'Баланс' ? 'Балансник' : 'Подписчик'}
      ${this.user.firstName} ${this.user.lastName}
      ${this.user.email || ''}
    `
  }

  getUserInfo() {
    setTimeout(() => {
      const user = localStorage.getItem('user')
      const userInfo = localStorage.getItem('userInfo')
      if(user) {
        const {user_name} = JSON.parse(user)
        this.user = {...this.user, email: user_name || null}
      }
      if(userInfo) {
        this.user = {...this.user, ...JSON.parse(userInfo)}
      }
    }, 1000);
  }

  getFavCount() {
    return typeof this.appState.get('favCount') === 'number' ? this.appState.get('favCount') : 0;
  }

  // goBack() {
  //   this.location.back();
  // }

  ngAfterViewChecked() {
    this.heigthNavbar = this.navbar.nativeElement.offsetHeight;
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  getSms() {
    let self = this;
    this.second = 120;
    this.authService.getSms(this.phone).subscribe((res) => {
      this.smsWaiting = true;
      this.interval = setInterval(function () {
        self.second--;
        if (self.second === 0) {
          clearInterval(self.interval);
        }
      }, 1000);
    }, (err) => {
      clearInterval(this.interval);
      if (err.status === 403 && err.error.username !== this.authService.user) {
        this.toast.pop('info', 'Телефон привязан к другому аккаунту Kompra.');
      } else {
        this.smsWaiting = true;
        this.interval = setInterval(function () {
          self.second--;
          if (self.second === 0) {
            clearInterval(self.interval);
          }
        }, 1000);
      }
    });
  }

  sendSms(sms) {
    if ((sms + '').length === 5) {
      this.authService.sendSms(this.phone, sms).subscribe((res) => {
        this.toast.pop('success', 'Телефон подтвержден, вам начислено ' + this.authService.getBonus() + ' тг.');
        this.smsModal = false;
        this.activationVisibility = false;
        let user = JSON.parse(localStorage.getItem('user'));
        user.phone = this.phone;
        localStorage.setItem('user', JSON.stringify(user));
        this.cs.getBalance().subscribe((r) => {
          this.appState.set('balance', r['balance']);
        });
      }, (err) => {
        this.toast.pop('error', 'Не верный код, проверьте правильность ввода данных');
        console.log(err);
      });
    }
  }

  isPhoneNumber() {
    return /^((\+7|7|8)+([0-9]){10})$/.test(this.phone);
  }

  getLang(lang) {
    let languages = {
      ru: 'РУС',
      en: 'ENG'
    };
    return lang ? languages[lang] : 'РУС';
  }

  shortName() {
    let userName = this.authService.user;
    let text = userName.text();

    if (text.length > 20) {
      let userNameShort = text.substring(0, 20) + '...';
      return userNameShort;
    }
  }

  isTariffRoute() {
    return this.router.url && this.router.url.includes('/tariff');
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
