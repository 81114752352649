import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-black-friday',
  template: `<div id="black-friday-banner"></div>`,
  styles: [`
    #black-friday-banner {
      margin: 0 auto;
      z-index: 100;
      cursor: pointer;
    }
    @media(min-width: 761px) {
      #black-friday-banner {
        height: 120px;
        background: url('/assets/img/banner-1200.png') no-repeat;
      }
    }
    @media(max-width: 760px) {
      #black-friday-banner {
        object-fit: contain;
        height: 228px;
        margin: 0 auto;
        background: url('/assets/img/banner-384.png') no-repeat;
        background-size: contain; 
      }
    }
  `]
})
export class BlackFridayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
