import { Component, HostBinding } from '@angular/core';
import { AuthRegPopupService } from './auth-reg-popup.service';
import { AppState } from '../../../app.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-auth-reg-popup',
    templateUrl: './auth-reg-dialog.component.html'
})
export class AuthRegPopupComponent {
  @HostBinding('class') class = 'layout__overlay layout__overlay--active';
  constructor(
    public authRegPopupService: AuthRegPopupService,
    public appState: AppState,
    public translate: TranslateService
  ) {
  }
}
