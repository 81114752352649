import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { UtilsService } from './utils.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class AdminService {
  constructor(
    private http: HttpClient,
    public utils: UtilsService) { }

  public addToExceptions(data: any, id: number) {
    return this.http.post(`/company/${id}/exceptions`, data).pipe(map((res) => {
      return res;
    }));
  }

  public getExceptions(params) {
    let p: string = this.utils.createUrlParams(params);
    return this.http.get(`/company/exceptions${p}`).pipe(map((res) => {
      return res;
    }));
  }

  public deleteFromExceptions(id) {
    return this.http.delete(`/company/exceptions/${id}`).pipe(map((res) => {
      return res;
    }));
  }

}
