import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { EdsComponent } from './eds.component';
import { EdsService } from './eds.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    TranslateModule
  ],
  declarations: [
    EdsComponent
  ],
  providers: [
    EdsService
  ],
  entryComponents: [],
  exports: [
    EdsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class EdsModule { }
