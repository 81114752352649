import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { AppState } from './app.service';
import { ToasterConfig } from 'angular2-toaster';
import { CabinetService } from './shared/services/cabinet.service';
import { TranslateService } from '@ngx-translate/core';
import { WindowRef } from './shared/services/window.ref';
import { VERSION } from './app.constants';
import { ModeService } from './shared/services/mode.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Gtag } from 'angular-gtag';
import { AuthService } from './shared/services/auth.service';
import { BehaviorSubjectService } from './shared/services/behavior-subject.service';

@Component({
  selector: 'app-app',
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="layout__notification notification-bar" *ngIf="updateVisibility">
      <div class="notification-bar__container">
        <div class="notification-bar__note">
          Текущая версия приложения устарела. Сбросьте кэш и обновите страницу или нажмите «Ctrl» + «R», Хотите обновить?
        </div>
        <div class="button-group">
          <div class="button-group__layout">
            <button (click)="updateVisibility = false" class="button button--false">
                Нет
            </button>
          </div>
          <div class="button-group__layout">
            <button (click)="refresh()" class="button button--true">Да</button>
          </div>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
    <toaster-container [toasterconfig]="toasterconfig"></toaster-container>
    <app-auth-reg-popup
      *ngIf="appState.state.authRegPopupVisibility"
    ></app-auth-reg-popup>
    <app-agreed-popup
      *ngIf="appState.state.agreedPopupVisibility"
    ></app-agreed-popup>
<!--    <app-phone-confirmation -->
<!--      *ngIf="appState.state['phone-confirmation'] && authService?.authorized" -->
<!--      [phone]="data.phone"-->
<!--      (close)="closePhoneConfirmation()"-->
<!--    ></app-phone-confirmation>-->

    <!--<div class="modal-notifier" *ngIf="updateVisibility">
      <div class="panel-notifier">
          <p class="ta-c"><span>Текущая версия приложения устарела.</span><br/>
          <span>Сбросьте кэш и обновите страницу или </span><br/>
          <span>нажмите «Ctrl» + «F5»</span></p>
          <p class="ta-c">Хотите обновить?</p>
          <div class="df j-c">
              <button (click)="updateVisibility = false" class="button button--false">Нет</button>
              <button (click)="refresh()" class="button button--true">Да</button>
          </div>
      </div>
  </div>-->
    <div
      class="notifier"
      [ngClass]="appState?.state?.notifierType"
      *ngIf="appState?.state?.notifyVisibility"
    >
      {{ appState?.state?.notifierText }}
    </div>
  `,
  styleUrls: ['./../assets/scss/app.scss'],
})
export class AppComponent implements OnInit {
  // notification;
  // visibilityNotificationBar;
  version: number;
  updateVisibility: boolean;
  smsActivation: boolean;
  toasterconfig: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    timeout: 10000,
  });
  data: any = {}

  constructor(
    public appState: AppState,
    public cs: CabinetService,
    private translate: TranslateService,
    private winRef: WindowRef,
    private activatedRoute: ActivatedRoute,
    public windowRef: WindowRef,
    private modeService: ModeService,
    public authService: AuthService,
    public subjectService: BehaviorSubjectService,
    public gtag: Gtag
  ) {
    // this.visibilityNotificationBar = false;
    this.updateVisibility = false;
    this.smsActivation = false;
    this.appState.set('visibilityNotificationBar', false);
    translate.setDefaultLang('ru');
    let a = this.cs.utils.shortGuid();
    this.appState.state['b' + 'a' + +'a' + 'a'] = 'a' + a;
    this.appState.set('a' + a, VERSION);
    this.appState.set('navbarNavigationVisible', false);
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.appState.set('referralCode', params['referralCode']);
    });
  }

  showSmsActivation() {
    this.smsActivation = true;
  }

  ngOnInit() {
    let self = this;
    this.getNotification();
    this.checkSetting();
    setInterval(function () {
      self.checkSetting();
    }, 180000);
    this.winRef.nativeWindow.addEventListener(
      'offline',
      function (val) {
        self.appState.set('connectionStatus', 'offline');
      },
      false
    );
    this.winRef.nativeWindow.addEventListener(
      'online',
      function (val) {
        self.appState.set('connectionStatus', 'online');
      },
      false
    );
    this.checkFeastEvents()
    this.checkSource()
    this.checkPhoneConfirmation()
  }

  getUserInfo() {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user) {
      const { user_name, phone } = user
      if(user_name) {
        this.data.email = user_name
      }
      if(phone) {
        this.data.phone = phone
      }
    }
  }

  checkPhoneConfirmation() {
    const today = new Date().toLocaleDateString()
    const phoneConfirmation = localStorage.getItem('phoneConfirmation')
    if(!phoneConfirmation) {
      this.appState.set('phone-confirmation', false)
      return
    }
    if(phoneConfirmation && new Date(today) > new Date(+phoneConfirmation)) {
      this.appState.set('phone-confirmation', true)
      this.appState.set('phoneConfirm', false)
      localStorage.removeItem('phoneConfirmation')
    }
  }

  closePhoneConfirmation() {
    this.appState.set('phoneConfirm', true)
    this.appState.set('phone-confirmation', false)
    localStorage.setItem('phoneConfirmation', String(new Date().getTime()))
  }

  checkSource() {
    if(document.URL.includes('utm_campaign')) {
      let source: string = document.URL.split('utm_campaign=')[1].split('&')[0]
      if(source.includes('mp.kz')) {
        source = 'MP'
      }
      sessionStorage.setItem('utm_campaign', source.replace(/[^a-zA-Z]+/g, '').toUpperCase())
    }
  }

  checkFeastEvents() {
    const date = new Date()
    const today = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    const time = date.getTime();
    const december = 11
    const january = 0
    const newYearStart = new Date(today.getFullYear(), december, 26);
    const newYearEnd = new Date(today.getFullYear(), january, 7);
    if ((today.getMonth() === december && (today >= newYearStart)) || (today.getMonth() === january && (today <= newYearEnd))) {
      this.appState.set('newYearEvent', true);
    }
    if (1584327600000 < time && time < 1589241579000) {
      this.appState.set('nauryzBonus', true);
    }
    // 1597978800000 < d &&
    if (time < 1599847200000) {
      this.appState.set('birthday', true);
    }
    // if (today.getMonth() === 10 && today.getDay() === 5) {
    //   this.appState.set('blackFriday', true);
    // }
  }

  checkSetting() {
    this.cs.checkVersion().subscribe(
      (res) => {
        this.checkVersion(res);
      },
      (err) => {
        this.checkVersion(err.error);
      }
    );
  }
  getNotification() {
    this.cs.getBanner().subscribe((res) => {
      this.appState.set('notification', res);
    });
    this.cs.getPromo().subscribe(
      (res) => {
        this.appState.set('connectionStatus', 'online');
      },
      (err) => {
        if (err.name === 'HttpErrorResponse') {
          this.appState.set('connectionStatus', 'offline');
        }
      }
    );
    let self = this;
    setTimeout(() => {
      self.getNotification();
    }, 180000);
  }

  checkVersion(res) {
    let text = res.text;
    if (text) {
      let v = /var\s+VERSION\s+=\s+'(\d+)'/.exec(text);
      if (
        v &&
        v.length &&
        VERSION &&
        parseInt(v[1], 10) > parseInt(VERSION, 10) &&
        !this.modeService.isDev()
      ) {
        this.updateVisibility = true;
      }
    }
  }
  refresh() {
    this.winRef.nativeWindow.location.reload();
  }
}
