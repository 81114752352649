import { Component, OnInit } from '@angular/core';
import {FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {logger} from 'codelyzer/util/logger';
import { HttpClient } from '@angular/common/http';
import { WindowRef } from '../../services/window.ref';

@Component({
  selector: 'app-subscribe-form',
  templateUrl: './subscribe-form.component.html',
  styleUrls: ['./subscribe-form.component.css']
})
export class SubscribeFormComponent implements OnInit {
  isSubscribe: boolean;
  form: FormGroup;
  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private http: HttpClient,
    private winRef: WindowRef
  ) {
    this.isSubscribe = false;
  }

  ngOnInit() {
    this.formInit();
    this.authService.loggedIn$.subscribe(value => {
      this.isSubscribe = true;
    });
  }

  formInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      type: ['MAIN']
    });
  }

  registration(body) {
    let {email, phone, type} = body;
    this.http.post('/cabinet/subscription', { 'email': email, 'phone': phone, 'type': type })
      .subscribe((response) => {
        if (location.hostname === 'kompra.kz') {
          let self = this;
          setTimeout(function() {
            self.winRef.nativeWindow.dataLayer.push({'event': 'newsletter'});
          }, 100);
        }
        this.isSubscribe = true;
    });
  }

}
