import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SchemeService } from '../../../modules/scheme/scheme.service';
import { ModeService } from '../../services/mode.service';

@Component({
  selector: 'app-treeview',
  templateUrl: './treeview.component.html'
})
export class TreeviewComponent implements OnInit {
  @Input() nodes: Array<any>;
  @Input() isFirstNode: boolean;
  @Input() level: number;
  @Output() setLevel: any = new EventEmitter();
  @Output() refresh: any = new EventEmitter();
  innerLevel: number;

  constructor(
    private schemeService: SchemeService,
    public modeService: ModeService
  ) { }

  ngOnInit() {
    this.innerLevel = this.level + 1;
    this.onSetLevel(this.innerLevel);
  }

  onSetLevel(level) {
    this.setLevel.emit(level);
  }

  onRefresh(level) {
    this.refresh.emit(level);
  }

  onExpand(
    node,
    type?: 'foundedCompanyList' | 'founderList' | 'ownedCompanyList'
  ) {
    if (!type) {
      node.isExpand = !node.isExpand;
    }
    if (type === 'foundedCompanyList') {
      node.foundedCompanyListIsExpand = !node.foundedCompanyListIsExpand;
    }
    if (type === 'founderList') {
      node.founderListIsExpand = !node.founderListIsExpand;
    }
    if (type === 'ownedCompanyList') {
      node.ownedCompanyListIsExpand = !node.ownedCompanyListIsExpand;
    }
  }

  getNextLevel(id, type) {
    this.schemeService.getNextNode(id).subscribe((res) => {
      if (type === 'self') {
        this.nodes = [res];
      }
      if (type === 'parentCompany') {
        this.nodes[0].parentCompany = res;
      }
      if (type === 'owner') {
        this.nodes[0].owner = res;
      }
      if (type === 'ownedIp') {
        this.nodes[0].ownedIp = res;
      }
    });
  }

  updateExtendedFlPart(id) {
    this.schemeService.updateExtendedFlPart(id).subscribe((res) => {
      this.refresh.emit(true);
    });
  }
}
