import { Injectable } from '@angular/core';
import { UtilsService } from './../../../shared/services/utils.service';
import { layouts } from './cytoscape-layouts';

@Injectable()
export class CytoscapeService {

  public instance
  public allDatas 
  public schemeList = []
  public isPerson: boolean = false
  public lastUpdated: string

  constructor(
    public utils: UtilsService
  ) { }

  public convertJsonToGraph(json: any, excludes: string[] = []) {
    const graph = { nodes: [], edges: [] };
  
    // main node
    if (!excludes.includes(json.id)) {
      graph.nodes.push({
        data: {
          id: json.id,
          name: this.shortNames(json.name),
          props: json,
        },
      });
    }
  
    // founder
    if (json.founders && json.founders.length) {
      json.founders.forEach((founder: any, index: number) => {
        if (!excludes.includes(founder.id)) {
          if (founder.ip) {
            graph.nodes.push({
              data: {
                id: `founder-ip-${json.id}-${index}`,
                name: this.shortNames(founder.name),
                props: founder,
                ip: true,
              },
            });
            graph.edges.push({
              data: {
                source: founder.id,
                target: `founder-ip-${json.id}-${index}`,
                type: 'ИП'
              },
            });
          }
  
          graph.nodes.push({
            data: {
              id: founder.id,
              name: this.shortNames(founder.name),
              props: founder,
            },
          });
          if(json.id && founder.id) {
            graph.edges.push({
              data: {
                source: json.id,
                target: founder.id,
                type: 'Учредитель'
              },
            });
          }
        }
      });
    }
  
    // owner
    if (json.owner && !excludes.includes(json.owner.id)) {
      if (json.owner.ip) {
        graph.nodes.push({
          data: {
            id: `ip-${json.owner.id}`,
            name: this.shortNames(json.owner.name),
            props: json.owner,
            ip: true,
          },
        });
        graph.edges.push({
          data: {
            source: json.owner.id,
            target: `ip-${json.owner.id}`,
            type: 'ИП'
          },
        });
      }
  
      graph.nodes.push({
        data: {
          id: json.owner.id,
          name: this.shortNames(json.owner.name),
          props: json.owner,
        },
      });
      if(json.id && json.owner.id) {
        graph.edges.push({
          data: {
            source: json.id,
            target: json.owner.id,
            type: 'Руководитель'
          },
        });
      }
    }
  
    // involvement
    if (json.involvement && json.involvement.length) {
      json.involvement.forEach((involvement: any, index: number) => {
        if (!excludes.includes(involvement.id)) {
          if (involvement.ip) {
            graph.nodes.push({
              data: {
                id: `involvement-ip-${json.id}-${index}`,
                name: this.shortNames(involvement.name),
                props: involvement,
                ip: true,
              },
            });
            graph.edges.push({
              data: {
                source: involvement.id,
                target: `involvement-ip-${json.id}-${index}`,
                type: 'ИП'
              },
            });
          }
          graph.nodes.push({
            data: {
              id: involvement.id,
              name: this.shortNames(involvement.name),
              props: involvement,
            },
          });
          if(json.id && involvement.id) {
            graph.edges.push({
                data: {
                  source: json.id,
                  target: involvement.id,
                  type: 'Участие'
                },
              });
          }
        }
      })
    }

    return graph
  }

  public shortNames(name: string): string {
    if (name) {
      name = name.toUpperCase().replace('ТОВАРИЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ', 'ТОО');
      name = name.toUpperCase().replace('ОТКРЫТОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО', 'ОАО');
      name = name.toUpperCase().replace('ЗАКРЫТОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО', 'ЗАО');
      name = name.toUpperCase().replace('АКЦИОНЕРНОЕ ОБЩЕСТВО', 'АО');
      name = name.toUpperCase().replace('ОБЩЕСТВЕННОЕ ОБЪЕДИНЕНИЕ', 'ОО');
    }
    return name;
  }

  public getUniqueDatas(datas: any[]): any[] {
    let result = datas.map(data => JSON.stringify(data))
    result = [...new Set(result)].map(data => JSON.parse(data))
    return result
  }

  public getLayoutOptions(type: string = 'dagre') {
    return layouts[type]
  }

  public showConnectedNodes(nodeId: string, id: string): void {
    const node = this.instance.nodes().filter((node: any) => node._private.data.id == nodeId)
    const outgoingEdges = node.connectedEdges().filter(edge => edge.source().id() === nodeId);
    const childrenNodes = outgoingEdges.connectedNodes().filter(connectedNode => connectedNode.id() !== nodeId);

    childrenNodes.forEach((childNode) => {
      this.showConnectedNodes(childNode._private.data.id, id)
      outgoingEdges.forEach(edge => {
        if ((edge.source().id() === nodeId) || (edge.target().id() === nodeId)) {
          edge.show();
        }
      });
      childNode.show();
    });

    if(!childrenNodes.length) {
      this.changeIcon(node, id, 'final')    }
  }

  public hideConnectedNodes(nodeId: string): void {
    const node = this.instance.nodes().filter((node: any) => node._private.data.id == nodeId)
    const outgoingEdges = node.connectedEdges().filter(edge => edge.source().id() === nodeId);
    const childrenNodes = outgoingEdges.connectedNodes().filter(connectedNode => connectedNode.id() !== nodeId);

    childrenNodes.forEach((childNode) => {
    this.hideConnectedNodes(childNode._private.data.id)
      outgoingEdges.forEach(edge => {
        if ((edge.source().id() === nodeId) || (edge.target().id() === nodeId)) {
          edge.hide();
        }
      });
      childNode.hide();
    });
  }

  public startNodeLoading(identifier: string): void {
    const node = this.instance.nodes().filter((node: any) => node._private.data.props.identifier == identifier)
    node.style({ 
      'background-image': '../../../../assets/img/icons/ic_sync.svg', 
      'background-fit': 'cover',
    })
  }

  public centerOnNode(nodeId) {
    const node = this.instance.nodes().filter((node: any) => node.data('props.identifier') === nodeId)
    console.log(node[0].data('props.person'));
    
    if (node) {
      this.instance.animate({
        center: {
          eles: node,
        },
        zoom: 1,
        duration: 1500
      });
    }
  }

  public changeIcon(node, id: string, type: string): void {
    if(node.data('ip')) {
      node.style({
        'background-image': `../../../../assets/img/icons/scheme/IE-node.svg`, 
      })
    }
    if(node.data('props.person') && !node.data('ip')) {
      node.style({
        'background-image': `../../../../assets/img/icons/scheme/Person-node_${type}.svg`, 
      })
    } 
    if(!node.data('props.person')) {
      node.style({
        'background-image': `../../../../assets/img/icons/scheme/Company-node_${type}.svg`, 
      })
    }
    if(node.data('props.identifier') === id) {
      node.style({
        'background-image': `../../../../assets/img/icons/scheme/Main-node_${type}.svg`, 
      })
    } 
  }
  
  public applyLayout(layout, animate = false): void {
    this.instance.layout({
      ...this.getLayoutOptions(layout),
      animate
    }).start()
    this.instance.center()
    this.instance.fit()
  }
  
  public applyStyles(id: string): void {
    const COMPANIES = this.instance.nodes().filter((node: any) => !node.data('props.person'))
    COMPANIES.style({ 
      'background-color': '#01165a7', 
      'background-image': '../../../../assets/img/icons/scheme/Company-node_extended.svg', 
      'background-fit': 'cover' 
    })
    const PERSONS = this.instance.nodes().filter((node: any) => node.data('props.person'))
    PERSONS.style({ 
      'background-color': '#f05c2c', 
      'background-image': '../../../../assets/img/icons/scheme/Person-node_extended.svg', 
      'background-fit': 'cover' 
    })
    const IP_NODES = this.instance.nodes().filter((node: any) => node.data('ip'))
    IP_NODES.style({ 
      'background-color': '#886d7c', 
      'background-image': '../../../../assets/img/icons/scheme/IE-node.svg', 
      'background-fit': 'cover' 
    })
    const MAIN_NODE = this.instance.nodes().filter((node: any) => {
      if(node.data('props.identifier') === id) {
        this.isPerson = node.data('props.person')
        return node
      }
    })
    MAIN_NODE.style({ 
      'font-size': '22px', 
      'font-weight': 'bold', 
      'width': "80px", 
      'height': "80px", 
      'border-width': '20px',
      'border-color': '#0165a7',
      'border-opacity': '0.5',
      'background-image': `../../../../assets/img/icons/scheme/${this.isPerson ? 'person.png' : 'Main-node_extended.svg'}`, 
      'background-fit': 'cover'
    })

    const OWNER_EDGES = this.instance.edges().filter((edge: any) => edge.data('type') && edge.data('type') === 'Руководитель')
    OWNER_EDGES.style({ 
      'line-color': '#278ACC', 
      'target-arrow-color': '#278ACC',
      'text-background-color': '#278ACC',
      'color': 'white'
    })
    const FOUNDER_EDGES = this.instance.edges().filter((edge: any) => edge.data('type') && edge.data('type') === 'Учредитель')
    FOUNDER_EDGES.style({ 
      'line-color': '#FF6600', 
      'target-arrow-color': '#FF6600',             
      'text-background-color': '#FF6600',
      'color': 'white'
    })
  }
}
