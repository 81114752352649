import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-not-found',
  template: `
    <div style="display: flex; justify-content: center; align-items: center; height: 600px;">
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <img src="../../../../../../assets/img/No-Result.png" width="80px" height="80px" alt="No-Result">
        <span class="text text--light">
          {{ text }}
        </span>
      </div>
    </div>
  `
})
export class CardNotFoundComponent implements OnInit {

  @Input() text: string = 'Нет данных'

  constructor() { }

  ngOnInit() {
  }

}
