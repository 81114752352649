import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-sadsmile',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './sad-smile.component.html'
})

export class IconSadsmileComponent {}
