export enum KompraLight20 {
    tariff = 'KompraLight20',
    periodTypeId = 1,
    totalCount = 20
}

export enum KompraLight50 {
    tariff = 'KompraLight50',
    periodTypeId = 1,
    totalCount = 50
}

export enum Unlimited {
    tariff = 'Unlimited',
    totalCount = 200,
    periodTypeId = 6
}

export enum Premium {
    tariff = 'Premium',
    periodTypeId = 6
}

export enum Test {
    tariff = 'Test',
    totalCount = 10,
    periodTypeId = 1
}

export type TariffTypes = KompraLight20.tariff | KompraLight50.tariff | Unlimited.tariff | Premium.tariff | Test.tariff
