import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { WindowRef } from './window.ref';
import * as FileSaver from 'file-saver';
import { MIME } from './mime.constants';
import { Meta, DomSanitizer, Title } from '@angular/platform-browser';
import { ToasterService } from 'angular2-toaster';

@Injectable()
export class UtilsService {

  constructor(
    private http: HttpClient,
    public win: WindowRef,
    public sanitizer: DomSanitizer,
    private titleService: Title,
    private metaService: Meta,
    private toast: ToasterService,
  ) { }

  public putZero(n: number) {
    return n.toString().length > 1 ? n : `0${n}`;
  }

  public isEmpty(val: any) {
    if (val === '' || typeof val === 'undefined' || val === null || String(val) === 'NaN') {
      return true;
    } else if(typeof val === 'object' && JSON.stringify(val) === '{}') {
      return true
    } else if(Array.isArray(val) && val.length === 0) {
      return true
    } else {
      return false;
    }
  }

  isNumeric(n) {
    return !Array.isArray(n) && (n - parseFloat(n) + 1) >= 0;
  }

  isDate(d: any): boolean {
    return !/Invalid|NaN/.test(new Date(d).toString());
  }

  public guid() {
    return `id${Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)}`;
  }

  shortGuid(): string {
    return `id${Math.random().toString(36).substring(2, 10)}`;
  }

  setTitle(v?) {
    this.titleService.setTitle(v ? v : 'Проверка контрагента на благонадежность по БИН, ИИН, ИНН, ТОО, ИП на Kompra.kz.');
  }

  setTag(name?, content?) {
    this.metaService.updateTag({
      name: name ? name : 'description',
      content: content ? content : 'Бесплатно проверить контрагента ⭐️ Казахстана или России ⭐️ на благонадежность за 3 минуты: учредители, налоговые задолженности, аффилированность с ЮЛ и ФЛ.  ⚡️ Kompra поможет обезопасить ваше сотрудничество.'
    });
  }

  /**
   * Математическое округление
   *
   * @param {number} val - Значение для округления.
   * @param {number} decimalPlaces - Количество символов после запятой.
   * @return {number} Округленное значение
   */
  mathRound(val, decimalPlaces) {
    let t: any = 1;
    if (!this.isEmpty(decimalPlaces)) {
      for (let i = 0; i < decimalPlaces; i++) {
        t = t + '0';
      }
      t = parseInt(t, 10);
    }
    return !this.isEmpty(decimalPlaces) ? Math.round(Math.round(val * 1000000 * t) / 1000000) / t : Math.round(val);
  }

  /**
   * Банковское округление (Гаусовское) - округление происходит к ближайшему чётному, то есть 2,5 → 2; 3,5 → 4.
   *
   * @param {number} val - Значение для округления.
   * @param {number} decimalPlaces - Количество символов после запятой.
   * @return {number} Округленное значение
   */
  gaussRound(num, decimalPlaces?) {
    let d = decimalPlaces || 0,
      m = Math.pow(10, d),
      n = +(d ? num * m : num).toFixed(8),
      i = Math.floor(n), f = n - i,
      e = 1e-8,
      r = (f > 0.5 - e && f < 0.5 + e) ?
        ((i % 2 === 0) ? i : i + 1) : Math.round(n);
    return this.cutter(d ? r / m : r, decimalPlaces);
  }

  cutter(v, decimalPlaces?) {
    if (v && decimalPlaces) {
      let d = v.toString();
      let s = d.split('.');
      if (s && s[1]) {
        s[1] = s[1].substr(0, decimalPlaces);
        return s.join('.');
      } else {
        return v;
      }
    } else {
      return v;
    }
  }

  public getUinType(s) {
    let uin = s && s.toString();
    if (uin && uin[4] >= 4 && uin[4] <= 6) { return 'bin'; }
    if (uin && uin[4] >= 0 && uin[4] <= 3) { return 'iin'; }
    return 'UNKNOWN';
  }

  public isUIN(data: string) {
    if(typeof data !== 'string') {
      data = String(data)
    }
    let obj: any = data ? data.trim() : data;
    return this.isNumeric(data) && obj.length === 12;
  }

  public isFilial(bin: any) {
    let obj: any = bin ? bin.trim() : bin;
    return this.isNumeric(bin) && obj[5] === 1;
  }

  public isNoResident(bin: any) {
    let obj: any = bin ? bin.trim() : bin;
    return this.isNumeric(bin) && obj[4] === 5;
  }

  public isINN(inputNumber) {
    // преобразуем в строку
    inputNumber = '' + inputNumber;
    // преобразуем в массив
    inputNumber = inputNumber.split('');
    // для ИНН в 10 знаков
    if ((inputNumber.length == 10) && (inputNumber[9] == ((2 * inputNumber[0] + 4 * inputNumber[1] +
      10 * inputNumber[2] + 3 * inputNumber[3] + 5 * inputNumber[4] + 9 * inputNumber[5] + 4 * inputNumber[6] +
      6 * inputNumber[7] + 8 * inputNumber[8]) % 11) % 10)) {
      return true;
      // для ИНН в 12 знаков
    } else if ((inputNumber.length == 12) && ((inputNumber[10] == ((7 * inputNumber[0] + 2 * inputNumber[1] +
      4 * inputNumber[2] + 10 * inputNumber[3] + 3 * inputNumber[4] + 5 * inputNumber[5] + 9 * inputNumber[6] +
      4 * inputNumber[7] + 6 * inputNumber[8] + 8 * inputNumber[9]) % 11) % 10) && (inputNumber[11] == ((3 * inputNumber[0] +
        7 * inputNumber[1] + 2 * inputNumber[2] + 4 * inputNumber[3] + 10 * inputNumber[4] + 3 * inputNumber[5] +
        5 * inputNumber[6] + 9 * inputNumber[7] + 4 * inputNumber[8] + 6 * inputNumber[9] + 8 * inputNumber[10]) % 11) % 10))) {
      return true;
    } else {
      return false;
    }
  }

  /*
    Функция склонения
    plural(число, 'год', 'года', 'лет')
    plural(число, 'месяц', 'месяца', 'месяцев')
    plural(число, 'день', 'дня', 'дней')
  */
  public plural(n: number, str1: string, str2: string, str5: string) {
    return ((((n % 10) === 1) && ((n % 100) !== 11)) ? (str1) : (((((n % 10) >= 2) && ((n % 10) <= 4)) &&
      (((n % 100) < 10) || ((n % 100) >= 20))) ? (str2) : (str5)));
  }

  /* TODO: Ошибка в определениях дней */
  public timePassed(date: number, type: 'YEAR' | 'YM' | 'YMD') {
    let rangeDate = '';
    if (!this.isEmpty(date)) {
      let fullYear: number = (new Date().getTime() - new Date(date).getTime()) / (86400000 * 365);
      let year: number = Math.floor(fullYear);
      let month: number = type === 'YMD' ? Math.floor((fullYear - year) * 12) : Math.round((fullYear - year) * 12);
      let day: number = Math.round((((fullYear - year) * 12) - Math.floor((fullYear - year) * 12)) * 24);
      if (year > 0) {
        rangeDate = `${year} ${this.plural(year, 'год', 'года', 'лет')}`;
      }
      if (month > 0 && type !== 'YEAR') {
        rangeDate = rangeDate + ` ${month} ${this.plural(month, 'месяц', 'месяца', 'месяцев')}`;
      }
      if (day > 0 && type === 'YMD') {
        rangeDate = rangeDate + ` ${day} ${this.plural(day, 'день', 'дня', 'дней')}`;
      }
    }
    return rangeDate ? rangeDate.trim() : rangeDate;
  }

  public getBirthDayFromIIN(iin) {
    const mfCenture = [
      { key: 1, centure: 19, gender: 'male' },
      { key: 2, centure: 19, gender: 'female' },
      { key: 3, centure: 20, gender: 'male' },
      { key: 4, centure: 20, gender: 'female' },
      { key: 5, centure: 21, gender: 'male' },
      { key: 6, centure: 21, gender: 'female' },
    ];
    if (iin && this.getUinType(iin) === 'iin') {
      let is = (mfCenture.filter(el => el.key === parseInt(iin[6], 10)) && mfCenture.filter(el => el.key === parseInt(iin[6], 10))[0] ?
        mfCenture.filter(el => el.key === parseInt(iin[6], 10))[0]['centure'] : parseInt(iin[0], 10) < 3 ? 21 : 20) - 1;
      let year = parseInt(is + iin[0] + iin[1], 10);
      let month = parseInt(iin[2] + iin[3], 10);
      let day = parseInt(iin[4] + iin[5], 10);
      return new Date(`${year}-${month}-${day}`).getTime();
    }
    return undefined;
  }

  public availableBefore(date: any) {
    let expireDate: any = new Date(date);
    let options = {
      year: 'numeric', month: 'long',
      day: 'numeric', hour: '2-digit', minute: '2-digit'
    };
    return expireDate.toLocaleDateString('ru-ru', options);
  }

  public getValidDate(val: any) {
    let date: any = Date.parse(val);

    if (!isNaN(date)) {
      let d: any = new Date(date);

      d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
      d.setDate(d.getDate() + 1);

      d = d.toISOString().replace(/\.\d+Z/, '');

      return d;
    } else {
      return undefined;
    }
  }

  public formatDate(value: any) {
    let d: any = new Date(value);

    return `${d.getFullYear()}-${this.putZero(d.getMonth() + 1)}-${this.putZero(d.getDate())}`;
  }

  public formatDateDot(value: any) {
    let d: any = new Date(value);

    return `${this.putZero(d.getDate())}.${this.putZero(d.getMonth() + 1)}.${d.getFullYear()}`;
  }

  public formatDateDMY(v: any) {
    return v ? v.substr(6, 4) + '-' + v.substr(3, 2) + '-' + v.substr(0, 2) : '';
  }

  public formatDateDMY2(v: any) {
    return v ? v.substr(8, 2) + '.' + v.substr(5, 2) + '.' + v.substr(0, 4) : v;
  }

  public formatDateYMD(v: any) {
    return v ? v.substr(8, 2) + '.' + v.substr(5, 2) + '.' + v.substr(0, 4) : '';
  }

  public formatDateYMDRec(v: any) {
    return v ? v.substr(0, 4) + '-' + v.substr(5, 2) + '-' + v.substr(8, 2) : '';
  }

  public hashCode(s, type?: 'positive' | 'default') {
    // return s.split('').reduce((a, b) => {
    //   a = (a << 5) - a + b.charCodeAt(0);
    //   return a & a;
    // }, 0);
    let hash = 0;
    for (let i = 0; i < s.length; i++) {
      let character = s.charCodeAt(i);
      hash = ((hash << 5) - hash) + character;
      hash = hash & hash;
    }
    if (type && type === 'positive') {
      return hash < 0 ? -1 * hash : hash;
    } else {
      return hash;
    }
  }

  public getShortName(name) {
    let copyName = JSON.parse(JSON.stringify({ name: name && name.trim() }))['name'];
    const abr = [
      { full: 'Товарищество с ограниченной ответственностью', short: 'ТОО' },
      { full: 'АКЦИОНЕРНОЕ ОБЩЕСТВО', short: 'АО' },
      { full: 'Государственное учреждение', short: 'ГУ' },
      { full: 'Республиканское Государственное учреждение', short: 'РГУ' },
      { full: 'Республиканское Государственное предприятие', short: 'РГП' },
      { full: 'Республиканское государственное предприятие на праве хозяйственного ведения', short: 'РГП на ПХВ' },
      { full: 'Государственное коммунальное предприятие на праве хозяйственного ведения', short: 'ГКП на ПХВ' },
      { full: 'Республиканского государственного предприятия на праве хозяйственного ведения', short: 'РГП на ПХВ' },
      { full: 'Дочернее государственное предприятие на праве хозяйственного ведения', short: 'ДГП на ПХВ' },
      { full: 'Республиканский центр', short: 'РЦ' },
      { full: 'ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ', short: 'ИП' },
      { full: 'Республики Казахстан', short: 'РК' },
      { full: 'Министерство Внутренних дел', short: 'МВД' },
      { full: 'Министерство информации и коммуникации', short: 'МИК' },
      { full: 'Министерство по инвестициям и развитию', short: 'МИР' },
      { full: 'Министерство здравоохранения', short: 'МЗ' },
      { full: 'Министерство труда и социальной защиты населения', short: 'МТСЗН' },
      { full: 'Министерство иностранных дел', short: 'МИД' },
      { full: 'Министерство культуры и спорта', short: 'МКС' },
      { full: 'Министерство образования и науки', short: 'МОН' },
      { full: 'Министерство обороны', short: 'МО' },
      { full: 'Министерство энергетики', short: 'МЭ' },
      { full: 'Министерство национальной экономики', short: 'МНЭ' },
      { full: 'Министерство сельского хозяйства', short: 'МСХ' },
      { full: 'Министерство финансов', short: 'МФ' },
      { full: 'Министерство юстиции', short: 'МЮ' },
      { full: 'Министерство общественного развития', short: 'МОР' },
      { full: 'Министерство обороны и аэрокосмической промышленности', short: 'МОАП' },
      { full: 'Министерства Внутренних дел', short: 'МВД' },
      { full: 'Министерства информации и коммуникации', short: 'МИК' },
      { full: 'Министерства по инвестициям и развитию', short: 'МИР' },
      { full: 'Министерства здравоохранения', short: 'МЗ' },
      { full: 'Министерства труда и социальной защиты населения', short: 'МТСЗН' },
      { full: 'Министерства иностранных дел', short: 'МИД' },
      { full: 'Министерства культуры и спорта', short: 'МКС' },
      { full: 'Министерства образования и науки', short: 'МОН' },
      { full: 'Министерства обороны', short: 'МО' },
      { full: 'Министерства энергетики', short: 'МЭ' },
      { full: 'Министерства национальной экономики', short: 'МНЭ' },
      { full: 'Министерства сельского хозяйства', short: 'МСХ' },
      { full: 'Министерства финансов', short: 'МФ' },
      { full: 'Министерства юстиции', short: 'МЮ' },
      { full: 'Министерства общественного развития', short: 'МОР' },
      { full: 'Министерства обороны и аэрокосмической промышленности', short: 'МОАП' },
      { full: 'Агентство по делам государственной службы и противодействию коррупции', short: 'АДГСиПК' },
      { full: 'Общество с ограниченной ответственностью', short: 'ООО' },
      { full: 'Общественное объединение', short: 'ОО' },
      { full: 'Закрытое акционерное общество', short: 'ЗАО' },
      { full: 'Открытое акционерное общество', short: 'ОАО' },
      { full: 'Государственное казенное предприятие', short: 'ГКП' },
      { full: 'Государственное предприятие', short: 'ГП' },
      { full: 'Государственное Коммунальное Казенное предприятие', short: 'ГККП' },
      { full: 'Дочернее государственное предприятие', short: 'ДГП' },
      { full: 'Государственное Предприятие На Праве Оперативного Управления', short: 'ГП на ПОУ' },
      { full: 'Управление государственных доходов', short: 'УГД' },
      { full: 'Товарищество с дополнительной ответственностью', short: 'ТДО' },
      { full: 'Автономная организация образования', short: 'АОО' },
      { full: 'Первичная профсоюзная организация', short: 'ППО' },
      { full: 'Производственный кооператив', short: 'ПК' },
      { full: 'Производственный Сельскохозяйственный Кооператив', short: 'ПСК' },
      { full: 'Коммунальное государственное учреждение', short: 'КГУ' },
      { full: 'Частное медицинское учреждение', short: 'ЧМУ' },
      { full: 'Объединение юридических лиц', short: 'ОЮЛ' },
      { full: 'Сельскохозяйственное товарищество смешанного вида', short: 'СТ смешанного вида' },
      { full: 'Жилищно-строительный кооператив', short: 'ЖСК' },
      { full: 'Сельский потребительский кооператив', short: 'СПК' },
      { full: 'Потребительский кооператив', short: 'ПК' }
    ];
    if (name) {
      abr.forEach(el => {
        let index: number = copyName.toLowerCase().indexOf(el.full.toLowerCase());
        if (index > -1 && (index + el.full.length) <= copyName.length) {
          let n = copyName.slice(index, index + el.full.length);
          if (n && n.length === el.full.length) {
            let re: any = new RegExp(n, 'gi');
            copyName = copyName.replace(re, el.short);
          }
        }
      });
    }
    return copyName;
  }

  getNav() {
    return navigator.productSub + navigator.appCodeName + navigator.appName + navigator.appVersion + navigator.userAgent;
  }

  randomHex() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }

  randomRgba(opacity) {
    let o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + opacity + ')';
  }

  public createUrlParams(params: any) {
    let urlParam: any = '';
    params.map((item: any) => {
      if (item.value) {
        urlParam += '&' + item.name + '=' + item.value;
      }
    });

    if (urlParam) {
      urlParam = '?' + urlParam.substr(1, urlParam.length - 1);
    }

    return urlParam;
  }

  public highlightArr(t: any, k: String[]) {
    let val: any = t;
    if (k && Array.isArray(k) && k.length > 0) {
      k.forEach((el) => {
        val = this.highlight(val, el);
      });
    }
    return val;
  }

  public highlight(t: any, k: any) {
    let key: any = k ? k.trim() : k;
    if (this.isEmpty(t) || this.isEmpty(k)) { return t ? t : ''; }
    if (Array.isArray(t)) { return !this.isEmpty(t[0]) ? t : ''; }
    let text: any = t && Array.isArray(t) ? String(t[0]) : String(t);
    let keyword: any = key.split(' ');
    keyword.forEach((e: any) => {
      let el = e.replace(/[\/\\[\]{}\?!\`~()#%:^\|.,;:]/g, '');
      if (el === '' || el.length === 2 || el.length === 1) { return; }
      let index: number = text.toLowerCase().indexOf(this.getESWithVowel(el));
      if (index > -1) {
        text = this.doHighlight(text, index, this.getESWithVowel(el));
      }
    });
    return text;
  }

  private getESWithVowel(text) {
    let str = text.toLowerCase();
    let i = str.length - 1;
    let vowel = ['а', 'о', 'я', 'и', 'е', 'а', 'ы', 'у', 'ю', 'я', 'й'];
    return vowel.indexOf(str[i]) > -1 && str.substr(0, i).length !== 1 ? str.substr(0, i) : str;
  }

  private doHighlight(text: any, index: number, el: any) {
    let cutText: any = text.slice(index, el.length + index);
    let re: any = new RegExp(cutText, 'gi');
    let newText: any = text.replace(re, `<b>${cutText}</b>`);
    return newText;
  }

  public keys(object: any) {
    let keys: any = [];
    if (object) {
      let arr: any = Object.keys(object);
      arr.forEach((el: any) => {
        keys.push(el.slice(1));
      });
    }
    return keys;
  }

  public objects(object: any) {
    let objects: any = [];
    if (object) {
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          objects.push(object[key]);
        }
      }
    }
    return objects;
  }

  /**
     * Из плоского масива строит древовидный массив
     * @returns object.children
     */
  treeify(list, idAttr?, parentAttr?, childrenAttr?) {
    if (list && list.length) {
      if (!idAttr) { idAttr = 'id'; }
      if (!parentAttr) { parentAttr = 'parentId'; }
      if (!childrenAttr) { childrenAttr = 'children'; }

      let lookup = {};
      let result = {};
      result[childrenAttr] = [];

      list.forEach(function (obj) {
        lookup[obj[idAttr]] = obj;
        obj[childrenAttr] = [];
      });
      list.forEach(function (obj) {
        if (!obj[parentAttr] || obj[parentAttr] === '0') {
          lookup[obj[parentAttr]] = obj;
          obj[childrenAttr] = [];
        } else {
          lookup[0] = obj;
          obj[childrenAttr] = [];
        }
      });

      list.forEach(function (obj) {
        if (obj[parentAttr] && obj[parentAttr] !== '0') {
          lookup[obj[parentAttr]][childrenAttr].push(obj);
        } else {
          result[childrenAttr].push(obj);
        }
      });

      return result;
    } else {
      return list;
    }
  }

  /**
   * Из двевовидного массива строит плоски массив
   * @returns flatArray
   */
  flatten(treeObj, idAttr?, parentAttr?, childrenAttr?, levelAttr?) {
    if (!idAttr) { idAttr = 'id'; }
    if (!parentAttr) { parentAttr = 'parent'; }
    if (!childrenAttr) { childrenAttr = 'children'; }
    if (!levelAttr) { levelAttr = 'level'; }

    function flattenChild(childObj, parentId, level) {
      let array = [];

      let childCopy = Object.assign({}, childObj);
      childCopy[levelAttr] = level;
      childCopy[parentAttr] = parentId;
      delete childCopy[childrenAttr];
      array.push(childCopy);

      array = array.concat(processChildren(childObj, level));

      return array;
    }

    function processChildren(obj, level?) {
      if (!level) { level = 0; }
      let array = [];
      if (obj[childrenAttr] && obj[childrenAttr].length) {
        obj[childrenAttr].forEach(function (childObj) {
          array = array.concat(flattenChild(childObj, obj[idAttr], level + 1));
        });
      }

      return array;
    }

    let result = processChildren(treeObj);
    return result;
  }

  unique(arr) {
    return arr.filter((el, pos, a) => a.indexOf(el) === pos);
  }

  getFBNP(d, p1, p2?, p3?, p4?, p5?) {
    if (p1 && p2 && p3 && p4 && p5) {
      return d && d[p1] && d[p1][p2] && d[p1][p2][p3] && d[p1][p2][p3][p4] && d[p1][p2][p3][p4][p5];
    } else if (p1 && p2 && p3 && p4) {
      return d && d[p1] && d[p1][p2] && d[p1][p2][p3] && d[p1][p2][p3][p4];
    } else if (p1 && p2 && p3) {
      return d && d[p1] && d[p1][p2] && d[p1][p2][p3];
    } else if (p1 && p2) {
      return d && d[p1] && d[p1][p2];
    } else if (p1) {
      return d && d[p1];
    }
  }

  exportDoc(url, format: '.xls' | '.xlsx' | '.pdf' | '.zip' | '.txt', filename?) {
    return this.exportDoc2(url).toPromise()
    .then((res) => {
      let file = new Blob([res], { type: MIME[format] });
      FileSaver.saveAs(file, filename + format);
    })
    .catch((err) => {
      console.error(err);
    })
  }

  private exportDoc2(url) {
    return this.http.get(`${url}`, { responseType: 'arraybuffer' }).pipe(map((res) => {
      return res;
    }));
  }

  arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  hexToBase64(hexstring: string) {
    return btoa(hexstring.match(/\w{2}/g).map(function (a) {
      return String.fromCharCode(parseInt(a, 16));
    }).join(""));
  }

  downloadPdfFromBase64(base64: string, fileName: string = '' + Date.now()) {
    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    downloadLink.remove()
  }

  sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  copyToClipboard(type: string, text: string): void {
    this.win.nativeWindow.navigator.clipboard.writeText(text)
    this.toast.pop('success', `${type} скопирован`)
  }
}
