import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-database',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './database.component.html'
})

export class IconDatabaseComponent {}
