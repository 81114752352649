import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-paperclip',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './paperclip.component.html'
})

export class IconPaperclipComponent {}
