import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-user',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './user.component.html'
})

export class IconUserComponent {}
