import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { tokenNotExpired } from 'angular2-jwt';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.headers.has('No-Intercept')) {
      const headers = req.headers.delete('No-Intercept');
      req = req.clone({ headers });
      return next.handle(req);
    }
    
    const token = JSON.parse(localStorage.getItem('token'));
    let auth = token && token.access_token && tokenNotExpired('access_token', token.access_token) ?
      `Bearer ${token.access_token}` :
      `Basic ${btoa(`web_app:qwerty`)}`;
    let cType = req && req.url === '/oauth/token' || req.url === '/users/resetPassword' ||
      req && req.url === '/users/changePassword' ?
      `application/x-www-form-urlencoded; charset=utf-8` : `application/json`;
    let h = {
      'Authorization': auth,
      'Content-Type': cType
    };
    if (['localhost'].indexOf(location.hostname) > -1) {
      h['localCode'] = 'MTYxODY1OTI4MzgxNg==';
    }
    const headers = new HttpHeaders(h);

    const authReq = req.clone({ headers });
    
    // if login with google
    if(req.url.includes('/oauth/google')) {
      let header = {
        'Content-Type': cType
      }
      const headers = new HttpHeaders(header);
      const auth = req.clone({ headers });
      return next.handle(auth)
    }
    return next.handle(authReq);
  }
}
