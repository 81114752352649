import { Injectable } from '@angular/core';
import { tokenNotExpired } from 'angular2-jwt';
import { Router } from '@angular/router';
import { DataService } from '../../data.service';
import { AppState } from '../../app.service';
import { UtilsService } from './utils.service';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class CabinetService {

  constructor(
    private router: Router,
    private http: HttpClient,
    public utils: UtilsService,
    public data: DataService,
    public appState: AppState
  ) { }

  public getCount() {
    return this.http.get(`/cabinet/count`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public sendReport(data) {
    return this.http.post(`/open-report`, data)
      .pipe(map((res) => {
        return res;
      }));
  }

  public getBanner() {
    return this.http.get(`/banner/getText`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public getPromo() {
    return this.http.get(`/promo`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public checkVersion() {
    return this.http.get(`/assets/js/version.js?v=${new Date().getTime()}`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public setNotifications(data: any) {
    return this.http.post(`/banner/updateMessage`, data)
      .pipe(map((res) => {
        return res;
      }));
  }

  public searchHistory() {
    return this.http.get(`/cabinet/searchHistory`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public chosenCompany(params) {
    let p: string = this.utils.createUrlParams(params);
    return this.http.get(`/cabinet/chosenCompany${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public getInvoiceList(params) {
    let p: string = this.utils.createUrlParams(params);

    return this.http.get(`/payment/invoice-admin${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public createInvoice(data) {
    return this.http.post(`/payment/invoice-admin`, data)
      .pipe(map((res) => {
        return res;
      }));
  }
  public getServices() {
    return this.http.get(`/payment/services`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public createServices(data) {
    return this.http.post(`/payment/services`, data)
      .pipe(map((res) => {
        return res;
      }));
  }
  public deleteServices(id) {
    return this.http.delete(`/payment/services/${id}`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public viewHistory(params) {
    let p: string = this.utils.createUrlParams(params);

    return this.http.get(`/cabinet/viewHistory${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public saleHistory(params) {
    let p: string = this.utils.createUrlParams(params);

    return this.http.get(`/cabinet/saleHistory${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public getResources() {
    return this.http.get(`/api/token/resources`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public getToken() {
    return this.http.get(`/api/token`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public getTokensAll(params) {
    return this.http.post(`/api/token/all`, params)
      .pipe(map((res) => {
        return res;
      }));
  }
  public getTokenStat(params) {
    let p: string = this.utils.createUrlParams(params);
    return this.http.get(`/api/token/stat${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public getTokenByName(name) {
    return this.http.get(`/api/token?name=${name}`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public getStatByName() {
    return this.http.get(`/api/token/user`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public createToken(data) {
    return this.http.post(`/api/token`, data)
      .pipe(map((res) => {
        return res;
      }));
  }
  public editToken(data) {
    return this.http.put(`/api/token/${data.id}`, data)
      .pipe(map((res) => {
        return res;
      }));
  }
  public viewHistoryAdmin(params) {
    let p: string = this.utils.createUrlParams(params);

    return this.http.get(`/cabinet/viewHistory/group${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public viewHistoryAdminByUser(params) {
    let p: string = this.utils.createUrlParams(params);

    return this.http.get(`/cabinet/viewHistory${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }
  public balanceHistory(params) {
    let p: string = this.utils.createUrlParams(params);
    return this.http.get(`/cabinet/balanceHistory${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public getBalance() {
    this.appState.set('balanceLoading', true);
    return this.http.get(`/cabinet/balance`)
      .pipe(map((res) => {
        let user = JSON.parse(localStorage.getItem('user'));
        user.balance = res['balance'];
        localStorage.setItem('user', JSON.stringify(user));
        this.appState.set('balance', res['balance']);
        this.appState.set('balanceLoading', false);
        return res;
      }));
  }

  public setBalance(email: string, amount: number) {
    return this.http.post(`/cabinet/setBalance`, { username: email, balance: amount })
      .pipe(map((res) => {
        return res;
      }));
  }

  public usePromoCode(code: number) {
    return this.http.post(`/cabinet/usePromo`, { password: code })
      .pipe(map((res) => {
        return res;
      }));
  }

  public buyCard(id: number, p: any) {
    let params = this.utils.createUrlParams(p);
    return this.http.post(`/cabinet/buyCompany/${id}${params}`, {})
      .pipe(map((res) => {
        return res;
      }));
  }

  public buyRfCard(inn: number, country: string) {
    return this.http.post(`/cabinet/buyLegatCompany/${country}/${inn}`, {})
      .pipe(map((res) => {
        return res;
      }));
  }

  public getStatus(idn: number) {
    return this.http.get(`/cabinet/status/${idn}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public getRfStatus(inn: number, c?) {
    return this.http.get(`/cabinet/ruStatus/${inn}${c ? '?country=' + c : ''}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public getStatusFavorites(id: number, c?) {
    return this.http.get(`/cabinet/chosenCompany/${id}${c ? '?country=' + c : ''}`)
      .pipe(map((res) => {
        this.appState.set('statusFavorites', { [`id${id}`]: res });
        return res;
      }));
  }
  public addFavorites(data) {
    return this.http.post(`/cabinet/chosenCompany`, data)
      .pipe(map((res) => {
        if (data && data.companyId) {
          this.appState.set('statusFavorites', { [`id${data.companyId}`]: { status: true } });
        }
        this.appState.set('favCount', (this.appState.get('favCount') || 0) + 1);
        return res;
      }));
  }
  public deleteFavorites(id: number) {
    return this.http.delete(`/cabinet/chosenCompany/${id}`)
      .pipe(map((res) => {
        this.appState.set('statusFavorites', { [`id${id}`]: { status: false } });
        this.appState.set('favCount', (this.appState.get('favCount') || 1) - 1);
        return res;
      }));
  }

  public changePassword(newPassword: string) {
    return this.http.post(`/cabinet/changePassword`, { password: newPassword })
      .pipe(map((res) => {
        return res;
      }));
  }

  public sendEmail(id: number, email: string) {
    return this.http.post(`/cabinet/sendEmail/${id}?email=${email}`, {})
      .pipe(map((res) => {
        return res;
      }));
  }

  public getUsers(params) {
    let p: string = this.utils.createUrlParams(params);
    return this.http.get(`/users${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public getApiStatistics(params) {
    let p: string = this.utils.createUrlParams(params);
    return this.http.get(`/users/api-stats${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public getSales(params) {
    let p: string = this.utils.createUrlParams(params);
    return this.http.get(`/cabinet/sales${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public enableUsers(id: number) {
    return this.http.post(`/users/${id}/enable`, {})
      .pipe(map((res) => {
        return res;
      }));
  }

  public checkBindedCompanies(userId: number) {
    return this.http.get(`/users/company?userId=${userId}`)
  }

  public bindCompany(
    data: {
      user_id: number,
      identifier: number | string,
      name: string,
      comment?: string,
    }
  ) {
    return this.http.post(`/users/company`, data)
  }

  public bindUserstoCompanies(
    data: {
      user_ids: number[],
      identifier: number | string,
      name: string,
      comment?: string,
    }
  ) {
    return this.http.post(`/users/company/bulk`, data)
  }

  public unbindCompany(userId: number) {
    return this.http.delete(`/users/company?userId=${userId}`)
  }
  
  public setIINHeadToCompany(data: any) {
    return this.http.post(`/company/setIINHead`, data)
      .pipe(map((res) => {
        return res;
      }));
  }

  public getPromoCode(params) {
    let p: string = this.utils.createUrlParams(params);
    return this.http.get(`/cabinet/promos${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public generatePromoCode() {
    return this.http.post(`/cabinet/generatePromo`, {})
      .pipe(map((res) => {
        return res;
      }));
  }

  public getPaymentXml(amount) {
    return this.http.post(`/payment`, { balance: amount });
  }

  public sendSms(phone) {
    return this.http.post(`/payment/wooppay/sendSms`, { phone: phone });
  }

  public payWoopay(data) {
    return this.http.post(`/payment/wooppay`, data);
  }

  public updateUser(obj) {
    return this.http.put(`/users`, obj)
      .pipe(map((res) => {
        return res;
      }));
  }
  public resetPassword(obj) {
    return this.http.post(`/cabinet/changePasswordAdmin`, obj)
      .pipe(map((res) => {
        return res;
      }));
  }

  public uploadEdoc(data) {
    return this.http.post(`/api2/edoc_upload`, data).pipe(map((res) => {
      return res;
    }));
  }

  public getFounders(params) {
    let p: string = this.utils.createUrlParams(params);
    return this.http.get(`/cabinet/founders${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public getGraph(params) {
    let p: string = this.utils.createUrlParams(params);
    return this.http.get(`/cabinet/graph${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public getHistory(params) {
    let p: string = this.utils.createUrlParams(params);
    return this.http.get(`/cabinet/history${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public getHistoryDetail(params) {
    let p: string = this.utils.createUrlParams(params);
    return this.http.get(`/cabinet/history-detail${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public blockDomain(data) {
    return this.http.post(`/cabinet/banned-domains`, data).pipe(map((res) => {
      return res;
    }));
  }

  public updateNotifications(data) {
    return this.http.put(`/cabinet/notifications`, data).pipe(map((res) => {
      return res;
    }));
  }

  public getNotifications() {
    return this.http.get(`/cabinet/notifications`).pipe(map((res) => {
      return res;
    }));
  }

  public deleteDomain(id) {
    return this.http.delete(`/cabinet/banned-domains/${id}`).pipe(map((res) => {
      return res;
    }));
  }

  public getDomains(params) {
    let p: string = this.utils.createUrlParams(params);
    return this.http.get(`/cabinet/banned-domains${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public getNotes(p?) {
    let params = p ? this.utils.createUrlParams(p) : '';
    return this.http.get(`cabinet/notes${params}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public deleteNotes(id) {
    return this.http.delete(`cabinet/notes/${id}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public sOuNotes(data) {
    return this.http[data && data.id ? 'put' : 'post'](`cabinet/notes`, data)
      .pipe(map((res) => {
        return res;
      }));
  }

  public getAccessRightAll() {
    return this.http.get(`accessRight/all`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public getAccessRight(id) {
    return this.http.get(`accessRight/user/${id}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public saveAccessRights(id, data) {
    return this.http.post(`accessRight/user/${id}`, data)
      .pipe(map((res) => {
        return res;
      }));
  }

  public saveGroupAccessRights(data) {
    return this.http.post(`accessRight/user/group`, data)
      .pipe(map((res) => {
        return res;
      }));
  }

  public getBindUserList(name) {
    return this.http.get(`users/bindUserList?userName=${name}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public unBindUser(name) {
    return this.http.put(`users/unBind?userName=${name}`, {})
      .pipe(map((res) => {
        return res;
      }));
  }

  public bindUser(parentUser, childUser) {
    return this.http.post(`users/bindUser?parentUser=${parentUser}&childUser=${childUser}`, {})
      .pipe(map((res) => {
        return res;
      }));
  }

  public getReviews(params) {
    let p = this.utils.createUrlParams(params);
    return this.http.get(`/cabinet/reviews${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public reviewsCount(params) {
    let p = this.utils.createUrlParams(params);
    return this.http.get(`/cabinet/reviewsCount${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public getReviewsAdmin(params) {
    let p = this.utils.createUrlParams(params);
    return this.http.get(`/cabinet/admin/reviews${p}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public setReviewsAdmin(id: number, status: boolean) {
    return this.http.put(`/cabinet/reviews/accept/${id}?status=${status}`, {})
      .pipe(map((res) => {
        return res;
      }));
  }

  public getInfo() {
    return this.http.get(`/users/getInfo`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public saveUsers(data) {
    return this.http.put(`/users/editInfo`, data)
      .pipe(map((res) => {
        return res;
      }));
  }

}
