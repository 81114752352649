import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-clocko',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './clocko.component.html'
})

export class IconClockoComponent {}
