import { Component } from '@angular/core';

@Component({
    selector: 'icon-yandex',
    template: `<svg class="icon__svg" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9837 2.62372C5.22306 3.30768 4.8124 4.29388 4.8124 5.52308C4.8124 6.51522 5.03976 7.27931 5.4559 7.94441C5.87848 8.61983 6.51357 9.22197 7.37642 9.85937C7.58918 10.0165 7.64272 10.3122 7.49859 10.534L4.92124 14.5H6.27123L9.28394 9.82645L8.49041 9.2725C8.49019 9.27235 8.48997 9.27219 8.48975 9.27204C7.75818 8.76501 7.15692 8.27487 6.74701 7.66006C6.32571 7.02819 6.13306 6.3068 6.13306 5.38462C6.13306 4.52015 6.43005 3.75086 7.04972 3.20397C7.66185 2.66373 8.5298 2.39231 9.57851 2.39231H10.6347C10.9109 2.39231 11.1347 2.61617 11.1347 2.89231V14.5H12.4995L12.4857 1.5H9.56364C7.93082 1.5 6.75033 1.93437 5.9837 2.62372ZM5.31506 1.88013C6.31496 0.981018 7.76009 0.5 9.56364 0.5H12.9851C13.2611 0.5 13.4848 0.723534 13.4851 0.999469L13.5 14.9995C13.5001 15.1322 13.4475 15.2595 13.3537 15.3534C13.26 15.4472 13.1327 15.5 13 15.5H10.6347C10.3586 15.5 10.1347 15.2761 10.1347 15V10.3523L6.96405 15.2709C6.87199 15.4137 6.71371 15.5 6.5438 15.5H4C3.81665 15.5 3.64801 15.3997 3.56055 15.2385C3.47309 15.0774 3.48085 14.8813 3.58075 14.7275L6.40733 10.378C5.66796 9.79561 5.05324 9.18619 4.60815 8.47482C4.08338 7.63608 3.8124 6.68478 3.8124 5.52308C3.8124 4.05996 4.30917 2.78463 5.31506 1.88013ZM10.1347 9.2008V3.39231H9.57851C8.69334 3.39231 8.08857 3.62089 7.71143 3.95373C7.34185 4.27991 7.13306 4.75678 7.13306 5.38462C7.13306 6.15474 7.28999 6.67181 7.57903 7.10532C7.87932 7.55571 8.3489 7.95777 9.06001 8.45058L9.06141 8.45155L10.1347 9.2008Z" fill="black"/>
    </svg>
    `
})
export class IconYandexComponent { }
