import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-filter',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './filter.component.html'
})

export class IconFilterComponent {}
