import { Injectable } from '@angular/core';
import { DEV_HOSTS, PROD_HOSTS } from '../../app.constants';

@Injectable()
export class ModeService {

    constructor(
    ) { }

    isDev() {
        return DEV_HOSTS.indexOf(location.hostname) > -1;
    }

    isProd() {
        return PROD_HOSTS.indexOf(location.hostname) > -1;
    }

}
