import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-owner-hierarchy',
  templateUrl: './owner-hierarchy.component.html',
  styleUrls: ['./owner-hierarchy.component.scss']
})
export class OwnerHierarchyComponent implements OnInit {

  @Input() data: any[];
  @Input() isFirstNode: boolean;
  @Input() identifier: number

  constructor() { }

  ngOnInit() {
  }
  
  // ngOnChanges(changes: SimpleChanges): void {
  //   if (this.isFirstNode) {
  //     this.sortDatas()
  //   }
  // }

  sortDatas() {
    for (let index = 0; index < this.data.length; index++) {
      if (this.data[index].identifier == this.identifier) {
        const temp = this.data[index]
        this.data[index] = this.data[0]
        this.data[0] = temp
      }
    }
  }

  onExpand(node: any) {
    node.isExpand = !node.isExpand;
  }
}
