import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

type Key = 'user';

interface Model {
  user: any;
}

interface ModelObs {
  user: Subject<any>;
}

@Injectable()
export class DataService {
  private model: Model = {
    user: {}
  };
  private modelObs: ModelObs = {
    user: new Subject<any>()
  };

  public setData(key: Key, data: any) {
    this.modelObs[key].next(data);
    for (let k in data) {
      if (data.hasOwnProperty(k)) {
        this.model[key][k] = data[k];
      }
    }
  }
  public clearPrivateData() {
    this.model.user = {};
  }

  public getData(key: Key) {
    return this.model[key];
  }
  public getDataObs(key: Key): Observable<any> {
    return this.modelObs[key].asObservable();
  }
}
