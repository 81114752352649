import {
  Component,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { Sub } from '../../../modules/card/card.service';
import { CabinetService } from '../../services/cabinet.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html'
})
export class NotesComponent implements OnChanges, OnDestroy {
  @Input() public country: string;
  @Input() public companyId: number;
  @Input() public companyName: string;
  @Input() public iin: string;
  @Input() public ip: boolean;
  
  @Input() 
  public set visibility(value: boolean) {
    this.model.visibleNote = value
  }

  sub: Sub = {};
  model: any = {};

  constructor(private cabinetService: CabinetService, private toast: ToasterService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['companyId'] && changes['companyId'].currentValue) {
      this.getNotes();
    }
  }

  toggleNote() {
    if (!(this.model.notes && this.model.notes.content && this.model.notes.content.length)) {
      this.model.showText = true;
    }
    this.model.visibleNote = !this.model.visibleNote;
  }

  setEdit(el) {
    this.model.showText = true;
    this.model = { ...this.model, ...el };
  }

  getNotes() {
    if (this.companyId) {
      if (this.sub.getNote) {
        this.sub.getNote.unsubscribe();
      }
      let p = [];
      p.push({ name: 'companyId', value: this.companyId });
      p.push({ name: 'size', value: 1000 });
      this.sub.getNote = this.cabinetService.getNotes(p).subscribe((res: any) => {
        if(res.content && !res.content.length) {
          this.model.showText = true
        }
        this.model.notes = res;
      }, (err) => {
      });
    }
  }

  addNote() {
    this.model.showText = true
    setTimeout(() => {
      this.model.visibleNote = true
    }, 100);
  }
  
  deleteNotes(id) {
    this.sub.deleteNotes = this.cabinetService.deleteNotes(id).subscribe((res: any) => {
      this.getNotes();
    }, (err) => {
    });
  }

  saveNotes() {
    this.sub.saveNotes = this.cabinetService.sOuNotes(this.model.id ? {
      id: this.model.id,
      ip: this.model.ip,
      iin: this.model.iin,
      country: this.model.country,
      note: this.model.note,
      companyId: this.model.companyId,
      companyName: this.model.companyName
    } : {
      ip: this.ip,
      iin: this.iin,
      country: this.country,
      note: this.model.note,
      companyId: this.companyId,
      companyName: this.companyName
    }).subscribe((res: any) => {
      this.model = {};
      this.model.visibleNote = false;
      this.model.showText = false;
      this.getNotes();
      this.toast.pop('success', 'Заметки добавлены');
    }, (err) => {
      this.model.showText = false;
      this.toast.pop('error', 'Техническая ошибка');
    });
  }

  ngOnDestroy() {
    for (const key in this.sub) {
      if (this.sub.hasOwnProperty(key)) {
        this.sub[key].unsubscribe();
      }
    }
  }
}
