import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-collapse',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './collapse.component.html'
})

export class IconCollapseComponent {}
