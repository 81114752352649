import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild, Router } from '@angular/router';

@Injectable()
export class TempPermissionGuard implements CanActivateChild {

    constructor(
        private router: Router
    ) { }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const userData = JSON.parse(localStorage.getItem('user'))
        const permissions = ['kompraservice@gmail.com', 'vyacheslav.kurlykin@telecom.kz', 'z.ismagulov@telecom.kz', 'nurlanknt@gmail.com', 'nurkanatkarrr@mail.ru', 'katyaabay@gmail.com', 'tima21032000@gmail.com', 'stoktasyn@gmail.com', 'm3etis@gmail.com', 'makadil.abdygali@gmail.com', 'khassenov_d@railways.kz']
        if (userData && permissions.includes(userData.user_name)) {
            return true;
        }
        this.router.navigateByUrl('/')
        return false;
    }

}
