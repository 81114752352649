import {
  Component,
  OnInit,
  EventEmitter,
  ViewChildren,
  HostListener,
  OnDestroy, ViewChild, ElementRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SearchService } from '../shared/services/search.service';
import { UtilsService } from '../shared/services/utils.service';
import { AuthRegPopupService } from '../shared/components/auth-reg-popup/auth-reg-popup.service';
import { AuthService } from '../shared/services/auth.service';
import { AppState } from '../app.service';
import { WindowRef } from '../shared/services/window.ref';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as moment from 'moment';
import { GtagService } from '../shared/services/gtag.service';
import { SubscribeService } from '../shared/services/subscribe.service';
import { ToasterService } from 'angular2-toaster';
import { BehaviorSubjectService } from '../shared/services/behavior-subject.service';

@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
  animations: [
    trigger('sliderVisibility', [
      state('hide', style({
        transition: 'opacity .3s ease-out',
        opacity: 0
      })),
      state('show', style({
        transition: 'opacity .3s ease-out',
        opacity: 1
      })),
      transition('hide => show', animate('300ms ease-in')),
    ]),
  ]
})
export class PromoComponent implements OnInit, OnDestroy {
  posts = [];
  @ViewChildren('input') public input;
  counts: any;
  public focusSet = new EventEmitter<boolean>();
  public activeEl: any = -2;
  public model: any = {
    data: '',
    keyword: '',
    result: [],
    showList: false,
    doSearching: true,
    loading: false,
    help: false,
    selected: ''
  };
  showModal: boolean;
  questions: string[];
  question: string;
  bonus: string;
  checks: string;
  referer: any;
  slider: any = {};
  clientsText: string
  btnText: string = "Отправить"
  data: any = {}
  @ViewChild('aboutService') aboutService: ElementRef

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    public subsribeService: SubscribeService,
    public searchService: SearchService,
    public authService: AuthService,
    public appState: AppState,
    public winRef: WindowRef,
    public authRegPopupService: AuthRegPopupService,
    public utils: UtilsService,
    public toast: ToasterService,
    public gtagService: GtagService,
    public subjectService: BehaviorSubjectService
  ) {
    this.questions = [
      'Как проверить контрагента на благонадежность?',
      'Как найти и выбрать поставщика?',
      'С кем связан мой контрагент?',
      'Как автоматизировать проверку контрагентов?',
      'Как проверить судебные дела компании?',
      'Контрагент участвовал в судебных делах?',
      'Мой поставщик добросовестный?',
      'Мой контрагент в реестре должников?',
      'Что если мой контрагент — лжепредприятие?',
      'Где посмотреть финансовую отчетность компании?',
      'Юридическое лицо ликвидировано?',
      'Кто руководитель компании? Кто учредитель компании?'
    ];
    this.randQuestion();
    this.showModal = false;
    this.bonus = this.authService.getBonus();
    this.checks = '2';
    this.referer = null;

    this.slider['list'] = [
      {
        info: '18 апреля, 2023',
        text: 'Банкротство физлиц в Казахстане. Советы экспертов. Видео.',
        url: 'https://youtu.be/IPZfZ9xWRDk',
      },
      {
        info: 'Январь 11, 2023',
        text: 'Ежегодная традиция Kompra – поддержка благотворительности!',
        url: 'https://blog.kompra.kz/%D0%B1%D0%BB%D0%B0%D0%B3%D0%BE%D1%82%D0%B2%D0%BE%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F_%D0%B0%D0%BA%D1%86%D0%B8%D1%8F_2023/',
      },
      {
        info: 'Декабрь, 2022',
        text: 'Голосование: Каким был 2022 год для специалистов СБ и комплаенс?',
        url: 'https://blog.kompra.kz/%D0%B3%D0%BE%D0%BB%D0%BE%D1%81%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D0%BA%D0%B0%D0%BA%D0%B8%D0%BC-%D0%B1%D1%8B%D0%BB-2022-%D0%B3%D0%BE%D0%B4-%D0%B4%D0%BB%D1%8F-%D1%81%D0%BF%D0%B5%D1%86%D0%B8%D0%B0/',
      },
      {
        info: 'Ноябрь 24, 2022',
        text: 'Как проверить ИП в Казахстане на надежность?',
        url: 'https://blog.kompra.kz/как-проверить-ип-в-казахстане-на-надеж/',
      },
      {
        info: 'Ноябрь 11, 2022',
        text: 'Новый инструмент: Внутренняя проверка',
        url: 'https://blog.kompra.kz/%D0%B2%D0%BD%D1%83%D1%82%D1%80%D0%B5%D0%BD%D0%BD%D1%8F%D1%8F-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D1%80%D0%BA%D0%B0/',
      },
      {
        info: 'Март 24, 2022',
        text: 'Проверяйте наличие контрагента в санкционных списках',
        url: 'https://blog.kompra.kz/sanctions/',
      },
      {
        info: 'Телеграм-канал',
        text: 'Больше мероприятий и новостей о безопасности на нашем канале',
        url: 'https://t.me/sb_trends',
      }
    ]

    this.slider['active'] = 0;
    this.slider['visibility'] = 'show';

    this.utils.setTitle();
    this.utils.setTag();
  }

  ngOnInit() {
    this.startSlider()
    this.checkBonuses()
    this.getPromo()
    this.getBlogInfo()
    this.getUserInfo()
  }

  startSlider() {
    this.slider.count = 1;
    this.slider.interval = setInterval(() => {
      this.setSlider(this.slider.count);
      this.slider.count++;
      if (this.slider.count === this.slider.list.length) {
        this.slider.count = 0;
      }
    }, 8000);
  }

  checkBonuses() {
    this.route.queryParams.subscribe(params => {
      if (params.promo) {
        this.authService.setReferer(params.promo);
        this.bonus = this.authService.getBonus();
        // if (params.promo === '2ndanniversary') {
        //     self.checks = '4';
        //     self.referer = '2ndanniversary';
        // }
      }
    });
  }

  getPromo() {
    this.http.get(`/promo`).subscribe((res: any) => {
      this.counts = res;
      this.clientsText = this.clientsDeclination(res.userCount)
    });
  }

  getUserInfo() {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user) {
      const { user_name, phone } = user
      if(user_name) {
        this.data.email = user_name
      }
      if(phone) {
        this.data.phone = phone
      }
    }
  }

  getBlogInfo() {
    // http://blog.kompra.kz/wp-json/wp/v2/posts
    this.http.get('/wp2/v2/posts?per_page=4').subscribe(data => {
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          this.posts.push(data[key]);
        }
      }
    });
  }

  fillBalance() {
    if(this.authService.authorized) {
      this.router.navigateByUrl('/mycabinet/payment')
      return
    }
    sessionStorage.setItem('blackfridaylogin', 'true')
    this.authRegPopupService.open()
  }

  public clientsDeclination(userCount: number): string {
    const clients: string = userCount ? String(userCount) : "56393"
    const lastDigit: string = clients.split('')[clients.length - 1]
    switch(lastDigit) {
      case "1":
        return "клиент"
      case "2":
      case "3":
      case "4":
        return "клиента"
      default:
        return "клиентов"
    }
  }

  ngOnDestroy() {
    clearInterval(this.slider.interval);
  }

  readLocalStorageValue(arg0: string): any {
    throw new Error('Method not implemented.');
  }

  randQuestion() {
    this.question = this.questions[parseInt(Math.random() * (this.questions.length - 0) + '', 10) + 0];
  }

  @HostListener('keyup', ['$event'])
  public onChange(e: KeyboardEvent) {
    // up
    if (e.keyCode === 38) {
      if (this.activeEl > -1) {
        this.activeEl -= 1;
      } else {
        this.activeEl = this.model.result.length - 1;
      }
      return;
    }
    // down
    if (e.keyCode === 40) {
      if (this.activeEl < this.model.result.length - 1) {
        this.activeEl += 1;
      } else {
        this.activeEl = -1;
      }
      return;
    }
  }

  public doSearch() {
    this.model.data = this.cutSymbols(this.model.data);
    let data: any = this.model.data ? this.model.data.trim() : this.model.data;
    this.activeEl = -2;
    if (this.model.doSearching) {
      this.model.showList = true;
      if (data && data.length >= 3) {
        if (this.utils.isUIN(data) && this.utils.getUinType(data) === 'iin') {
          let obj = { code: 'UZB' };
          obj['filter'] = { name: data };
          this.searched(obj);
          this.searchedFl(data);
        } else if (this.utils.isUIN(data) && this.utils.getUinType(data) === 'bin') {
          let obj = { code: 'UZB' };
          obj['filter'] = { name: data };
          this.searched(obj);
        } else {
          let obj = { code: 'UZB' };
          obj['filter'] = { name: data };
          this.searched(obj);
        }
      } else {
        this.model.result = [];
        this.model.showList = false;
      }
    } else {
      this.model.doSearching = true;
    }
  }

  public searched(params: any) {
    this.model.loading = true;
    this.searchService.search(params).subscribe((res) => {
      this.model.loading = false;
      if (this.model.doSearching) {
        this.model.result = this.model.result.concat(res && res['content']);
      } else {
        this.model.doSearching = true;
      }
    }, (err) => {
      this.model.loading = false;
      this.model.result = [];
      console.log(err);
    });
  }

  public searchedFl(iin: any) {
    this.searchService.searchFl(iin).subscribe((res) => {
      this.model.result.push(res);
    });
  }

  public clearAll() {
    this.model.data = '';
    this.model.result = [];
    this.model.showList = false;
    this.model.doSearching = false;
    this.router.navigateByUrl(`/search`);
  }

  public getIndexOf(el: any) {
    return typeof el === 'string' ? el.trim().toLowerCase().indexOf(this.model.data.trim().toLowerCase()) > -1 : false;
  }

  public cutSymbols(str: string) {
    if (!this.utils.isEmpty(str)) {
      return str.replace(/[\/\\[\]{}\?!\`~()#%:^\|]/g, '');
    }
    return str;
  }

  public choiceSearch() {
    this.model.data = this.cutSymbols(this.model.data);
    if (this.activeEl > -1) {
      this.goToCard(this.model.result[this.activeEl]);
    } else {
      this.goToSearch();
    }
  }

  public goToSearch() {
    if (this.model.data) {
      this.model.result = [];
      this.router.navigateByUrl(`/search?q=${this.model.data}`);
      this.model.showList = false;
      this.model.doSearching = false;
    }
  }

  public goToCard(el: any) {
    this.router.navigateByUrl(`/country/uzb/${el.company_number}/report`);
  }

  setSlider(i) {
    this.slider.count = i;
    this.slider.visibility = 'hide';
    let self = this;
    this.slider.timeout = setTimeout(function () {
      self.slider.visibility = 'show';
      self.slider.active = i;
    }, 300);
  }

  getXml(user) {
    if (user && user.iin) {
      this.model.xmlData = `<?xml version="1.0" encoding="utf-8"?>
      <request1>
       <bin>170940011140</bin>
       <corporate>
        <companyname>KOMPRA, ТОО</companyname>
        <address>Г.НУР-СУЛТАН, РАЙОН ЕСИЛЬ, ПРОСПЕКТ МӘҢГІЛІК ЕЛ, 55/8, ОФИС ПАВИЛЬОН С4.6.</address>
        <phone>+77084256319</phone>
       </corporate>
       <date>2021-09-20</date>
      </request1>`
    }
  }

  signXml(xml) {
    console.log(xml);
  }

  showAmoForms() {
    this.winRef.nativeWindow.AMO_FORM_VISIBILITY();
  }

  diffDate(date) {
    let f = moment(date).set({ h: 0, m: 0, s: 0, ms: 0 });
    let c = moment().set({ h: 0, m: 0, s: 0, ms: 0 });
    let r = moment.duration(f.diff(c));
    let d = r.days();
    return d > 0 ? d + ' ' + this.utils.plural(d, 'день', 'дня', 'дней') : d === 0 ? 'Сегодня' : 'Прошло';
  }

  submit_form() {
    if (this.data.email && this.data.phone) {
      this.btnText = "Отправка..."
      let obj = {
        phone: this.data.phone,
        email: this.data.email,
        name: this.data.name,
        type: 'CONSULTATION',
        description: 'Получить консультацию',
      };
      this.subsribeService.request_subscription(obj).subscribe((res: any) => {
        this.btnText = "Отправить"
        this.data = {};
        this.data.success = true;
        this.data.modal = false;
      }, (err) => {
        this.toast.pop('error', 'Техническая ошибка');
        this.btnText = "Отправить"
      });
    }
  }
  scrollToSpecificDiv() {
    this.aboutService.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
