import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-caretleft',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './caretleft.component.html'
})

export class IconCaretleftComponent {}
