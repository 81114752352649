import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-telegram-banner',
    template: '<div id="telegram-banner"></div>',
    styles: [`
        #telegram-banner {
            margin: 0 auto;
            z-index: 100;
            cursor: pointer;
        }
        @media(min-width: 761px) {
            #telegram-banner {
                height: 90px;
                background: url('/assets/img/Banner_telegram_desktop.png') no-repeat;
            }
        }
        @media(max-width: 760px) {
            #telegram-banner {
                object-fit: contain;
                height: 110px;
                margin: 0 auto;
                background: url('/assets/img/Banner_telegram_mobile.png') no-repeat;
                background-size: cover; 
            }
        }
        @media (max-width: 1279px) {
            #telegram-banner {
                height: 125px !important;
            }
        }
`]
})
export class TelegramBannerComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
