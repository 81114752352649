import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class PhotoService {

  constructor(private http: HttpClient) { }

  public getFaces(image: File) {
    const formData = new FormData();
    formData.append('image', image);

    const token = JSON.parse(localStorage.getItem('token'))

    return this.http.post<any>(`/web-api-gateway/photo_check/get_faces`, formData, {
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({ "Accept": "*/*", 'No-Intercept': 'true', 'Authorization': `Bearer ${token.access_token}` })
    })
  }

  public getLinks(base64: string) {
    return this.http.post(`/web-api-gateway/photo_check/get_links`, { image_base_64: base64 }, {
      reportProgress: true,
      observe: 'events'
    })
  }
}
