import { Injectable } from '@angular/core';
import { Gtag } from 'angular-gtag';

@Injectable()
export class GtagService {

  constructor(private gtag: Gtag) { }

  gtag_report_conversion() {
    this.gtag.event('conversion', {
        'send_to': 'AW-824421500/Q0BnCOG064IYEPzYjokD',
        'event_callback': () => {}
    });
    return false;
  }

  gtag_report_registration() {
    this.gtag.event('registration', {
        'send_to': 'AW-824421500/Q0BnCOG064IYEPzYjokD',
        'event_callback': () => {}
    });
    return false;
  }
}
