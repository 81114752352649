import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-chevrondown',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './chevrondown.component.html'
})

export class IconChevrondownComponent {}
