import {BrowserModule} from '@angular/platform-browser';
import {NgModule, LOCALE_ID} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, PreloadAllModules} from '@angular/router';
import {SharedModule} from './shared/shared.module';
import {ROUTES} from './app.routes';
import {AppComponent} from './app.component';
import {PromoComponent} from './pages/promo.component';
import {ToolsComponent} from './pages/tools.component';
import {ContactsComponent} from './pages/contacts.component';
import {ConfirmComponent} from './pages/confirm.component';
import {ConfirmService} from './confirm.service';
import {TariffComponent} from './pages/tariff.component';
import {NotFoundComponent} from './pages/404.component';
import {RestoreComponent} from './pages/restore.component';
import {PublicofferComponent} from './pages/publicoffer.component';
import {RegisterComponent} from './pages/register.component';
import {AppState} from './app.service';
import {DataService} from './data.service';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import '../assets/scss/app.scss';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import { GtagModule } from 'angular-gtag';
import { BlackFridayComponent } from './shared/components/black-friday/black-friday.component';
import { OffshoresService } from './modules/offshores/services/offshores.service';
import { QuestionsBannerComponent } from './shared/components/questions-banner/questions-banner.component';
import { TelegramBannerComponent } from './shared/components/telegram-banner/telegram.banner';
import { CarBannerComponent } from './shared/components/test-car-banner/test-car.banner';

registerLocaleData(localeRu, localeRuExtra);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './../assets/i18n/', '.json' ); // ?v=' + new Date().getTime()
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    PromoComponent,
    ToolsComponent,
    ContactsComponent,
    ConfirmComponent,
    TariffComponent,
    NotFoundComponent,
    RestoreComponent,
    RegisterComponent,
    PublicofferComponent,
    BlackFridayComponent,
    QuestionsBannerComponent,
    TelegramBannerComponent,
    CarBannerComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES, {useHash: false, preloadingStrategy: PreloadAllModules}),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    GtagModule.forRoot({ trackingId: 'AW-824421500', trackPageviews: true })
    // ServiceWorkerModule.register('ngsw-worker.js?v=' + new Date().getTime(), { enabled: environment.production })
  ],
  providers: [
    AppState,
    ConfirmService,
    DataService,
    TranslateModule,
    OffshoresService,
    { provide: LOCALE_ID, useValue: 'ru-RU' }
  ]
})
export class AppModule {
  constructor() {
  }
}
