import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TariffService } from './modules/admin/components/users/access-tab/services/tariff.service';

export type InternalStateType = {
  [key: string]: any
};

@Injectable()
export class AppState {

  public readonly cardCost: number = 500
  public readonly cardCostRf: number = 1000
  
  constructor(
    private http: HttpClient,
    private tariffService: TariffService, 
  ) { }

  public _state: InternalStateType = {};

  // already return a clone of the current state
  public get state() {
    return this._state = this._clone(this._state);
  }
  // never allow mutation
  public set state(value) {
    throw new Error('do not mutate the `.state` directly');
  }

  public get(prop?: any) {
    // use our state getter for the clone
    const state = this.state;
    return state.hasOwnProperty(prop) ? state[prop] : state;
  }

  public set(prop: string, value: any) {
    // internally mutate our state
    return this._state[prop] = value;
  }

  private _clone(object: InternalStateType) {
    // simple object clone
    return JSON.parse(JSON.stringify(object));
  }

  // tempPermission() {
  //   const userData = JSON.parse(localStorage.getItem('user'))
  //   const permissions = ['kompraservice@gmail.com', 'vyacheslav.kurlykin@telecom.kz', 'z.ismagulov@telecom.kz', 'nurlanknt@gmail.com', 'nurkanatkarrr@mail.ru', 'katyaabay@gmail.com', 'tima21032000@gmail.com', 'stoktasyn@gmail.com', 'm3etis@gmail.com', 'makadil.abdygali@gmail.com', 'khassenov_d@railways.kz']
  //   if (userData && permissions.includes(userData.user_name)) {
  //     this.set('showInternalChecking', true);
  //   }
  // }
  
  notifier(type: 'error' | 'success' | 'info', text: string, time: number): void {
    if (!this._state.isNotifyFirst) {
      this.set('notifierText', text);
      this.set('notifierType', 'notifier--' + text);
      this.set('notifyVisibility', true);
      let self = this;
      setTimeout(function () {
        self.set('notifyVisibility', false);
        self.set('isNotifyFirst', false);
      }, 1000 * time);
      this.set('isNotifyFirst', true);
    }
  }

  agree() {
    return this.http.post('/cabinet/termsAgree', { status: true }).pipe(map(res => {
      return res;
    }));
  }

  fromMP() {
    const token = JSON.parse(localStorage.getItem('token'))
    if(!token || !token.src) {
      return false
    }
    if(token && token.src === 'MP') {
      return true
    }
  }

  getCardCost(type?: string) {
    if(type === 'rf') {
      if(this.fromMP()) {
        return this.cardCostRf - (this.cardCostRf * 0.10)
      }
      return this.cardCostRf
    }
    if(this.fromMP()) {
      return this.cardCost - (this.cardCost * 0.10)
    }
    return this.cardCost
  }

  updateCurrentTariff(accessRights?) {
    let accesess = accessRights
    if(!accessRights) {
      accesess = JSON.parse(localStorage.getItem('accessRight'))
    } 
    if(accesess) {      
      switch (this.tariffService.checkTariff(accesess)) {
        case 'KompraLight20':
          return this.set('currentTariff', 'Kompra Light 20')
        case 'KompraLight50':
          return this.set('currentTariff', 'Kompra Light 50')
        case 'Unlimited':
          return this.set('currentTariff', 'Безлимит')
        case 'Premium':
          return this.set('currentTariff', 'Премиум')
        case 'Test':
          return this.set('currentTariff', 'Тестовый')
        default:
          return this.set('currentTariff', 'Баланс')
      }
    }
    this.set('currentTariff', 'Баланс')
  }
}
