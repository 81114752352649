import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-search-minus',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './search-minus.component.html'
})

export class IconSearchMinusComponent {}
