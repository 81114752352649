import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-kompra',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './kompra.component.html'
})

export class IconKompraComponent {}
