import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UtilsService } from '../../shared/services/utils.service';

interface Params {
  name: string;
  value: string | number;
}

@Injectable()
export class SchemeService {

  constructor(
    private http: HttpClient,
    private utils: UtilsService
    ) { }

  public getStatus(id: number) {
    return this.http.get(`/node/status?id=${id}`).pipe(map((res) => {
      return res;
    }));
  }

  public getCardStatus(idn: number) {
    return this.http.get(`/cabinet/status/${idn}`)
      .pipe(map((res) => {
        return res;
      }));
  }

  public getById(id: number) {
    return this.http.get(`/open-company/${id}`).pipe(map((res) => {
      return res;
    }));
  }

  public getNode(id: number) {
    return this.http.get(`/node?id=${id}`).pipe(map((res) => {
      return res;
    }));
  }

  public getNextNode(id: number) {
    return this.http.get(`/node/single?id=${id}`).pipe(map((res) => {
      return res;
    }));
  }

  public getLevel(id: number, level: number) {
    return this.http.get(`/node?id=${id}&depth=${level}`).pipe(map((res) => {
      return res;
    }));
  }

  public getMockLevel(level: number) {
    return this.http.get(`../../../assets/mock/kazimpex_l${level}.json`).pipe(map((res) => {
      return res;
    }));
  }

  public getOwner(name: string) {
    return this.http.get(`/owner?name=${name}`).pipe(map((res) => {
      return res;
    }));
  }

  public getFoundersCompany(bin: any) {
    return this.http.get(`/founders/company/${bin}`).pipe(map((res) => {
      return res;
    }));
  }

  public getFoundersFl(iin: any) {
    return this.http.get(`/founders/owner/${iin}`).pipe(map((res) => {
      return res;
    }));
  }

  public getBranches(id: any) {
    return this.http.get(`/branches?companyId=${id}`).pipe(map((res) => {
      return res;
    }));
  }

  public updateExtendedFlPart(id: any) {
    return this.http.get(`/node/single/update?id=${id}`).pipe(map((res) => {
      return res;
    }));
  }

  public start(identifier: string) {
    return this.http.get(`/api/v2/node/start?identifier=${identifier}`)
  }

  public getSchemeStatus(identifier: string) {
    return this.http.get(`/api/v2/node/status?identifier=${identifier}`)
  }

  public getRelations(identifier: string) {
    return this.http.get(`/api/v2/node/?identifier=${identifier}`)
  }

  public getSchemeList(id: any) {
    return this.http.get(`/api/v2/node/list?identifier=${id}`)
  }

  public getSchemeExcel(id: any) {
    return this.http.get(`/api/v2/node/xlsx?identifier=${id}`)
  }

  public getDFO(id, page, type, name) {
    let params = [{
      name: 'size',
      value: 20
    }, {
      name: 'page',
      value: page ? page : 0
    }];
    if (name) {
      params.push({
        name: type === 'shareholders' ? 'name' : 'trgName',
        value: name
      });
    }
    let p: string = this.utils.createUrlParams(params);
    return this.http.get(`/company/${id}/${type}${p}`).pipe(map((res) => {
      return res;
    }));
  }
}
