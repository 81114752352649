import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-legal',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './legal.component.html'
})

export class IconLegalComponent {}
