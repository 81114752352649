import {
  Component,
  Input,
  Output,
  forwardRef,
  EventEmitter
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ItemListComponent),
    multi: true
  }]
})
export class ItemListComponent {
  @Input() array: any;
  @Input() name: string;
  @Input() key: string;
  @Output() deleteItem: EventEmitter<any> = new EventEmitter();
  constructor() { }

  itemWasClicked(el): void {
    this.deleteItem.emit(el);
  }
}
