import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-minuso',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './minuso.component.html'
})

export class IconMinusoComponent {}
