import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../shared/services/auth.service';
import 'rxjs/add/operator/do';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req)
      .do((event: any) => {
      }, (err: any) => {
        if (err.status === 401) {
          this.auth.logout(true);
        }
      });
  }
}
