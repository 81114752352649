import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfirmService } from '../confirm.service';
import { ToasterService } from 'angular2-toaster';
import { Subject } from 'rxjs/Subject';
import { AuthRegPopupService } from '../shared/components/auth-reg-popup/auth-reg-popup.service';
import { UtilsService } from '../shared/services/utils.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html'
})
export class ConfirmComponent implements OnInit {
  public token = '';
  public model: any = {
    status: '',
    preLoader: false
  };

  constructor(
    public activatedRoute: ActivatedRoute,
    public confirmService: ConfirmService,
    public authRegPopupService: AuthRegPopupService,
    public router: Router,
    public utils: UtilsService,
    public toast: ToasterService
  ) {
    this.utils.setTitle();
    this.utils.setTag();
  }

  public ngOnInit() {
    this.activatedRoute.queryParams.subscribe((query) => {
      this.submitState(query['token']);
    });
  }

  public submitState(token) {
    this.model.preLoader = true;
    this.confirmService.confirm(token)
      .subscribe((res) => {
        this.model.preLoader = false;
        this.model.status = 'SUCCESS';
        let id = localStorage.getItem('redirectId');
        let redirectId = parseInt(id, 10);
        let self = this;
        if (redirectId && !isNaN(redirectId)) {
          setTimeout(function() {
            self.router.navigate([`/card`, redirectId, 'report']);
            localStorage.removeItem('redirectId');
          }, 3000);
        } else {
          setTimeout(function() {
            self.router.navigate([`/`]);
          }, 3000);
        }
        this.toast.pop('success', `Поздравляем! Теперь вы можете войти в систему!.`);
      }, (err) => {
        this.model.status = 'ERROR';
        this.model.preLoader = false;
      });
  }

}
