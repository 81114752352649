import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { CabinetService } from '../../services/cabinet.service';
import { ToasterService } from 'angular2-toaster';
import { SearchService } from '../../services/search.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-invoice-form',
  templateUrl: './invoice-form.component.html'
})
export class InvoiceFormComponent implements OnInit {
  @Output() public save: any = new EventEmitter();
  model: any;
  services: any[];
  mask: any[] = ['+', '7', /[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  constructor(public cs: CabinetService,
    public toast: ToasterService,
    public searchService: SearchService,
    public utils: UtilsService
    ) {
      this.initModel();
  }

  ngOnInit() {
    this.getServices();
  }

  initModel() {
    this.model = {
      customer: {
        firstname: null,
        lastname: null,
        middlename: null,
        email: null,
        phone: null,
        uin: null,
        name: null,
        address: null,
        postCode: null,
        iik: null,
        bik: null,
        kbe: null,
        nameOfBank: null
      },
      executor: {
        phone: '+77084255319',
        uin: '170940011140',
        name: 'Товарищество с ограниченной ответственностью "KOMPRA"',
        address: 'Республика Казахстан, город Астана, район Есиль, улица Дінмұхамед Қонаев, дом 12, кв. 169',
        iik: 'KZ8396503F0007702140',
        bik: 'IRTYKZKA',
        kbe: '17',
        paymentCode: '859',
        nameOfBank: 'АО "ForteBank" г. Алматы'
      },
      contract: null,
      contractNumber: null,
      contractDate: null,
      invoiceNumber: null,
      invoiceDate: null,
      services: [{
        name: null,
        count: null,
        price: null,
        sum: null
      }],
      documentLink: null
    };
  }

  createInvoice() {
    this.cs.createInvoice(this.model).subscribe((res: any) => {
      this.getServices();
      this.save.emit(true);
      this.initModel();
    }, (err) => {
      this.toast.pop('error', 'Ошибка!');
    });
  }

  getServices() {
    this.cs.getServices().subscribe((res: any) => {
      this.services = res;
    }, (err) => {
      this.toast.pop('error', 'Ошибка!');
    });
  }

  calcSum(price, count, i) {
    if (price && count) {
      this.model.services[i].sum = price * count;
    }
  }

  doSearch() {
    if (this.utils.isUIN(this.model.customer.uin)) {
      this.searchService.searching([{
        name: 'bin',
        value: this.model.customer.uin
      }]).subscribe((res: Array<any>) => {
        if (res && res.length) {
          this.model.customer.name = res[0].fullName;
          this.model.customer.address = res[0].lawAddress;
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

  setServices(el, i) {
    let obj = this.services.find((e) => el === e.name);
    this.model.services[i].id = obj.id;
  }
}
