import { Injectable, Component } from '@angular/core';
import { AppState } from '../../../app.service';

@Injectable()
export class AuthRegPopupService {
    constructor(private appState: AppState) {}

    open(t?: 'REG' | 'AUTH') {
      this.appState.set('authRegPopupVisibility', true);
      if (t) {
        this.appState.set('tabName', t);
      }
    }

    close() {
      this.appState.set('authRegPopupVisibility', false);
    }
}
