import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostBinding
} from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { CabinetService } from '../../services/cabinet.service';
import { AuthService } from '../../services/auth.service';
import { WindowRef } from '../../services/window.ref';
import { AppState } from '../../../app.service';
import { AccessRightService } from '../../services/access-right.service';

@Component({
  selector: 'app-sr-item',
  templateUrl: './sr-item.component.html'
})
export class SrItemComponent implements OnInit {
  @Input() public data: any = {};
  @Input() public name: string;
  @Input() public keyword: any = '';
  @Input() public expireDate: any;
  @Input() public createdDate: any;
  @Input() public payment: any;
  @Input() public favorite: any;
  @Input() public country: any;
  @Input() public type: 'UL' | 'FL' | 'RF';
  @Output() public view: any = new EventEmitter();
  @Output() public remove: any = new EventEmitter();
  public expiredStatus = false;
  listOfRelation: any[] = [];
  listOfComparision: any[] = [];

  constructor(
    public utils: UtilsService,
    public cs: CabinetService,
    public appState: AppState,
    public authService: AuthService,
    public accessRightService: AccessRightService,
    private winRef: WindowRef
  ) { }

  public ngOnInit() {
    if (this.name === 'favorites' || this.name === 'historyView') {
      let date: any = new Date().getTime();
      if (this.expireDate < date) {
        this.expiredStatus = true;
      }
    }
    this.listOfRelation = JSON.parse(localStorage.getItem('listOfRelation'));
  }

  public handleView(el: any) {
    this.view.emit(el);
  }

  public addFavorites() {
    if (this.data && this.data.id) {
      this.cs.addFavorites({ companyId: this.data.id }).subscribe((res) => {
        if (location.hostname === 'kompra.kz') {
          this.winRef.nativeWindow.dataLayer.push({ 'event': 'add_to_favorites' });
        }
        this.data.favorite = true;
      });
    }
  }

  public deleteFromFavorite() {
    if (this.data && this.data.id) {
      this.cs.deleteFavorites(this.data.id).subscribe((res) => {
        this.data.favorite = false;
      });
    }
  }

  addToRelation(company) {
    this.listOfRelation = JSON.parse(localStorage.getItem('listOfRelation'));
    if (this.listOfRelation && this.listOfRelation.length) {
      this.listOfRelation.push(company);
    } else {
      this.listOfRelation = [company];
    }
    localStorage.setItem('listOfRelation', JSON.stringify(this.listOfRelation));
  }

  deleteRelation(company) {
    this.listOfRelation = JSON.parse(localStorage.getItem('listOfRelation'));
    let i = this.listOfRelation.findIndex(el => el.id === company.id);
    this.listOfRelation.splice(i, 1);
    localStorage.setItem('listOfRelation', JSON.stringify(this.listOfRelation));
  }

  getComparision() {
    this.listOfComparision = JSON.parse(localStorage.getItem('listOfComparision'));
    return this.listOfComparision;
  }

  isInComparision(id) {
    this.listOfComparision = JSON.parse(localStorage.getItem('listOfComparision'));
    return this.listOfComparision && this.listOfComparision.length && this.listOfComparision.some((el) => el.id === id);
  }

  addComparision(company) {
    this.listOfComparision = JSON.parse(localStorage.getItem('listOfComparision'));
    if (this.listOfComparision && this.listOfComparision.length) {
      this.listOfComparision.push(company);
    } else {
      this.listOfComparision = [company];
    }
    localStorage.setItem('listOfComparision', JSON.stringify(this.listOfComparision));
  }

  disabledRelation() {
    return this.data && (this.listOfRelation && this.listOfRelation.length
      && this.listOfRelation.findIndex(el => el.id === this.data.id) > -1 || !(this.data.bin || this.data.iin));
  }

  getField(p) {
    return p && p.substring(2, p.length - 2);
  }

}
