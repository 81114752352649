import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-search',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './search.component.html'
})

export class IconSearchComponent {}
