import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-question',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './question.component.html'
})

export class IconQuestionComponent {}
