import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-pagination2',
  templateUrl: './pagination2.component.html'
})
export class Pagination2Component implements OnChanges {
  @Input() public page: number; /*страница которую я запросил*/
  @Input() public totalPages: number; /*общее количетсво страниц*/
  @Input() public first: boolean; /*первая ли это страница*/
  @Input() public last: boolean; /*последняя ли это страница*/
  @Input() public searchPage: boolean; /*страница поиска*/
  @Output() public changePage: any = new EventEmitter();

  constructor() { }

  arrPages: number[] = [];

  public ngOnChanges(changes: SimpleChanges) {
    if (this.totalPages > 20 && this.searchPage) {
      this.totalPages = 20;
    }
  }

}
