import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-close',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './close.component.html'
})

export class IconCloseComponent {}
