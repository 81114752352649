import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

export interface SurveyForm {
  survey: {
    id: number
  }
  answerDto: SurveyAnswerDto[]
}

export interface SurveyAnswerDto {
  question: {
    id: number
  }
  value: number | string
  options?: {id: number | string}[]
}

@Injectable()
export class SurveyService {

  constructor(private http: HttpClient) { }

  public checkSurveyCompletion(): Observable<any> {
    return this.http.get('/survey/passed')
  }

  public createSurvey(data: SurveyForm): Observable<any> {
    return this.http.put('/survey/', data)
  }

  public getSurveys(): Observable<any> {
    return this.http.get('/survey')
  }

  public getSurveyById(id: number | string): Observable<any> {
    return this.http.get('/survey/' + id)
  }
  
  public takeSurvey(data: SurveyForm): Observable<any> {
    return this.http.post('/survey/take', data)
  }

}
