import {
  Component,
  Input,
  Output,
  OnInit,
  OnChanges,
  EventEmitter,
  forwardRef,
  HostListener,
  ViewChild,
  ElementRef
} from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

/**
* Компонент автокомплитер по справочникам и переданному массиву. Данные изначально загружены и известны.
* @param {string} title - Заголовок
* @param {string} dictName - Наименование справочника, надо смотреть dictionary
* @param {string} micName - Наименование микросервиса с которого нужно взять, если не указать то берет с global
* @param {string} keys - Ключ поиска, Поля которых нужно показать ['ru', 'kk', 'code']
* @param {string} tooltipText - Текст подсказки
* @param {string} placeholder - Плейсхолдер
* @param {any} result - Если указать в параметре dictName = NO_DICT, то можно передать свой справочник по которому будет происходит поиск
* @param {boolean} isDisabled - Редактируемость
* @param {boolean} isRequired - Обязательность
* @param {string} openApi - Флаг публичности справочника
* @param {EventEmitter} setObj - Выбор значения
*/
@Component({
  selector: 'app-completer-locale',
  templateUrl: './completer-locale.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CompleterLocaleComponent),
    multi: true
  }]
})
export class CompleterLocaleComponent implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild('scroll') private myScrollContainer: ElementRef;
  @Input() title: string;
  @Input() keys: string[];  /* Поля которых нужно показать ['ru', 'kk', 'code'] */
  @Input() placeholder: string;
  @Input() result: any[];
  @Input() isDisabled: boolean;
  @Input() isRequired: boolean;
  @Output() setObj = new EventEmitter();  /* Передача выбранного объекта */

  model = {
    size: 2000,
    result: [],
    showResult: [],
    showList: false,
    setShowList: true,
    activeEl: -1
  };

  public innerValue: any = '';
  public focused = false;
  public uid: any;

  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_: any) => void = () => { };

  constructor(
    public utils: UtilsService
  ) {
    this.placeholder = '';
    this.uid = this.utils.shortGuid();
  }

  @HostListener('keyup', ['$event'])
  public onChange(e: KeyboardEvent) {
    // up
    if (e.keyCode === 38) {
      if (this.model.activeEl > -1) {
        this.model.activeEl -= 1;
        this.scrollTo(this.model.activeEl);
      } else {
        this.model.activeEl = this.model.showResult.length - 1;
        this.scrollTo(this.model.activeEl);
      }
      return;
    }
    // down
    if (e.keyCode === 40) {
      if (this.model.activeEl < this.model.showResult.length - 1) {
        this.model.activeEl += 1;
        this.scrollTo(this.model.activeEl);
      } else {
        this.model.activeEl = -1;
        this.scrollTo(this.model.activeEl);
      }
      return;
    }
  }

  ngOnInit() {
    this.setResponse(this.result);
  }

  ngOnChanges() {
    this.setResponse(this.result);
  }

  setResponse(res) {
    let arr = res && res.length ? res : [];
    this.model.result = [...arr];
    this.model.showResult = [...arr]
    if (this.model.showResult && this.model.showResult.length > 100) {
      this.model.showResult.length = 100;
    }
  }

  focusFunction() {
    if (this.utils.isEmpty(this.innerValue) && this.model.result && this.model.result.length) {
      this.clearObj();
    }
    this.model.showList = true;
  }
  focusOutFunction() {
    this.model.showList = false;
  }

  doSearch() {
    if (this.innerValue && this.innerValue.length > 1 && this.model.setShowList) {
      this.model.showList = true;
      if (this.keys && this.keys.length && Array.isArray(this.keys)) {
        this.model.showResult = this.model.result.filter((el) =>
          this.keys.some((e) => {
            return el[e] && String(el[e]).toLowerCase().indexOf(this.innerValue.toLowerCase()) > -1
          })
        );
        if (this.model.showResult && this.model.showResult.length > 100) {
          this.model.showResult.length = 100;
        }
      } else {
        this.model.showResult = this.model.result.filter((el) =>
          el.ru && el.ru.toLowerCase().indexOf(this.innerValue.toLowerCase()) > -1 ||
          el.kk && el.kk.toLowerCase().indexOf(this.innerValue.toLowerCase()) > -1 ||
          el.en && el.en.toLowerCase().indexOf(this.innerValue.toLowerCase()) > -1 ||
          el.code && el.code.toLowerCase().indexOf(this.innerValue.toLowerCase()) > -1
        );
        if (this.model.showResult && this.model.showResult.length > 100) {
          this.model.showResult.length = 100;
        }
      }
    } else {
      this.model.showList = false;
      this.model.setShowList = true;
      this.model.showResult = JSON.parse(JSON.stringify(this.model.result));
      if (this.model.showResult && this.model.showResult.length > 100) {
        this.model.showResult.length = 100;
      }
    }
  }

  clearObj() {
    this.setObj.emit(undefined);
    this.onChangeCallback(undefined);
  }

  clear() {
    this.innerValue = '';
    this.setObj.emit(undefined);
    this.checkFocus();
  }

  setValue(i) {
    let obj = this.model.showResult[i];
    this.innerValue = obj.name;
    this.setObj.emit(obj);
    this.model.showList = false;
    this.model.setShowList = false;
  }

  onEnter() {
    if (this.model.activeEl > -1) {
      this.setValue(this.model.activeEl);
    }
  }

  scrollTo(i): void {
    try {
      this.myScrollContainer.nativeElement.children[i].scrollIntoView();
    } catch (err) { }
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    let val = v;
    if (v) {
      let arr = this.model.showResult.filter((el) => v.id === el.id);
      val = arr[0];
    }
    let str = val && val.name;
    if (str !== this.innerValue) {
      this.innerValue = str;
      this.onChangeCallback(this.innerValue);
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    let str = value && value.name;
    if (str !== this.innerValue) {
      this.innerValue = str;
      this.checkFocus();
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
    this.checkFocus();
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
    this.checkFocus();
  }

  blur(e) {
    this.checkFocus();
  }

  checkFocus() {
    this.focused = this.utils.isEmpty(this.innerValue) ? false : true;
  }
  trackId(index: number, item: any) {
    return item.id || index;
  }
}
