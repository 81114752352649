import { Injectable } from '@angular/core';
import { AppState } from '../../app.service';

@Injectable()
export class AgreedPopupService {
  constructor(private appState: AppState) { }

  open() {
    this.appState.set('agreedPopupVisibility', true);
  }

  close() {
    this.appState.set('agreedPopupVisibility', false);
  }
}
