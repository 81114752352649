import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-uxcheck',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './uxcheck.component.html'
})

export class IconUxcheckComponent {}
