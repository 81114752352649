import { Directive, HostListener, Input } from '@angular/core';

interface FacebookParams {
  u: string;
}
interface VkParams {
  url: string;
  title: string;
  description: string;
  image: any;
}
interface TwitterParams {
  text: string;
  url: string;
  hashtags: string;
  via: string;
}

interface WhatsappParams {
  text: string;
}

interface TelegramParams {
  url: string;
}

/*
shareUrl: 'mailto:' + this.to,
params: {
    subject: this.subject,
    body: this.title + '\n' + this.url
},*/

@Directive({
  selector: '[appSocialShare]'
})
export class SocialShareDirective {
  @Input() public facebook: FacebookParams;
  @Input() public twitter: TwitterParams;
  @Input() public vk: VkParams;
  @Input() public whatsapp: WhatsappParams;
  @Input() public telegram: TelegramParams;
  @Input() public shareWidth: string;
  @Input() public shareHeight: string;
  private sharers = {
    facebook: {
      shareUrl: 'https://www.facebook.com/sharer/sharer.php'
    },
    twitter: {
      shareUrl: 'https://twitter.com/intent/tweet/'
    },
    email: {
      isLink: true
    },
    vk: {
      shareUrl: 'https://vk.com/share.php'
    },
    whatsapp: {
      shareUrl: 'https://wa.me/'
    },
    telegram: {
      shareUrl: 'https://t.me/share/url/'
    }
  };

  @HostListener('click') public share() {
    let s = this.getSharer();
    // custom popups sizes
    if (s) {
      s.width = this.shareWidth;
      s.height = this.shareHeight;
    }
    return s !== undefined ? this.urlSharer(s) : false;
  }

  private urlSharer(sharer: any) {
    let p: any = sharer.params || {};
    let keys: any = Object.keys(p);
    let i: any;
    let str: any = keys.length > 0 ? '?' : '';
    for (i = 0; i < keys.length; i++) {
      if (str !== '?') {
        str += '&';
      }
      if (p[keys[i]]) {
        str += keys[i] + '=' + encodeURIComponent(p[keys[i]]);
      }
    }
    sharer.shareUrl += str;

    if (!sharer.isLink) {
      let popWidth = sharer.width || 600;
      let popHeight = sharer.height || 480;
      let left = window.innerWidth / 2 - popWidth / 2 + window.screenX;
      let top = window.innerHeight / 2 - popHeight / 2 + window.screenY;
      let popParams = 'scrollbars=no, width=' + popWidth + ', height=' + popHeight + ', top=' + top + ', left=' + left;
      let newWindow = window.open(sharer.shareUrl, '', popParams);

      if (window.focus) {
        newWindow.focus();
      }
    } else {
      window.location.href = sharer.shareUrl;
    }
  }

  private getSharer() {
    let _sharer: any = {};
    if (this.facebook) {
      _sharer = this.sharers['facebook'];
      _sharer.params = this.facebook;
    }
    if (this.twitter) {
      _sharer = this.sharers['twitter'];
      _sharer.params = this.twitter;
    }
    if (this.vk) {
      _sharer = this.sharers['vk'];
      _sharer.params = this.vk;
    }
    if (this.whatsapp) {
      _sharer = this.sharers['whatsapp'];
      _sharer.params = this.whatsapp;
    }
    if (this.telegram) {
      _sharer = this.sharers['telegram'];
      _sharer.params = this.telegram;
    }

    return _sharer;
  }
}
