import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class SubscribeService {

  constructor(
    private http: HttpClient,
  ) { }

  public request_subscription(data) {
    return this.http.post(`/cabinet/subscription`, data)
      .pipe(map((res) => {
        return res;
      }));
  }
}
