import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-pencil',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './pencil.component.html'
})

export class IconPencilComponent {}
