import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-externallink',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './externallink.component.html'
})

export class IconExternallinkComponent {}
