import {
  Component,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  forwardRef,
  HostListener,
  ViewChild,
  ElementRef,
  OnInit
} from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import {NG_VALUE_ACCESSOR, ControlValueAccessor, NgModel} from '@angular/forms';

/**
* Компонент автокомплитер, поиск происходит на бэке по ключу. Данные изначально не известны поиск происходит на моменте ввода
* @param {string} title - Заголовок
* @param {string} placeholder - Плейсхолдер
* @param {boolean} autofocus - Автофокус, установка курсора при инициализации
* @param {boolean} isDisabled - Редактируемость
* @param {boolean} isRequired - Обязательность
* @param {string} tooltipText - Текст подсказки
* @param {number} searchSize - Размер строки после которого начинается поиск
* @param {any} result - Результат поиска
* @param {string} keys - Ключ поиска, Поля которых нужно показать ['ru', 'kk', 'code']
* @param {EventEmitter} search - Поиск по ключу на бэке, на это событие в компоненте нужно реализовать функцию поиска и передачу результата в result
* @param {EventEmitter} setObj - Выбор значения
* @param {boolean} clearAfterSelect - Очищать input после выбора значения
*/
@Component({
  selector: 'app-completer-remote',
  templateUrl: './completer-remote.component.html',
  providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompleterRemoteComponent),
      multi: true
  }]
})
export class CompleterRemoteComponent implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild('inputModel') private inputModel: NgModel;
  @ViewChild('scroll') private myScrollContainer: ElementRef;
  @Input() title: string;
  @Input() placeholder: string;
  @Input() autofocus: boolean;
  @Input() isDisabled: boolean;
  @Input() isRequired: boolean;
  @Input() tooltipText: string;
  @Input() searchSize: number;
  @Input() result: any[];  /* Результат поиска */
  @Input() keys: string[];  /* Поля которых нужно показать ['ru', 'kk', 'code'] */
  @Input() clearAfterSelect: boolean;
  @Input() preload: boolean;
  @Input() autoFill: boolean;
  @Output() search = new EventEmitter();  /* Собятие для поиска */
  @Output() setObj = new EventEmitter();  /* Передача выбранного объекта */

  model = {
      showList: false,
      loading: false,
      doSearching: true,
      activeEl: -1
  };

  public innerValue: any = '';
  public focused = false;
  public uid: any;
  public ss: number;

  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_: any) => void = () => { };

  constructor(
      public utils: UtilsService
  ) {
      this.placeholder = '';
      this.uid = this.utils.shortGuid();
  }

  @HostListener('keyup', ['$event'])
  public onChange(e: KeyboardEvent) {
      // up
      if (e.keyCode === 38) {
          if (this.model.activeEl > -1) {
              this.model.activeEl -= 1;
              this.scrollTo(this.model.activeEl);
          } else {
              this.model.activeEl = this.result.length - 1;
              this.scrollTo(this.model.activeEl);
          }
          return;
      }
      // down
      if (e.keyCode === 40) {
          if (this.model.activeEl < this.result.length - 1) {
              this.model.activeEl += 1;
              this.scrollTo(this.model.activeEl);
          } else {
              this.model.activeEl = -1;
              this.scrollTo(this.model.activeEl);
          }
          return;
      }
  }

  ngOnInit() {
      this.ss = this.searchSize || this.searchSize === 0 ? this.searchSize : 2;
      if (this.preload) {
          this.search.emit();
      }
  }

  ngOnChanges(value) {
      this.model.loading = false;
  }

  doSearch() {
      if (this.innerValue && this.innerValue.length > this.ss && this.model.doSearching && !this.autoFill) {
          this.model.showList = true;
          this.model.loading = true;
          this.search.emit(this.innerValue);
      } else {
          this.model.doSearching = true;
          this.model.showList = false;
          this.model.loading = false;
          this.model.activeEl = -1;
      }
  }

  setValue(i) {
      let obj = this.result[i];
      this.setObj.emit(obj);
      this.innerValue = '';
      this.keys.forEach((key) => {
          this.innerValue += `${obj[key]} `;
      });
      this.model.doSearching = false;
      this.model.showList = false;
      this.model.loading = false;
      this.checkFocus();
      if (this.clearAfterSelect) {
          this.inputModel.reset();
      }
  }

  onEnter() {
      if (this.model.activeEl > -1) {
          this.setValue(this.model.activeEl);
      }
  }

  scrollTo(i): void {
      try {
          this.myScrollContainer.nativeElement.children[i].scrollIntoView();
      } catch (err) { }
  }

  get value(): any {
      return this.innerValue;
  }

  set value(v: any) {
      if (v !== this.innerValue) {
          this.innerValue = v;
          this.onChangeCallback(v);
      }
  }

  onBlur() {
      this.onTouchedCallback();
  }

  writeValue(value: any) {
      if (value !== this.innerValue) {
          this.innerValue = value;
          this.checkFocus();
      }
  }

  registerOnChange(fn: any) {
      this.onChangeCallback = fn;
      this.checkFocus();
  }

  registerOnTouched(fn: any) {
      this.onTouchedCallback = fn;
      this.checkFocus();
  }

  focus() {
      this.focused = true;
      if (this.preload && this.result && this.result.length === 0 && !this.innerValue) {
          this.search.emit();
          this.model.showList = true;
      }
      if (this.result && this.result.length > 0) {
          this.model.showList = true;
      }
  }

  blur() {
      this.checkFocus();
  }

  checkFocus() {
      this.focused = this.utils.isEmpty(this.innerValue) ? false : true;
      if (this.model.showList) {
          setTimeout(() => this.model.showList = false, 500);
      }
  }
  trackId(index: number, item: any) {
      return item.id || index;
  }
}
