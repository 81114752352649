import { Component, OnInit, HostBinding } from '@angular/core';
import { UtilsService } from '../shared/services/utils.service';

@Component({
  selector: 'app-publicoffer',
  templateUrl: './publicoffer.component.html'
})
export class PublicofferComponent implements OnInit {
  @HostBinding('class') class = 'layout';
  constructor(public utils: UtilsService) {
    this.utils.setTitle();
    this.utils.setTag();
  }

  ngOnInit() {
  }

}
