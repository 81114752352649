import { Directive, HostListener, ElementRef } from "@angular/core";

@Directive({
  selector: "[appPhoneNumberMask]",
})
export class PhoneMask {
  constructor(private el: ElementRef) {}

  private lastLength: number = 0;

  @HostListener("input", ["$event"])
  onInput(event: any) {
    // Get the input value
    let value = event.target.value;
    if (this.lastLength < value.length) {
      // Remove non-digit characters
      // value = value.replace(/\D/g, '');
      value = value.replace(/[^0-9+]/g, "");
      // Apply the mask
      let prefix = value.slice(0, 4);
      const areaCode = value.slice(4, 6);
      const lineNumber = value.slice(6, 13);

      if (prefix.length > 0) {
        if (prefix.startsWith("+998")) {
          value = `${prefix} (${areaCode}`;
        } else {
          prefix = "+998";
          value = `${prefix} (${areaCode}`;
        }
      }

      if (lineNumber.length > 0) {
        value += `) ${lineNumber.slice(0, 3)}-${lineNumber.slice(3, 7)}`;
      }

      // Set the input value
      event.target.value = value;
      this.lastLength = value.length;
    }
    if (this.lastLength > value.length) {
      this.lastLength = value.length;
    }
  }

  @HostListener("keydown.backspace", ["$event"])
  onBackspaceKeyDown(event: KeyboardEvent) {
    // Get the current input value
    let inputValue = this.el.nativeElement.value;

    // Get the cursor position
    let cursorPosition = this.el.nativeElement.selectionStart;

    if (inputValue.length === 4 && inputValue.startsWith("+998")) {
      event.preventDefault(); // Prevent the default backspace behavior
      event.stopPropagation(); // Stop the event propagation
      return;
    }

    if (
      cursorPosition > 0 &&
      /[-() ]/.test(inputValue[cursorPosition - 2]) &&
      /[-() ]/.test(inputValue[cursorPosition - 3])
    ) {
      inputValue = inputValue.substring(0, cursorPosition - 2);
      this.el.nativeElement.value = inputValue;
      return;
    }
    // Check if there is a character before the cursor position
    if (cursorPosition > 0 && /[-() ]/.test(inputValue[cursorPosition - 2])) {
      inputValue = inputValue.substring(0, cursorPosition - 1);
      this.el.nativeElement.value = inputValue;
    }
  }
}
