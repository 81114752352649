import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-uxcloud',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './uxcloud.component.html'
})

export class IconUxcloudComponent {}
