import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-lifering',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './lifering.component.html'
})

export class IconLiferingComponent {}
