import {
  Component,
  OnChanges,
  OnInit
} from '@angular/core';
import { DataService } from '../../../data.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html'
})
export class PersonalDataComponent {
  model: any = {};

  constructor(public authService: AuthService) { }

  setPersonalData() {
    this.authService.setPersonalData(true);
  }

}
