export * from './collapse.component';
export * from './expand.component';
export * from './ban.component';
export * from './bank.component';
export * from './bell.component';
export * from './bookmark.component';
export * from './bookmark-alt.component';
export * from './calendar.component';
export * from './caretleft.component';
export * from './caretdown.component';
export * from './caretleftdouble.component';
export * from './caretright.component';
export * from './checkcircleo.component';
export * from './checkcircle.component';
export * from './chevrondown.component';
export * from './chevronleft.component';
export * from './chevronright.component';
export * from './chevronup.component';
export * from './clocko.component';
export * from './cog.component';
export * from './copy.component';
export * from './diag.component';
export * from './dot.component';
export * from './download.component';
export * from './envelope.component';
export * from './exchange.component';
export * from './exclamationsign.component';
export * from './export.component';
export * from './externallink.component';
export * from './eyeopen.component';
export * from './eyeclosed.component';
export * from './facebook.component';
export * from './file.component';
export * from './filetext.component';
export * from './filter.component';
export * from './kompra.component';
export * from './legal.component';
export * from './lock.component';
export * from './minussquareo.component';
export * from './pencil.component';
export * from './pie.component';
export * from './plus.component';
export * from './plussquareo.component';
export * from './question.component';
export * from './remove.component';
export * from './removecircle.component';
export * from './rocket.component';
export * from './search.component';
export * from './share.component';
export * from './shoppingcart.component';
export * from './signout.component';
export * from './star.component';
export * from './staro.component';
export * from './tie.component';
export * from './trash.component';
export * from './twitter.component';
export * from './upload.component';
export * from './vk.component';
export * from './bag.component';
export * from './angle-left.component';
export * from './angle-right.component';
export * from './avatar.component';
export * from './wallet.component';
export * from './close.component';
export * from './crown.component';
export * from './menu.component';
export * from './paperclip.component';
export * from './downloadalt.component';
export * from './plug.component';
export * from './refresh.component';
export * from './ellipsis.component';
export * from './expand-alt.component';
export * from './search-minus.component';
export * from './search-plus.component';
export * from './compress.component';
export * from './database.component';
export * from './sitemap.component';
export * from './align-left.component';
export * from './angle-double-up.component';
export * from './print.component';
export * from './clouddownload.component';
export * from './coffee.component';
export * from './lifering.component';
export * from './briefcase.component';
export * from './sad-smile.component';
export * from './russia.component';
export * from './exclsign.component';
export * from './flag.component';
export * from './affiliation.component';
export * from './connections.component';
export * from './gravel.component';
export * from './shareholders.component';
export * from './calculator.component';
export * from './circledownload.component';
export * from './balance.component';
export * from './api.component';
export * from './history.component';
export * from './uxlink.component';
export * from './uxscheme.component';
export * from './uxcloud.component';
export * from './uxtimes.component';
export * from './uxuser.component';
export * from './uxsuitecase.component';
export * from './signin.component';
export * from './bookmark-fill.component';
export * from './uxpin.component';
export * from './balance-fill.component';
export * from './uxcheck.component';
export * from './arrowtoleft.component';
export * from './arrowtoright.component';
export * from './check.component';
export * from './minuso.component';
export * from './pluso.component';
export * from './telegram.component';
export * from './list.component';
export * from './download-circle.component';
export * from './placeholder.component';
export * from './arrowtobottom.component';
export * from './starfill.component';
export * from './starfilled.component';
export * from './arrowdown.component';
export * from './notfound.component';
export * from './monitoring.component';
export * from './exit.component';
export * from './comparision.component';
export * from './virus.component';
export * from './arrowright.component';
export * from './insta.component';
export * from './info.component';
export * from './note.component';
export * from './analytics.component';
export * from './ballot.component';
export * from './address-book.component';
export * from './hand-dollar.component';
export * from './book-user.component';
export * from './flag-kz.component';
export * from './flag-ru.component';
export * from './flag-kg.component';
export * from './user.component';
export * from './table.component';
export * from './asc.component';
export * from './desc.component';
export * from './kompra-tools.component';
export * from './phone.component';
export * from './users.component';
export * from './newspaper.component';
export * from './comments.component';
export * from './comment.component';
export * from './thumbup.component';
export * from './repost.component';
export * from './odnoklassniki.component';
export * from './at.component';
export * from './gplus.component';
export * from './yt.component';
export * from './map-marker.component';
export * from './google-icon.component';
export * from './yandex-icon.component';
export * from './duck-go-icon.component';
export * from './service-hh.component';
export * from './arrowrightalt.component';
export * from './wa.component';
export * from './key.component';
export * from './filetext-color.component';
export * from './exclsigntri.component';
export * from './usersettings.component';
// Все иконки подключать выше
// export * from './icon.module';
