import { HttpEventType, HttpResponse } from '@angular/common/http';
import { PhotoService } from './../../shared/services/photo.service';
import { Component, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { UtilsService } from '../../shared/services/utils.service';
import { WindowRef } from '../../shared/services/window.ref';
import { ToasterService } from 'angular2-toaster';
import { Sub } from '../card/card.service';
import { AccessRightService } from '../../shared/services/access-right.service';
import { AuthService } from '../../shared/services/auth.service';
import { AuthRegPopupService } from '../../shared/components/auth-reg-popup/auth-reg-popup.service';
import { SubscribeService } from '../../shared/services/subscribe.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html'
})

export class PhotoComponent implements OnInit, OnDestroy {
  @ViewChildren('input') public input;

  sub: Sub = {};
  data: any = {};
  errorMsg: string
  btnText: string = "Отправить"
  showNoData: boolean = false;
  showUnavailable: boolean = false;
  emailPattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  
  public model: any = {
    number: '',
    data: []
  }

  getFacesLoading: boolean = false
  getLinksLoading: boolean = false
  loadingPercent = 0 
  selectedImage: string
  chooseFaceModal: boolean = false
  faces = []
  errors = []
  links: any = {
    totalElements: 0,
    moreSimilar: [],
    lessSimilar: []
  }

  constructor(
    public utils: UtilsService,
    public accessRightService: AccessRightService,
    public authService: AuthService,
    public authRegPopupService: AuthRegPopupService,
    public winref: WindowRef,
    public toast: ToasterService,
    private photoService: PhotoService,
    private subsribeService: SubscribeService,
    public sanitizer: DomSanitizer
  ) {
    this.utils.setTitle('Поиск по фото');
    this.utils.setTag('Проверяйте наличие зарегистрированных юридических лиц у сотрудников организации и сопоставляйте их со списком контрагентов компании.');
  }

  ngOnInit() {
    this.getUserInfo()
  }
  
  getUserInfo() {
    if (this.authService.authorized) {
      this.data.email = this.authService.user;
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(userInfo) {
        this.data.phone = userInfo.phone;
        this.data.name = userInfo.firstName;
      }
    }
  }
  
  startSearchingByFace() {
    this.getLinksLoading = true
    this.sub.getLinks = this.photoService
      .getLinks(this.selectedImage)
      .subscribe((res: any) => {
        if (res.type === HttpEventType.UploadProgress) {
          this.loadingPercent = Math.round(100 * res.loaded / res.total);
        }
        if (res instanceof HttpResponse) {
          this.getLinksLoading = false
          if(res.body.content && !res.body.content.length) {
            this.selectedImage = null
            this.errors = ['Поиск по фото не дал результатов.']
          }
          if(res.body.content && res.body.content.length) {
            this.errors = []
            this.links.totalElements = res.body.content.length || 0
            res.body.content.map(item => {
              if(item.percentage >= 99) {
                this.links.moreSimilar.push(item)
              } else {
                this.links.lessSimilar.push(item)
              }
            })
          }
        } 
      })
  }
  
  sendImage(e: Event) {
    const file = (<HTMLInputElement>e.target).files[0]
    if(this.correctImageSize(file.size)) {
      this.getFacesLoading = true
      this.sub.getFaces = this.photoService
        .getFaces(file)
        .subscribe(
          (res: any) => {
            this.errors = []
            if (res.type === HttpEventType.UploadProgress) {
              this.loadingPercent = Math.round(100 * res.loaded / res.total);
            }
            if (res instanceof HttpResponse) {
              this.getFacesLoading = false
              if(res.body && res.body.content && res.body.content.length) {
                if(res.body.content.length === 1) {
                  this.selectedImage = res.body.content[0].face
                } 
                if(res.body.content.length > 1) {
                  this.faces = res.body.content.map((el: any) => {
                    return {...el, selected: false}
                  })
                  this.chooseFaceModal = true
                }
              } else {
                this.errors = ['Нет данных по данному фото.']
              }
            }
          }, (err: Error) => {
            this.getFacesLoading = false
            this.errors = ['При загрузке файла произошла ошибка.']
            this.toast.pop('error', 'Ошибка', err.message)
          }
        )
    }

  }

  correctImageSize(size: number): boolean {
    if(size > 10 * 1024 * 1024) {
      this.errors = ['вес фото не должен превышать 10 Мб']
      return false
    }
    return true
  }
  
  public checkDatas() {
    if(this.utils.isEmpty(this.model.data && this.model.data.content)) {
      this.showNoData = true
      return
    }
    const booleans = []
    for (const key in this.model.data.content) {
      booleans.push({
        empty: this.model.data.content[key].status === 'NO',
        unavailable: this.model.data.content[key].status === 'OFF',
      })
    }
    this.showNoData = booleans.every(el => el.empty)
    this.showUnavailable = booleans.every(el => el.unavailable)
  }
  
  public clearAll() {
    this.model.data = [];
    this.model.number = '';
    this.model.loading = false;
    this.model.showResult = false;
    if (this.sub.searchByNumber) {
      this.sub.searchByNumber.unsubscribe();
    }
  }

  public reset() {
    this.getFacesLoading = false
    this.getLinksLoading = false
    this.loadingPercent = 0 
    this.selectedImage = null
    this.chooseFaceModal = false
    this.faces = []
    this.errors = []
    this.links = {
      totalElements: 0,
      moreSimilar: [],
      lessSimilar: []
    }
  }

  public selectImage(index: number) {
    this.faces = this.faces.map(face => {
      return {...face, selected: false}
    })
    this.faces[index].selected = true
    this.selectedImage = this.faces[index].face
  }

  ngOnDestroy() {
    for (const key in this.sub) {
      if (this.sub.hasOwnProperty(key)) {
        this.sub[key].unsubscribe();
      }
    }
  }

  submit_form() {
    if (this.data.email && this.data.phone) {
      this.btnText = "Отправка..."
      let obj = {
        phone: this.data.phone,
        email: this.data.email,
        name: this.data.name,
        type: 'PAYMENT',
        description: 'Проверка по фото',
      };
      this.subsribeService.request_subscription(obj).subscribe((res: any) => {
        this.btnText = "Отправить"
        this.data = {};
        this.data.success = true;
        this.data.modal = false;
      }, () => {
        this.btnText = "Отправить"
        this.toast.pop('error', 'Техническая ошибка');
      });
    }
  }
}
