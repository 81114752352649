import { Component, OnInit, HostBinding } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { SubscribeService } from '../shared/services/subscribe.service';
import { AuthRegPopupService } from '../shared/components/auth-reg-popup/auth-reg-popup.service';
import { WindowRef } from '../shared/services/window.ref';
import { Title, Meta } from '@angular/platform-browser';
import { AppState } from '../app.service';
import { UtilsService } from '../shared/services/utils.service';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html'
})
export class TariffComponent implements OnInit {
  @HostBinding('class') class = 'layout';
  email: string;
  phone: string;
  name: string;
  discountamount: string;
  model: any;
  showModal: boolean;
  success: boolean;
  disabled: boolean = false
  constructor(
    public auth: AuthService,
    public winRef: WindowRef,
    public appState: AppState,
    public subsribeService: SubscribeService,
    public utils: UtilsService,
    public toast: ToasterService,
    private titleService: Title,
    private metaService: Meta,
    public authRegPopupService: AuthRegPopupService
  ) {
    this.showModal = false;
    this.success = false;
    this.model = {
      month: {},
      year: {},
      currency: 'KZT',
      currencyCount: 1,
      currencyValue: 1,
    };
  }

  ngOnInit() {
    this.titleService.setTitle(`Стоимость проверки на Kompra. Выбирайте удобный платежный план для своей работы`);
    this.metaService.updateTag({
      name: 'description',
      content: `Присоединяйтесь к сервису Kompra по самым выгодным тарифам, оформляйте подписку на новые услуги.`
    });
    this.getUserInfo()
    // this.metaService.updateTag({
    //   name: 'keywords',
    //   content: ``
    // });
  }

  getUserInfo() {
    if (this.auth.authorized) {
      this.email = this.auth.user;
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(userInfo) {
        this.phone = userInfo.phone;
        this.name = userInfo.firstName;
      }
    }
  }

  getCurrencySum(x) {
    return this.utils.mathRound(x * this.model.currencyCount / this.model.currencyValue, 0);
  }

  submit_form() {
    if (this.email && this.phone) {
      this.disabled = true
      let obj = {
        amount: this.model.cost,
        phone: this.phone,
        email: this.email,
        name: this.name,
        type: this.model.tariffName,
        scheme: this.model.month.scheme || this.model.year.scheme,
        history: this.model.month.history || this.model.year.history,
        monitoring: this.model.month.monitoring || this.model.year.monitoring,
        interrelation: this.model.month.interrelation || this.model.year.interrelation,
        bulk: this.model.month.bulk || this.model.year.bulk,
        person: this.model.month.fl || this.model.year.fl,
        ru: this.model.month.rf || this.model.year.rf
      };
      this.subsribeService.request_subscription(obj).subscribe((res: any) => {
        this.success = true;
        this.disabled = false
        this.model.expression1 = false;
        this.model.expression2 = true;
        this.phone = null;
        this.email = null;
        this.name = null;
        if (location.hostname === 'kompra.kz') {
          let self = this;
          setTimeout(function () {
            self.winRef.nativeWindow.dataLayer.push({ 'event': 'individ_tariff' });
          }, 100);
        }
      }, (err) => {
        this.toast.pop('error', 'Техническая ошибка');
        this.model.expression1 = false;
        this.disabled = false
      });
    }
  }

  checkSum(b, key) {
    if (key === 'month') {
      this.model.year = {};
    } else {
      this.model.month = {};
    }
    this.model.all = null;
    this.model.amount = 0;
    if (this.model[key].ulip) {
      this.model.amount += key === 'month' ? 89000 : 790000;
    } else {
      this.model[key].scheme = null;
      this.model[key].history = null;
      this.model[key].monitoring = null;
      this.model[key].interrelation = null;
      this.model[key].bulk = null;
    }
    if (this.model[key].scheme) {
      this.model.amount += key === 'month' ? 55000 : 400000;
    }
    if (this.model[key].history) {
      this.model.amount += key === 'month' ? 35000 : 200000;
    }
    if (this.model[key].monitoring) {
      this.model.amount += key === 'month' ? 35000 : 200000;
    }
    if (this.model[key].interrelation) {
      this.model.amount += key === 'month' ? 65000 : 500000;
    }
    if (this.model[key].bulk) {
      this.model.amount += key === 'month' ? 35000 : 200000;
    }
    // if (this.model[key].fl) {
    //   this.model.amount += key === 'month' ? 55000 : 400000;
    // }
    if (this.model[key].rf) {
      this.model.amount += key === 'month' ? 65000 : 500000;
    }
  }

  allChecked(e) {
    if (e === 'month') {
      this.model.month.ulip = true;
      this.model.month.scheme = true;
      this.model.month.history = true;
      this.model.month.monitoring = true;
      this.model.month.interrelation = true;
      this.model.month.bulk = true;
      // this.model.month.fl = true;
      this.model.month.rf = true;
      this.model.year = {};
    }
    if (e === 'year') {
      this.model.year.ulip = true;
      this.model.year.scheme = true;
      this.model.year.history = true;
      this.model.year.monitoring = true;
      this.model.year.interrelation = true;
      this.model.year.bulk = true;
      // this.model.year.fl = true;
      this.model.year.rf = true;
      this.model.month = {};
    }
    // this.model.amount = e === 'month' ? 330000 : 2700000;
    this.model.amount = e === 'month' ? 300000 : 2700000;
  }
}
