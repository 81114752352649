import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questions-banner',
  template: '<div id="questions-banner"></div>',
  styles: [`
  #questions-banner {
    margin: 0 auto;
    z-index: 100;
    cursor: pointer;
  }
  @media(min-width: 761px) {
    #questions-banner {
      height: 120px;
      background: url('/assets/img/QA_Banner_desktop.png') no-repeat;
    }
  }
  @media(max-width: 760px) {
    #questions-banner {
      object-fit: contain;
      height: 228px;
      margin: 0 auto;
      background: url('/assets/img/QA_Banner_mobile.png') no-repeat;
      background-size: contain; 
    }
  }
`]
})
export class QuestionsBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
