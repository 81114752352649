import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'decodeString' })
export class DecodeStringPipe implements PipeTransform {
  constructor() { }
  transform(value) {
    if (value && value.includes('\\u')) {
      value = JSON.parse(value);
    }
    if (value && value[0] === '[') {
      value = JSON.parse(value)[0];
    }
    if (value && value[0] === '{') {
      value = JSON.parse(value).keys()[0];
    }
    return value;
  }
}
