import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-twitter',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './twitter.component.html'
})

export class IconTwitterComponent {}
