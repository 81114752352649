export const layouts = {
    dagre: {
        name: 'dagre',
        nodeSep: undefined, // the separation between adjacent nodes in the same rank
        edgeSep: undefined, // the separation between adjacent edges in the same rank
        rankSep: undefined, // the separation between each rank in the layout
        rankDir: 'LR', // 'TB' for top to bottom flow, 'LR' for left to right,
        align: undefined,  // alignment for rank nodes. Can be 'UL', 'UR', 'DL', or 'DR', where U = up, D = down, L = left, and R = right
        acyclicer: 'greedy', // If set to 'greedy', uses a greedy heuristic for finding a feedback arc set for a graph.
        ranker: 'tight-tree', // Type of algorithm to assign a rank to each node in the input graph. Possible values: 'network-simplex', 'tight-tree' or 'longest-path'
        fit: false, // whether to fit to viewport
        padding: 30, // fit padding
        spacingFactor: undefined, // Applies a multiplicative factor (>0) to expand or compress the overall area that the nodes take up
        nodeDimensionsIncludeLabels: true, // whether labels should be included in determining the space used by a node
        animate: false, // whether to transition the node positions
        animationDuration: 250, // duration of animation in ms if enabled
        refresh: 20,
    },
    cose: {
        name: 'cose',
        // Called on `layoutready`
        ready: function () { },
        // Called on `layoutstop`
        stop: function () { },
        // Number of iterations between consecutive screen positions update
        refresh: 20,
        // Whether to fit the network view after when done
        fit: true,
        // Padding on fit
        padding: 10,
        // Constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
        boundingBox: undefined,
        // Excludes the label when calculating node bounding boxes for the layout algorithm
        nodeDimensionsIncludeLabels: true,
        // Randomize the initial positions of the nodes (true) or use existing positions (false)
        randomize: true,
        // Extra spacing between components in non-compound graphs
        componentSpacing: 200,
        // Node repulsion (non overlapping) multiplier
        nodeRepulsion: function (node: any) { return 2048; },
        // Node repulsion (overlapping) multiplier
        nodeOverlap: 4,
        // Ideal edge (non nested) length
        idealEdgeLength: function (edge: any) { return 32; },
        // Divisor to compute edge forces
        edgeElasticity: function (edge: any) { return 32; },
        // Nesting factor (multiplier) to compute ideal edge length for nested edges
        nestingFactor: 1.2,
        // Gravity force (constant)
        gravity: 0,
        // Maximum number of iterations to perform
        numIter: 1000,
        // Initial temperature (maximum node displacement)
        initialTemp: 1000,
        // Cooling factor (how the temperature is reduced between consecutive iterations
        coolingFactor: 0.99,
        // Lower temperature threshold (below this point the layout will end)
        minTemp: 1.0
    },
    circle: {
        name: 'circle'
    },
    concentric: {
        name: 'concentric',
        concentric: function (node) {
            return node.degree();
        },
        levelWidth: function (nodes) {
            return 2;
        }
    },
}