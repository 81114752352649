import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-plussquareo',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './plussquareo.component.html'
})

export class IconPlussquareoComponent {}
