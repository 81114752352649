import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { tokenNotExpired, JwtHelper } from 'angular2-jwt';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { AppState } from '../../app.service';
import { DataService } from '../../data.service';
import { AgreedPopupService } from '../agreed/agreed-popup.service';
import { WindowRef } from './window.ref';

@Injectable()
export class AuthService {
  // Поток событий авторизации
  _loggedIn: boolean;
  loggedIn$: Subject<boolean>;
  bonus = '1 000';

  constructor(private http: HttpClient,
    private jwtHelper: JwtHelper,
    private router: Router,
    private appState: AppState,
    private dataService: DataService,
    private agreedPopupService: AgreedPopupService,
    private winRef: WindowRef,
  ) {
    // Инициализация потока
    this._loggedIn = this.authorized;
    this.loggedIn$ = new Subject<boolean>();
  }

  // Проверка авторизации
  get authorized() {
    let token = JSON.parse(localStorage.getItem('token'));
    if (token && token.access_token && tokenNotExpired('access_token', token.access_token)) {
      return true;
    } else {
      return false;
    }
  }

  // Пользователь
  get user() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.user_name) {
      return user.user_name;
    } else {
      return null;
    }
  }

  get phone() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.phone) {
      return user.phone;
    } else {
      return null;
    }
  }

  // // Россия
  // get enabledRu() {
  //   let user = JSON.parse(localStorage.getItem('user'));
  //   if (user && user.enabledRu) {
  //     return user.enabledRu;
  //   } else {
  //     return null;
  //   }
  // }

  // Схема
  /**
  * @deprecated switch to AccessRightService
  */
  get enabledScheme() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.enabledScheme) {
      return user.enabledScheme;
    } else {
      return null;
    }
  }

  // ФЛ
  /**
  * @deprecated switch to AccessRightService
  */
  get enabledPerson() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.enabledPerson) {
      return user.enabledPerson;
    } else {
      return null;
    }
  }

  // RF
  /**
  * @deprecated switch to AccessRightService
  */
  get enabledRf() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.enabledRu) {
      return user.enabledRu;
    } else {
      return null;
    }
  }

  // History
  /**
  * @deprecated switch to AccessRightService
  */
  get enabledHistory() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.enabledHistory) {
      return user.enabledHistory;
    } else {
      return null;
    }
  }

  // Filter
  /**
  * @deprecated switch to AccessRightService
  */
  get enabledContractors() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.enabledContractors) {
      return user.enabledContractors;
    } else {
      return null;
    }
  }

  // Relation
  /**
  * @deprecated switch to AccessRightService
  */
  get enabledRelation() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.enabledRelation) {
      return user.enabledRelation;
    } else {
      return null;
    }
  }

  // relationCount
  /**
  * @deprecated switch to AccessRightService
  */
  get relationCount() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.relationCount) {
      return user.relationCount;
    } else {
      return null;
    }
  }

  // Monitoring
  /**
  * @deprecated switch to AccessRightService
  */
  get enabledMonitoring() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.enabledMonitoring) {
      return user.enabledMonitoring;
    } else {
      return null;
    }
  }

  // monitoringCount
  /**
  * @deprecated switch to AccessRightService
  */
  get monitoringCount() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.monitoringCount) {
      return user.monitoringCount;
    } else {
      return null;
    }
  }

  // ruCount
  /**
  * @deprecated switch to AccessRightService
  */
  get ruCount() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.ruCount) {
      return user.ruCount;
    } else {
      return null;
    }
  }

  // Баланс
  get balance() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.balance) {
      return user.balance;
    } else {
      return 0;
    }
  }

  // Проверка подписки
  /**
  * @deprecated switch to AccessRightService
  */
  get subscribed() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.subscribed) {
      return user.subscribed;
    } else {
      return false;
    }
  }

  // Проверка пользовательского соглашения
  get agreed() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.termsAgreed) {
      return user.termsAgreed;
    } else {
      return false;
    }
  }

  get admin() {
    let user = JSON.parse(localStorage.getItem('user'));
    return user && user.admin;
  }

  get apiToken() {
    let user = JSON.parse(localStorage.getItem('user'));
    return user && user.apiToken;
  }

  get schemeNotification() {
    let user = JSON.parse(localStorage.getItem('user'));
    return user && user.schemeNotification;
  }

  get registerDate() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.created) {
      return user.created;
    } else {
      return false;
    }
  }

  get lastLoginDate() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.lastLoginDate) {
      return user.lastLoginDate;
    } else {
      return false;
    }
  }

  get kapchaAccess() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.kapchaAccess) {
      return user.kapchaAccess;
    } else {
      return false;
    }
  }

  /**
  * @deprecated switch to AccessRightService
  */
  get enabledBulkAccess() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.enabledBulkAccess) {
      return user.enabledBulkAccess;
    } else {
      return false;
    }
  }

  /**
  * @deprecated switch to AccessRightService
  */
  get subscribe() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.subscribed && user.subscribedTo && new Date() < new Date(user.subscribedTo)) {
      return user.subscribedTo;
    }
    return false;
  }

  /**
  * @deprecated switch to AccessRightService
  */
  get subscribedFrom() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.subscribed && user.subscribedFrom) {
      return user.subscribedFrom;
    }
    return false;
  }

  /**
  * @deprecated switch to AccessRightService
  */
  get subscribedDateValid() {
    let user = JSON.parse(localStorage.getItem('user'));
    return user.subscribedTo && user.subscribedFrom && user.subscribedFrom <= new Date().getTime()
      && new Date().getTime() <= user.subscribedTo;
  }

  get personalData() {
    let user = JSON.parse(localStorage.getItem('user'));
    return user.personalData;
  }

  setPersonalData(t: boolean) {
    let user = JSON.parse(localStorage.getItem('user'));
    user.personalData = t;
    localStorage.setItem('user', JSON.stringify(user));
  }

  getLoggedIn(): Observable<boolean> {
    return this.loggedIn$.asObservable();
  }

  setLoggedIn(value: boolean) {
    this._loggedIn = value;
    this.loggedIn$.next(value);
  }

  getSms(phone: any) {
    return this.http.post('/users/sms', { phone: phone }).pipe(map(res => {
      return res;
    }));
  }

  sendSms(phone: any, sms: any) {
    return this.http.post('/users/sms/activate', { phone: phone, smsCode: sms }).pipe(map(res => {
      return res;
    }));
  }

  login(email: string, pass: string) {
    this.clearStorage();
    return this.http.post('/oauth/token', this.getFormUrlEncoded({
      grant_type: 'password',
      username: email,
      password: pass
    })).pipe(map(res => {
      let user: any = this.jwtHelper.decodeToken(res['access_token']);
      localStorage.setItem('token', JSON.stringify(res));
      localStorage.setItem('user', JSON.stringify(user));
      this.dataService.setData('user', user);
      this.appState.set('balance', res['balance']);
      if (!this.agreed) {
        this.agreedPopupService.open();
      }
      if (location.hostname === 'kompra.kz') {
        let self = this;
        setTimeout(function () {
          self.winRef.nativeWindow.dataLayer.push({ 'event': 'authorised_success' });
        }, 100);
      }
      this.setLoggedIn(true);
      this.accessRight();
      this.setApiToken();
      // this.checkFilterAccess().subscribe((filter_res) => {
      //   this.appState.set('filterAccess', filter_res['result']);
      // });
      return res;
    }));
  }

  loginWithGoogle(token: string) {
    this.clearStorage();
    return this.http.get(`/oauth/google?token=${token}`)
    .pipe(map(res => {
      let user: any = this.jwtHelper.decodeToken(res['access_token']);
      localStorage.setItem('token', JSON.stringify(res));
      localStorage.setItem('user', JSON.stringify(user));
      this.dataService.setData('user', user);
      this.appState.set('balance', res['balance']);
      if (location.hostname === 'kompra.uz') {
        let self = this;
        setTimeout(function () {
          self.winRef.nativeWindow.dataLayer.push({ 'event': 'authorised_success' });
        }, 100);
      }
      this.setLoggedIn(true);
      this.accessRight();
      this.setApiToken();
      return res;
    }));
  }

  logout(b?): void {
    if (b) {
      if (this.authorized) {
        this.appState.notifier('error', 'В аккаунт произведен вход с другого устройства, рекомендуем сменить пароль', 3);
      } else {
        this.appState.notifier('error', 'Время сессии истекло, рекомендуем произвести повторный вход', 3);
      }
    }
    this.clearStorage();
    this.setLoggedIn(false);
    this.appState.set('balance', null);
    this.appState.set('favCount', null);
    this.router.navigateByUrl('/');
  }

  getReferer() {
    return localStorage.getItem('referer');
  }

  // TODO expired
  setReferer(referer: string) {
    if (referer == null) {
      localStorage.removeItem('referer');
    } else {
      localStorage.setItem('referer', referer);
    }
  }

  getBonus() {
    if (this.getReferer() === '2ndanniversary') {
      this.bonus = '2 000';
    }
    return this.bonus;
  }

  // Восстановления пароля
  recovery(email: string): Observable<any> {
    return this.http.post('/users/resetPassword', this.getFormUrlEncoded({ username: email }),
      { headers: { contentType: 'application/x-www-form-urlencoded; charset=utf-8' } });
  }

  logoutAndRedirect(): void {
    this.logout();
    this.router.navigate(['/']);
  }

  registration(user, recaptcha?): Observable<any> {
    const {source, email, password, phone} = user
    const data = source ?
    { username: email, password, phone, promo: this.getReferer(), src: source, gRecaptchaResponse: recaptcha } : 
    { username: email, password, phone, promo: this.getReferer(), gRecaptchaResponse: recaptcha }
    return this.http.post<any>('/users/registration', data);
  }

  clearStorage(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('userInfo');
  }

  private getFormUrlEncoded(obj): string {
    const formBody = [];

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(obj[key]);
        formBody.push(encodedKey + '=' + encodedValue);
      }
    }
    return formBody.join('&');
  }

  setApiToken() {
    let user = JSON.parse(localStorage.getItem('user'));
    this.getApiToken().subscribe((res) => {
      if (res && res.length) {
        user.apiToken = res[0].token;
        localStorage.setItem('user', JSON.stringify(user));
      }
    });
  }

  accessRight() {
    this.getAccessRight().subscribe((res) => {
      if (res) {
        localStorage.setItem('accessRight', JSON.stringify(res));
        this.appState.updateCurrentTariff(res)
      }
    });
  }

  private getApiToken(): any {
    return this.http.get(`/api/token/user`)
      .pipe(map((res) => {
        return res;
      }));
  }

  private getAccessRight(): any {
    return this.http.get(`/accessRight/my`)
      .pipe(map((res) => {
        return res;
      }));
  }

  // public checkFilterAccess() {
  //   return this.http.get(`/rest-api/user`)
  //     .pipe(map((res) => {
  //       return res;
  //     }));
  // }
}


