import { Injectable } from '@angular/core';
import { KompraLight20, KompraLight50, Premium, TariffTypes, Test, Unlimited } from './../models/tariff.model';

@Injectable()
export class TariffService {
  
  constructor() { }

  public ul_ip_accesses: string[] = ['ENABLED_COMPANY', 'ENABLED_HISTORY', 'ENABLED_MONITORING', 'ENABLED_SCHEME', 
  'ENABLED_RELATIONS', 'ENABLED_RU']
  public other_accesess: string[] = ['ENABLED_BULK_ACCESS', 'ENABLED_SEARCH_BY_PHONE', 'ENABLED_SEARCH_BY_PHOTO', 'ENABLED_SANCTIONS', 
  'ENABLED_COMPARE', 'ENABLED_MEDIA', 'ENABLED_OFFSHORE', 'ENABLED_INTERNAL_CHECK']
  public test_accesess : string[] = this.ul_ip_accesses.concat(['ENABLED_BULK_ACCESS', 'ENABLED_SANCTIONS', 'ENABLED_COMPARE', 
  'ENABLED_OFFSHORE', 'ENABLED_INTERNAL_CHECK'])

  public checkTariff(userRights): string {
    if(userRights) {
      if(this.isPremiumTariff(userRights)) {
        return Premium.tariff
      }
      if(this.isUnlimitedTariff(userRights)) {
        return Unlimited.tariff
      }
      if(this.isTestTariff(userRights)) {
        return Test.tariff
      }
  
      let result: string = ''
      this.ul_ip_accesses.map(access => {
        if(this.isTariff(KompraLight20.tariff, access, userRights)) {
          result = KompraLight20.tariff
        }
        if(this.isTariff(KompraLight50.tariff, access, userRights)) {
          result = KompraLight50.tariff
        }
        if(this.isTariff(Unlimited.tariff, access, userRights)) {
          result = Unlimited.tariff
        }
        if(this.isTariff(Premium.tariff, access, userRights)) {
          result = Premium.tariff
        }
        if(this.isTariff(Test.tariff, access, userRights)) {
          result = Test.tariff
        }
      })
      return result
    }
  }
    
  public isTestTariff(userRights): boolean {
    const testAccesess: string[] = this.test_accesess
    return testAccesess.every(access => userRights 
                                    && userRights[access]['totalCount'] === Test.totalCount 
                                    && userRights[access]['periodTypeId'] === Test.periodTypeId )
  }
    
  public isUnlimitedTariff(userRights): boolean {
    const unlimitedAccesess: string[] = this.ul_ip_accesses.concat(['ENABLED_BULK_ACCESS'])
    return unlimitedAccesess.every(access => userRights && userRights[access]['periodTypeId'] === Unlimited.periodTypeId)
  }
  
  public isPremiumTariff(userRights): boolean {
    const allAccesess: string[] = this.ul_ip_accesses.concat(this.other_accesess)
    return allAccesess.every(access => userRights && userRights[access] && userRights[access]['periodTypeId'] 
                                    && userRights[access]['periodTypeId'] === Premium.periodTypeId)
  }
  
  public isTariff(tariff: TariffTypes, access, userRights): boolean {
    if(userRights && userRights[access]['active']) {
      switch (tariff) {
        case KompraLight20.tariff:
          return userRights[access]['totalCount'] === KompraLight20.totalCount 
              && userRights[access]['periodTypeId'] === KompraLight20.periodTypeId
        case KompraLight50.tariff:
          return userRights[access]['totalCount'] === KompraLight50.totalCount 
              && userRights[access]['periodTypeId'] === KompraLight50.periodTypeId
        case Unlimited.tariff:
          return userRights[access]['totalCount'] === Unlimited.totalCount 
              && userRights[access]['periodTypeId'] === Unlimited.periodTypeId
              && userRights[access]['totalCount'] === Unlimited.totalCount
        default:
          return false
      }
    }
    return false
  }
}
