import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-compress',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './compress.component.html'
})

export class IconCompressComponent {}
