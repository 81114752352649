import {
  Component,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'icon-expand-alt',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './expand-alt.component.html'
})

export class IconExpandAltComponent {}
