import { NgModule } from '@angular/core';
import {
  IconExpandComponent,
  IconCollapseComponent,
  IconBanComponent,
  IconBankComponent,
  IconBellComponent,
  IconBookmarkComponent,
  IconCalendarComponent,
  IconCaretleftComponent,
  IconCaretleftdoubleComponent,
  IconCaretrightComponent,
  IconCheckcircleoComponent,
  IconChevrondownComponent,
  IconChevronleftComponent,
  IconChevronrightComponent,
  IconChevronupComponent,
  IconClockoComponent,
  IconCogComponent,
  IconCopyComponent,
  IconDiagComponent,
  IconDotComponent,
  IconDownloadComponent,
  IconEnvelopeComponent,
  IconExchangeComponent,
  IconExclamationsignComponent,
  IconExportComponent,
  IconExternallinkComponent,
  IconEyeopenComponent,
  IconEyeclosedComponent,
  IconFacebookComponent,
  IconFileComponent,
  IconFiletextComponent,
  IconFilterComponent,
  IconKompraComponent,
  IconLegalComponent,
  IconLockComponent,
  IconMinussquareoComponent,
  IconPencilComponent,
  IconPieComponent,
  IconPlusComponent,
  IconPlussquareoComponent,
  IconQuestionComponent,
  IconRemoveComponent,
  IconRemovecircleComponent,
  IconRocketComponent,
  IconSearchComponent,
  IconShareComponent,
  IconShoppingcartComponent,
  IconSignoutComponent,
  IconStarComponent,
  IconTieComponent,
  IconTrashComponent,
  IconTwitterComponent,
  IconUploadComponent,
  IconVkComponent,
  IconBagComponent,
  IconBookmarkAltComponent,
  IconWalletComponent,
  IconAvatarComponent,
  IconAngleRightComponent,
  IconAngleLeftComponent,
  IconCloseComponent,
  IconCrownComponent,
  IconMenuComponent,
  IconPaperclipComponent,
  IconDownloadaltComponent,
  IconPlugComponent,
  IconRefreshComponent,
  IconEllipsisComponent,
  IconExpandAltComponent,
  IconSearchMinusComponent,
  IconSearchPlusComponent,
  IconCompressComponent,
  IconDatabaseComponent,
  IconSitemapComponent,
  IconAlignLeftComponent,
  IconAngleDoubleUpComponent,
  IconPrintComponent,
  IconClouddownloadComponent,
  IconCheckcircleComponent,
  IconCoffeeComponent,
  IconLiferingComponent,
  IconBriefcaseComponent,
  IconSadsmileComponent,
  IconRussiaComponent,
  IconExclsignComponent,
  IconFlagComponent,
  IconAffiliationComponent,
  IconConnectionsComponent,
  IconGravelComponent,
  IconShareholdersComponent,
  IconCalculatorComponent,
  IconCircledownloadComponent,
  IconBalanceComponent,
  IconApiComponent,
  IconHistoryComponent,
  IconUxlinkComponent,
  IconUxschemeComponent,
  IconUxcloudComponent,
  IconUxtimesComponent,
  IconUxuserComponent,
  IconUxsuitecaseComponent,
  IconSigninComponent,
  IconBookmarkFillComponent,
  IconUxpinComponent,
  IconBalanceFillComponent,
  IconUxcheckComponent,
  IconArrowtoleftComponent,
  IconArrowtorightComponent,
  IconCheckComponent,
  IconPlusoComponent,
  IconMinusoComponent,
  IconStaroComponent,
  IconTelegramComponent,
  IconListComponent,
  IconCaretdownComponent,
  IconDownloadCircleComponent,
  IconPlaceholderComponent,
  IconArrowtobottomComponent,
  IconStarfillComponent,
  IconStarfilledComponent,
  IconArrowdownComponent,
  IconNotfoundComponent,
  IconMonitoringComponent,
  IconExitComponent,
  IconArrowrightComponent,
  IconInstaComponent,
  IconInfoComponent,
  IconComparisionComponent,
  IconVirusComponent,
  IconNoteComponent,
  IconAnalyticsComponent,
  IconBallotComponent,
  IconAddressbookComponent,
  IconHanddollarComponent,
  IconBookuserComponent,
  IconFlagKzComponent,
  IconFlagRuComponent,
  IconFlagKgComponent,
  IconUserComponent,
  IconTableComponent,
  IconSortAscComponent,
  IconSortDescComponent,
  IconKompraToolsComponent,
  IconPhoneComponent,
  IconUsersComponent,
  IconNewspaperComponent,
  IconCommentComponent,
  IconCommentsComponent,
  IconThumbupComponent,
  IconRepostComponent,
  IconOdnoklassnikiComponent,
  IconAtComponent,
  IconGplusComponent,
  IconYtComponent,
  IconMapMarkerComponent,
  IconDuckGoComponent,
  IconGoogleComponent,
  IconYandexComponent,
  IconServiceHhComponent,
  IconArrowRightAltComponent,
  IconWaComponent,
  IconKeyComponent,
  IconFiletextColorComponent,
  IconExclsignTriComponent,
  IconUsersettingsComponent
} from '.';
import { EmptyFileIcon } from './file-icon';

@NgModule({
  declarations: [
    IconExpandComponent,
    IconCollapseComponent,
    IconBanComponent,
    IconBankComponent,
    IconBellComponent,
    IconBookmarkComponent,
    IconCalendarComponent,
    IconCaretleftComponent,
    IconCaretleftdoubleComponent,
    IconCaretrightComponent,
    IconCheckcircleoComponent,
    IconChevrondownComponent,
    IconChevronleftComponent,
    IconChevronrightComponent,
    IconChevronupComponent,
    IconClockoComponent,
    IconCogComponent,
    IconCopyComponent,
    IconDiagComponent,
    IconDotComponent,
    IconDownloadComponent,
    IconEnvelopeComponent,
    IconExchangeComponent,
    IconExclamationsignComponent,
    IconExportComponent,
    IconExternallinkComponent,
    IconEyeopenComponent,
    IconEyeclosedComponent,
    IconFacebookComponent,
    IconFileComponent,
    IconFiletextComponent,
    IconFilterComponent,
    IconKompraComponent,
    IconLegalComponent,
    IconLockComponent,
    IconMinussquareoComponent,
    IconPencilComponent,
    IconPieComponent,
    IconPlusComponent,
    IconPlussquareoComponent,
    IconQuestionComponent,
    IconRemoveComponent,
    IconRemovecircleComponent,
    IconRocketComponent,
    IconSearchComponent,
    IconShareComponent,
    IconShoppingcartComponent,
    IconSignoutComponent,
    IconStarComponent,
    IconTieComponent,
    IconTrashComponent,
    IconTwitterComponent,
    IconUploadComponent,
    IconVkComponent,
    IconBagComponent,
    IconBookmarkAltComponent,
    IconWalletComponent,
    IconAvatarComponent,
    IconAngleRightComponent,
    IconAngleLeftComponent,
    IconCloseComponent,
    IconCrownComponent,
    IconMenuComponent,
    IconPaperclipComponent,
    IconDownloadaltComponent,
    IconPlugComponent,
    IconRefreshComponent,
    IconEllipsisComponent,
    IconExpandAltComponent,
    IconSearchMinusComponent,
    IconSearchPlusComponent,
    IconCompressComponent,
    IconDatabaseComponent,
    IconSitemapComponent,
    IconAlignLeftComponent,
    IconAngleDoubleUpComponent,
    IconPrintComponent,
    IconClouddownloadComponent,
    IconCheckcircleComponent,
    IconCoffeeComponent,
    IconLiferingComponent,
    IconBriefcaseComponent,
    IconSadsmileComponent,
    IconRussiaComponent,
    IconExclsignComponent,
    IconFlagComponent,
    IconAffiliationComponent,
    IconConnectionsComponent,
    IconGravelComponent,
    IconShareholdersComponent,
    IconCalculatorComponent,
    IconCircledownloadComponent,
    IconBalanceComponent,
    IconApiComponent,
    IconHistoryComponent,
    IconUxlinkComponent,
    IconUxschemeComponent,
    IconUxcloudComponent,
    IconUxtimesComponent,
    IconUxuserComponent,
    IconUxsuitecaseComponent,
    IconSigninComponent,
    IconBookmarkFillComponent,
    IconUxpinComponent,
    IconBalanceFillComponent,
    IconUxcheckComponent,
    IconArrowtoleftComponent,
    IconArrowtorightComponent,
    IconCheckComponent,
    IconPlusoComponent,
    IconMinusoComponent,
    IconStaroComponent,
    IconTelegramComponent,
    IconListComponent,
    IconCaretdownComponent,
    IconDownloadCircleComponent,
    IconPlaceholderComponent,
    IconArrowtobottomComponent,
    IconStarfillComponent,
    IconStarfilledComponent,
    IconArrowdownComponent,
    IconNotfoundComponent,
    IconMonitoringComponent,
    IconExitComponent,
    IconArrowrightComponent,
    IconInstaComponent,
    IconInfoComponent,
    IconComparisionComponent,
    IconVirusComponent,
    IconNoteComponent,
    IconAnalyticsComponent,
    IconBallotComponent,
    IconAddressbookComponent,
    IconHanddollarComponent,
    IconBookuserComponent,
    IconFlagKzComponent,
    IconFlagRuComponent,
    IconFlagKgComponent,
    IconUserComponent,
    IconTableComponent,
    IconSortAscComponent,
    IconSortDescComponent,
    IconKompraToolsComponent,
    IconPhoneComponent,
    IconUsersComponent,
    IconNewspaperComponent,
    IconCommentComponent,
    IconCommentsComponent,
    IconThumbupComponent,
    IconRepostComponent,
    IconOdnoklassnikiComponent,
    IconAtComponent,
    IconGplusComponent,
    IconYtComponent,
    IconMapMarkerComponent,
    IconGoogleComponent,
    IconYandexComponent,
    IconDuckGoComponent,
    IconServiceHhComponent,
    IconArrowRightAltComponent,
    IconWaComponent,
    IconKeyComponent,
    IconFiletextColorComponent,
    IconExclsignTriComponent,
    IconUsersettingsComponent,
    EmptyFileIcon
  ],
  exports: [
    IconExpandComponent,
    IconCollapseComponent,
    IconBanComponent,
    IconBankComponent,
    IconBellComponent,
    IconBookmarkComponent,
    IconCalendarComponent,
    IconCaretleftComponent,
    IconCaretleftdoubleComponent,
    IconCaretrightComponent,
    IconCheckcircleoComponent,
    IconChevrondownComponent,
    IconChevronleftComponent,
    IconChevronrightComponent,
    IconChevronupComponent,
    IconClockoComponent,
    IconCogComponent,
    IconCopyComponent,
    IconDiagComponent,
    IconDotComponent,
    IconDownloadComponent,
    IconEnvelopeComponent,
    IconExchangeComponent,
    IconExclamationsignComponent,
    IconExportComponent,
    IconExternallinkComponent,
    IconEyeopenComponent,
    IconEyeclosedComponent,
    IconFacebookComponent,
    IconFileComponent,
    IconFiletextComponent,
    IconFilterComponent,
    IconKompraComponent,
    IconLegalComponent,
    IconLockComponent,
    IconMinussquareoComponent,
    IconPencilComponent,
    IconPieComponent,
    IconPlusComponent,
    IconPlussquareoComponent,
    IconQuestionComponent,
    IconRemoveComponent,
    IconRemovecircleComponent,
    IconRocketComponent,
    IconSearchComponent,
    IconShareComponent,
    IconShoppingcartComponent,
    IconSignoutComponent,
    IconStarComponent,
    IconTieComponent,
    IconTrashComponent,
    IconTwitterComponent,
    IconUploadComponent,
    IconVkComponent,
    IconBagComponent,
    IconBookmarkAltComponent,
    IconWalletComponent,
    IconAvatarComponent,
    IconAngleRightComponent,
    IconAngleLeftComponent,
    IconCloseComponent,
    IconCrownComponent,
    IconMenuComponent,
    IconPaperclipComponent,
    IconDownloadaltComponent,
    IconPlugComponent,
    IconRefreshComponent,
    IconEllipsisComponent,
    IconExpandAltComponent,
    IconSearchMinusComponent,
    IconSearchPlusComponent,
    IconCompressComponent,
    IconDatabaseComponent,
    IconSitemapComponent,
    IconAlignLeftComponent,
    IconAngleDoubleUpComponent,
    IconPrintComponent,
    IconClouddownloadComponent,
    IconCheckcircleComponent,
    IconCoffeeComponent,
    IconLiferingComponent,
    IconBriefcaseComponent,
    IconSadsmileComponent,
    IconRussiaComponent,
    IconExclsignComponent,
    IconFlagComponent,
    IconAffiliationComponent,
    IconConnectionsComponent,
    IconGravelComponent,
    IconShareholdersComponent,
    IconCalculatorComponent,
    IconCircledownloadComponent,
    IconBalanceComponent,
    IconApiComponent,
    IconHistoryComponent,
    IconUxlinkComponent,
    IconUxschemeComponent,
    IconUxcloudComponent,
    IconUxtimesComponent,
    IconUxuserComponent,
    IconUxsuitecaseComponent,
    IconSigninComponent,
    IconBookmarkFillComponent,
    IconUxpinComponent,
    IconBalanceFillComponent,
    IconUxcheckComponent,
    IconArrowtoleftComponent,
    IconArrowtorightComponent,
    IconCheckComponent,
    IconPlusoComponent,
    IconMinusoComponent,
    IconStaroComponent,
    IconTelegramComponent,
    IconListComponent,
    IconCaretdownComponent,
    IconDownloadCircleComponent,
    IconPlaceholderComponent,
    IconArrowtobottomComponent,
    IconStarfillComponent,
    IconStarfilledComponent,
    IconArrowdownComponent,
    IconNotfoundComponent,
    IconMonitoringComponent,
    IconExitComponent,
    IconArrowrightComponent,
    IconInstaComponent,
    IconInfoComponent,
    IconComparisionComponent,
    IconVirusComponent,
    IconNoteComponent,
    IconAnalyticsComponent,
    IconBallotComponent,
    IconAddressbookComponent,
    IconHanddollarComponent,
    IconBookuserComponent,
    IconFlagKzComponent,
    IconFlagRuComponent,
    IconFlagKgComponent,
    IconUserComponent,
    IconTableComponent,
    IconSortAscComponent,
    IconSortDescComponent,
    IconKompraToolsComponent,
    IconPhoneComponent,
    IconUsersComponent,
    IconNewspaperComponent,
    IconCommentComponent,
    IconCommentsComponent,
    IconThumbupComponent,
    IconRepostComponent,
    IconOdnoklassnikiComponent,
    IconAtComponent,
    IconGplusComponent,
    IconYtComponent,
    IconMapMarkerComponent,
    IconGoogleComponent,
    IconYandexComponent,
    IconDuckGoComponent,
    IconServiceHhComponent,
    IconArrowRightAltComponent,
    IconWaComponent,
    IconKeyComponent,
    IconFiletextColorComponent,
    IconExclsignTriComponent,
    IconUsersettingsComponent,
    EmptyFileIcon
  ]
})
export class IconModule { }
