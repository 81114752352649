import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OffshoresService {

  constructor(private http: HttpClient) { }

  getOffshores(searchValue: string): Promise<Object> {
    if(searchValue.trim()) {
      return this.http.get('/api/get_data/offshore?company=' + searchValue).toPromise()
    }
  }
}
